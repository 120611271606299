import React from 'react';
import {IVacanciesContext, VacanciesContext} from './vacancies.context';
import axios from 'axios';
import {IVacancy} from '../../../types';
import striptags from "striptags";

interface IVacanciesProviderProps {
    children: JSX.Element[] | JSX.Element
}

interface IVacanciesProviderState extends IVacanciesContext {
}

class VacanciesProvider extends React.Component<IVacanciesProviderProps, IVacanciesProviderState> {
    constructor(props:IVacanciesProviderProps) {
        super(props);
        this.state = {
            data: [],
            isCompleted: false,
            isLoading: false,
            error: null
        }
    }
    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false
        });
        axios.get('/api/vacancy?countryCode=cz').then(res => {
            this.setState({
                data: (res.data || [])
                    .map((vacancy: IVacancy) => {
                        vacancy.qualificationsStripedTags = striptags(vacancy.qualifications);
                        return vacancy;
                    })
                    .filter((vacancy: IVacancy) => vacancy.isVisible),
                isLoading: false,
                isCompleted: true
            });
        });
    }

    public render() {
        return (
            <VacanciesContext.Provider value={this.state}>
                {this.props.children}
            </VacanciesContext.Provider>
        )
    }
}

export default VacanciesProvider;
