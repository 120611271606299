import React from 'react';
import {Link} from 'react-router-dom';
import {IDepartment, ISidebanner, IVacancyDepartment} from '../../../types';

interface ISidebannerProps {
    banner: ISidebanner,
    department: IVacancyDepartment | IDepartment,
}

class SidebannerItem extends React.Component<ISidebannerProps> {
    public render() {
        let banner = this.props.banner;
        let department = this.props.department;
        let departmentTitle = department.title ===`Business Operations` ? `Corporate Information Systems` :
                department.title === `Technical Documentation Development` ? `Technical Writing ` : department.title;
        let bannerStyle = banner.type === `Interview` ? `bg-green` :
            banner.type === `Veeam Team` ? `bg-dark` :
            banner.type === `FAQ` ? `bg-danger` : `bg-info`;
        return (
            <Link to={banner.src + department.alias} className={bannerStyle + " card card-banner card-no-hover"}>
                <div className="card-header">
                    <span className={banner.icon}></span>
                    <span className={banner.type === 'Working at Veeam'? 'text-font' : ''}>{banner.type}</span>
                </div>
                <div className="card-body">
                    <h3 className={banner.type === 'Working at Veeam'? 'text-font display-3 header-custom-spacer' : 'display-3 header-custom-spacer'}>
                        {banner.type === `Veeam Team` ?
                            banner.title + ` ` + departmentTitle
                             : departmentTitle + ` ` + banner.title
                        }
                    </h3>
                    <h4 className="mb-0">
                        {banner.description}
                    </h4>
                </div>
                <div className="card-footer">
                    <Link to={banner.src + department.alias} className="btn btn-secondary">Read</Link>
                </div>
            </Link>
        )
    }
}

export default SidebannerItem
