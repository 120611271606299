import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import qs from "qs";
import companybanner from "../../mocks/banners/mocks-companybanners";
import learnMore from "../../mocks/learn-more";
import title from "../../mocks/title-slider";
import BannersCompany from "../../components/banners-company/banners-company";
import BannersDepartmentAll from "../../components/banners-department/banners-department-all";
import SidebannerVacancies from "../../../shared/components.shared/components/sidebanner-vacancies/sidebanner-vacancies";
import TypewriterText from "../../../shared/components.shared/components/typewriter-text/typewriter-text";
import VacanciesList from "../../../shared/components.shared/components/vacancies-list/vacancies-list";
import { ITitleSlider, IVacancyTag } from "../../../shared/types";

interface IHomepageState {
  tag: IVacancyTag | undefined;
  showStartCareerBanners: boolean;
  showMarketingCareerBanners: boolean;
}

interface IHomepageProps extends RouteComponentProps {
  setMenuTransparency(value: boolean): void;
}

interface TitleSliderProps {
  title: ITitleSlider;
}

class Homepage extends React.Component<
  IHomepageProps,
  IHomepageState,
  TitleSliderProps
> {
  constructor(props: IHomepageProps) {
    super(props);
    let queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.state = {
      tag: Homepage.parseTag(queryParams),
      showStartCareerBanners: false,
      showMarketingCareerBanners: false,
    };
    this.handleTagSelect = this.handleTagSelect.bind(this);
  }

  static parseTag(queryParams: any): IVacancyTag | undefined {
    let result = undefined;
    if (queryParams && queryParams.tag && typeof queryParams.tag === "string") {
      let queryParts = queryParams.tag.split(":");
      return queryParts.length === 2
        ? {
            name: queryParts[1],
            value: queryParts[1],
            type: queryParts[0],
          }
        : undefined;
    }
    return result;
  }

  handleTagSelect(tag: IVacancyTag) {
    this.setState({
      tag,
      showStartCareerBanners:
        tag && tag.type === "experience" && tag.value === "without",
      showMarketingCareerBanners:
        tag && tag.type === "department" && tag.value === "marketing",
    });
    let tagParam = tag ? `?tag=${tag.type}:${tag.value}` : "";
    this.props.history.replace({
      ...window.location,
      search: tagParam,
    });
  }

  componentDidMount(): void {
    this.props.setMenuTransparency(true);
  }

  componentWillUnmount(): void {
    this.props.setMenuTransparency(false);
  }

  public render() {
    let videoSource = "../../../assets/images/video-bgr.mp4";
    return (
      <div className="section">
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="bg-dark bg-with-video">
          <video autoPlay muted loop className="bg-video">
            <source
              src="//img.veeam.com/careers/banners/bg-video-rus-cz.mp4"
              type="video/mp4"
            />
          </video>
          <div className="container banner-spacer text-center d-none d-md-block">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                <h1 className="display-1 text-center mb-0">
                  Build your career at Veeam
                </h1>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-7">
                <h2 className="display-3 text-type mb-0 mt-3 mt-lg-0">
                  <em>We need: </em>&nbsp;
                  <TypewriterText items={title} />
                </h2>
              </div>
            </div>
          </div>
          <div className="container banner-spacer pb-4 text-center d-md-none">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 d-md-none">
                <h3 className="display-3 text-center mb-0">
                  Build your career at Veeam
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container container-spacer">
          <div className="row block-spacer-mobile">
            <div className="col-12 col-md-4 mb-5 mt-5 mb-md-0 mt-md-0">
              <SidebannerVacancies tag={this.state.tag} />
            </div>
            <div className="col-12 col-md-8">
              <VacanciesList
                limit={4}
                tag={this.state.tag}
                homepage={true}
                learnMoreData={learnMore}
              />
            </div>
          </div>
          <div className="row block-spacer">
            <div className="col-12">
              {this.state.tag && (
                <BannersDepartmentAll tag={this.state.tag.value} />
              )}

              <div className="card-deck card-deck-banners block-spacer">
                {companybanner.slice(0, 3).map((banner) => (
                  <BannersCompany
                    banner={banner}
                    key={`banner-${banner.title}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Homepage);
