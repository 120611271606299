import React from 'react';
import {INewsCz} from '../../types';
import {Link} from 'react-router-dom';

interface INewsProps {
    news: INewsCz[];
}

class News extends React.Component<INewsProps> {

    public render() {
        return (
            this.renderNews()
        )
    }


    protected renderNews() {
        let news = this.props.news;
        return (
            news.map((item) =>
                <div className="card card-sm" key={item.id}>
                    <div className="card-header flex-md-row flex-column align-items-start">
                        <img src={item.img} alt="" className="img-fluid" width="200"/>
                        <span className="pl-md-5">
                           <p><span className="text-placeholder">{item.date}</span></p>
                           <a href={`/news/${item.id}`} className="text-decoration-none">
                               <h3 className="text-font">{item.title}</h3>
                               <p className="text-font">{this.truncateText(item.text, 300)}</p>
                           </a>
                       </span>
                    </div>
                    <div className="card-footer justify-content-end">
                        <Link className="btn btn-link btn-link-cta"
                              to={`/news/${item.id}`}>Read more</Link>
                    </div>
                </div>
            )
        )
    }

    protected truncateText(str: any, maxlength: number) {
        str = str.replace(/(<([^>]+)>)/ig, '');
        return (str.length > maxlength) ?
            str.slice(0, maxlength - 1) + '…' : str;
    }
}

export default News;
