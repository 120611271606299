import React from 'react';

const Gdpr: React.FC = () => {
    return (

        <div className="container container-spacer-lg">
            <h2>INFORMATION ON THE PERSONAL DATA PROCESSING</h2>
            <h2>INFORMACE O ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</h2>

            <h3>Processing of personal data of a job candidate shall be governed by the following rules:
                Zpracování osobních údajů uchazeče o zaměstnání se řídí následujícími pravidly:</h3>

            <p>I. In connection with pre-employment negotiations, Veeam Software (Czech Republic) s.r.o., ID: 041 83
                037, having its registered seat at Karolinská 661/4, Prague - Karlín, 186 00, Czech Republic, registered
                in the Commercial Register maintained by the Municipal Court in Prague, Section C, Entry 243567, (“the
                Controller”) processes a job candidate’s personal data provided to the Controller in a candidate’s
                resume and its possible attachments for the Controller`s internal recruiting processes. For the duration
                of the hiring procedure until its conclusion, the Controller processes such data as part of the process
                leading to eventual conclusion of employment contract.
            </p>
            <p><em>
                V souvislosti s jednáním před vznikem pracovního poměru Veeam Software (Czech Republic) s.r.o., IČ: 041
                83 037, se sídlem Karolinská 661/4, Praha - Karlín, 186 00, Česká republika, zapsaná v obchodním
                rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 243567, („Správce“) zpracovává osobní údaje
                uchazeče o zaměstnání poskytnuté Správci v uchazečově životopise a jeho případných přílohách pro interní
                náborové procesy Správce. Po dobu trvání výběrového řízení až do jeho ukončení Správce zpracovává tyto
                údaje v rámci procesu směřujícího k případnému uzavření pracovní smlouvy.
            </em></p>

            <p>II. When the hiring procedure for the given position is over, a job candidate may agree to processing of
                his or her personal data for subsequent internal recruitment processes of the Controller for a period of
                3 years from the date of granting his or her respective consent.
            </p>
            <p><em>
                Po skončení výběrového řízení na danou pozici může uchazeč o zaměstnání vyslovit souhlas se zpracováním
                jeho osobních údajů pro pozdější náborové procesy Správce na dobu 3 let ode dne udělení příslušného
                souhlasu.
            </em></p>

            <p>III. The Controller never requires such data from job candidates that is not directly related to the
                conclusion of an employment contract.</p>
            <p><em>Správce nikdy nevyžaduje od uchazečů o zaměstnání údaje, které bezprostředně nesouvisejí s uzavřením
                pracovní smlouvy.</em></p>

            <p>IV. The Controller processes personal data manually and electronically. Personal data may be available
                only to authorized employees of the Controller and only to the extent necessary for the processing
                purposes.</p>
            <p><em>Správce zpracovává osobní údaje manuálně i elektronicky. Osobní údaje mohou být zpřístupněny pouze
                oprávněným zaměstnancům Správce, a to pouze v míře nezbytné pro účely zpracování.</em></p>

            <p>V. The Controller takes all technical and organizational measures to ensure protection of personal data
                and to exclude the possibility of unauthorized or accidental access to personal data, alteration,
                destruction or loss thereof, unauthorized transfers to other unauthorized parties, as well as other
                misuse of personal data.</p>
            <p><em>Správce přijímá veškerá technická a organizační opatření k zabezpečení ochrany osobních údajů a k
                vyloučení možnosti neoprávněného nebo nahodilého přístupu k osobním údajům, k jejich změně, zničení či
                ztrátě, neoprávněným přenosům, k jejich jinému neoprávněnému zpracování, jakož i k jinému zneužití
                osobních údajů.</em></p>

            <p>VI. When processing personal data of job candidates, there is no automated decision making or
                profiling.</p>
            <p><em>Při zpracovávání osobních údajů uchazečů o zaměstnání nedochází k automatizovanému rozhodování, ani
                profilování.</em></p>

            <p>VII. Every job candidate has the following rights that the Controller fully respects: Každý uchazeč o
                zaměstnání má následující práva, která Správce plně respektuje:</p>
            <ul className="list-unstyled">
                <li>
                    <h3>Transparency / Transparentnost</h3>
                    <p>Each time personal data are provided to the Controller, a job candidate is always transparently
                        informed about the purpose of personal data being used, who processes data and who controls
                        data.</p>
                    <p><em>Při každém poskytnutí osobních údajů Správci je uchazeč o zaměstnání transparentně poučen o
                        tom, k jakému účelu mohou být osobní údaje použity, kdo údaje zpracovává a kdo je spravuje.</em>
                    </p>
                </li>
                <li>
                    <h3>Right of access to personal data / Právo na přístup k osobním údajům</h3>
                    <p>A job candidate has the right to obtain from the Controller a confirmation that personal data
                        concerning him or her are processed and how and, if so, has the right to access such personal
                        data. Such information on processing personal data is provided on request.</p>
                    <p><em>Uchazeč o zaměstnání má právo získat od Správce potvrzení, zda osobní údaje, které se ho
                        týkají, jsou zpracovávány a jakým způsobem, a pokud jsou zpracovávány, má právo získat přístup k
                        těmto osobním údajům. Tyto informace o zpracování osobních údajů budou poskytnuty na
                        vyžádání.</em></p>
                </li>
                <li>
                    <h3>Right to rectification / Právo na opravu</h3>
                    <p>If a job candidate thinks or discovers that his or her personal data are inaccurately or
                        erroneously processed, he or she has the right to have his or her personal data corrected
                        without undue delay.</p>
                    <p><em>Pokud se uchazeč o zaměstnání domnívá nebo zjistí, že jsou jeho osobní údaje nepřesně či
                        chybně zpracovávány, má právo na to, aby byly jeho osobní údaje bez zbytečného odkladu
                        opraveny.</em></p>
                </li>
                <li>
                    <h3>Right to withdraw the consent and right to erasure ("right to be forgotten") / Právo odvolat
                        souhlas a právo na výmaz („právo být zapomenut“)
                    </h3>
                    <p>Consent to the processing of personal data may be withdrawn at any time by sending an email to
                        the address gdpr-info.cz@veeam.com. If a job candidate withdraws the consent to the processing
                        of personal data, his or her personal data will be erased.</p>
                    <p><em>Souhlas se zpracováním osobních údajů může být kdykoliv odvolán formou emailové zprávy
                        zaslané na adresu gdpr-info.cz@veeam.com. Pokud uchazeč o zaměstnání odvolá souhlas se
                        zpracováním osobních údajů, budou jeho osobní údaje vymazány.</em></p>
                </li>
                <li>
                    <h3>Right to restriction of processing / Právo na omezení zpracování</h3>
                    <p>A job candidate has the right to limit the processing if: (i) he or she denies the accuracy of
                        personal data for the time necessary to verify the accuracy of personal data; or (ii) the
                        processing is unlawful and he or she disclaims the deletion of personal data and instead seeks
                        to limit their use; or (iii) the Controller no longer needs personal data for processing but a
                        job candidate required data to identify, exercise or defend against legal claims; or (iv) a job
                        candidate has objected to the processing, whereas the restriction of the processing will apply
                        until it is ascertained, whether the legitimate reasons of the Controller outweigh the
                        legitimate reasons of a job candidate.</p>
                    <p><em>Uchazeč o zaměstnání má právo na omezení zpracování, pokud: i) popírá přesnost osobních
                        údajů, a to na dobu potřebnou k tomu, aby Správce mohl přesnost osobních údajů ověřit; nebo ii)
                        zpracování je protiprávní a uchazeč o zaměstnání nepožaduje výmaz osobních údajů a žádá místo
                        toho o omezení jejich použití; nebo iii) Správce již osobní údaje nepotřebuje pro účely
                        zpracování, ale uchazeč o zaměstnání je požaduje pro určení, výkon nebo obhajobu právních
                        nároků; nebo iv) uchazeč o zaměstnání vznesl námitku proti zpracování, přičemž v takovém případě
                        se omezení zpracování uplatní do doby, dokud nebude ověřeno, zda oprávněné důvody Správce
                        převažují nad oprávněnými důvody uchazeče o zaměstnání.</em></p>
                </li>
                <li>
                    <h3>Right to data portability / Právo na přenositelnost údajů</h3>
                    <p>A job candidate has the right to receive personal data concerning him or her, which he or she has
                        provided to the Controller, in a structured, commonly used and machine-readable format as well
                        as has the right to transmit those data to another controller without hindrance from the
                        Controller, to which personal data have been provided.
                    </p>
                    <p><em>Uchazeč o zaměstnání má právo získat osobní údaje, které se ho týkají a jež poskytl Správci
                        ve strukturovaném, běžně používaném a strojově čitelném formátu, a právo předat tyto údaje
                        jinému Správci, aniž by tomu Správce, kterému byly osobní údaje poskytnuty, bránil.</em></p>
                </li>
                <li>
                    <h3>Right to lodge a complaint with the Controller or a supervisory authority / Právo podat stížnost
                        u Správce nebo dozorového úřad</h3>
                    <p>If a job candidate discovers or thinks that the Controller carries out the processing of his or
                        her personal data in a manner, which is contrary to the protection of the private and personal
                        life of a job candidate or violating the law, he or she may require the Controller to provide
                        clarification, or to remedy the situation. If the Controller fails to comply with a job
                        candidate's application under the previous sentence, a job candidate has the right to contact
                        the Office for Personal Data Protection (www.uoou.cz/en). This is without prejudice to the right
                        of a job candidate to contact the Office for Personal Data Protection directly.
                    </p>
                    <p><em>Pokud uchazeč o zaměstnání zjistí nebo se domnívá, že Správce provádí zpracování jeho
                        osobních údajů, které je v rozporu s ochranou soukromého a osobního života uchazeče o zaměstnání
                        nebo v rozporu s právními předpisy, může požádat Správce o vysvětlení nebo požadovat, aby
                        Správce odstranil takto vzniklý stav. Nevyhoví-li Správce žádosti uchazeče o zaměstnání podle
                        předchozí věty, má uchazeč o zaměstnání právo obrátit se na Úřad pro ochranu osobních údajů
                        (www.uoou.cz). Tím není dotčeno právo uchazeče o zaměstnání obrátit se na Úřad pro ochranu
                        osobních údajů přímo.</em></p>
                </li>
            </ul>
            <p>VIII. The Controller is a member of the global Veeam group. Personal data that a job candidate provides
                to the Controller may be transferred or accessed by Veeam corporate entities in Switzerland and other
                countries around the world, each of which is responsible for personal data, which it collects and each
                of which will protect job candidate`s personal data in accordance with this Information on the personal
                data processing. Personal data of individuals who reside in a member state of the European Economic Area
                or Switzerland will also be controlled by Veeam Software Corporation AG and other entities of Veeam
                group. Should personal data be provided to other Veeam corporate entities, most notably to Russia,
                United Kingdom, United States of America, China, Japan, Taiwan, Malaysia, Singapore, Australia, New
                Zealand, such countries do not provide such level of personal data protection, which would, in the
                opinion of the European Commission, be adequate to the European one. The Controller therefore uses
                Standard Contractual Clauses approved by the European Commission for the international transfer of
                personal data collected in the European Economic Area and Switzerland, which ensure compliance with data
                protection requirements appropriate to the processing within the EU. The Controller also abides by the
                Asia-Pacific Economic Cooperation (APEC) Cross Border Privacy Rules System (CBPR). The APEC CBPR system
                provides a framework for organizations to ensure protection of personal data transferred among
                participating APEC economies.
            </p>
            <p><em>Správce je součástí globální skupiny Veeam. Osobní údaje, které uchazeč o zaměstnání poskytuje
                Správci, mohou být poskytnuty nebo zpřístupněny jiným společnostem ze skupiny Veeam ve Švýcarsku a
                dalších zemích po celém světě, z nichž každá odpovídá za osobní údaje, které sbírá a chrání osobní údaje
                uchazeče o zaměstnání v souladu s touto Informací o zpracování osobních údajů. Správcem osobních údajů
                osob, které mají bydliště v členském státě Evropského hospodářského prostoru nebo ve Švýcarsku, je
                rovněž společnost Veeam Software Corporation AG a další společnosti ze skupiny Veeam. V případě
                poskytnutí osobních údajů dalším společnostem ze skupiny Veeam, zejména do Ruské federace, Velké
                Británie, Spojených států amerických, Číny, Japonska, Taiwanu, Malajsie, Singapuru, Austrálie, Nového
                Zélandu, tyto státy nezaručují takovou úroveň ochrany osobních údajů, která by z pohledu Evropské komise
                odpovídala úrovni evropské. Správce proto používá standardní smluvní doložky schválené Evropskou komisí
                pro mezinárodní přenos osobních údajů shromážděných v Evropském hospodářském prostoru nebo ve Švýcarsku,
                které zajišťují splnění požadavků na ochranu údajů v rozsahu odpovídajícím zpracování v EU. Správce
                rovněž dodržuje systém přeshraničních pravidel pro ochranu soukromí (CBPR) v rámci asijsko-tichomořské
                ekonomiky (APEC). Systém APEC CBPR poskytuje organizacím rámec, který zajišťuje ochranu osobních údajů
                přenášených mezi zúčastněnými ekonomikami APEC.

            </em></p>

        </div>
    )
};


export default Gdpr;
