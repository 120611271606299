import React from 'react';
import {IVacanciesContext, VacanciesContext} from '../providers/vacancies.context';
import {Link} from 'react-router-dom';

interface IVacanciesCountProps {
    departmentAlias?: string
}

class CheckAll extends React.Component<IVacanciesCountProps> {
    public render() {
        return (
            <div className="card mt-4 d-flex flex-lg-row text-left">
                <div className="card-body">
                    <h3 className="mb-2">Haven’t found a suitable job?</h3>
                    <p className="mb-0">More than <strong>
                        <VacanciesContext.Consumer>
                            {
                            (context) => (this.renderContext(context))

                            }
                    </VacanciesContext.Consumer></strong> jobs are available.</p>
                </div>
                <div className="card-footer d-flex flex-column justify-content-center"><Link
                    className="btn btn-outline-success" to={"/vacancies"}>All jobs</Link></div>
            </div>

        )
    }

    protected renderContext(context: IVacanciesContext | null) {
        let data = context && context.data || [];
        let department = this.props.departmentAlias;
        data = data.filter(vacancy => department ? vacancy.department.alias === department : true);
        return data.length;
    }
}

export default CheckAll;
