import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IStagesProps {
  department: IDepartment | null
}

class StagesProductManagement extends React.Component<IStagesProps> {
  public render() {
    let department = this.props.department

    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <div className="row block-spacer mt-0">
            <div className="col-12 col-md-7">
              <p className="text-placeholder">Product Management / PM</p>
              <h2>Interview stages</h2>
              <p>
                The PM candidate selection process may vary depending on the
                specific team. This is the most common sequence of steps:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>1. Preliminary candidate screening</h3>
                      <p>
                        Preliminary selection is based on information from your
                        resume and additional information we receive from you.
                      </p>
                      <p>
                        Your resume should have a detailed description of a
                        project you worked on, what was your role and which soft
                        skills you possess.
                      </p>
                      <p>
                        Detailed information in the resume helps us understand
                        the level of your expertise. Knowledge of foreign
                        language is crucial for us, so an accurate assessment of
                        your skills in this area is important.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_1.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_2.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>2. Telephone interview with an HR representative</h3>
                      <p>
                        During a telephone interview, an HR specialist will get
                        to know you better and learn more about your experience.
                        The HR specialist will also discuss open vacancies,
                        assess your interest and answer your questions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>3. Interview</h3>
                      <p>
                        The interview is conducted in two stages. The first
                        interview is held by your immediate future manager and a
                        representative of the department, the second one is with
                        the vice-president of the department.
                      </p>
                      <p>
                        During the interview, your foreign language skills will
                        be assessed, the results of your test assignment will be
                        discussed, and you’ll be able to fully explain your work
                        experience and soft skills.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_4.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_5.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>4. Feedback after the interview</h3>
                      <p>
                        Within a few days after the interview the HR specialist
                        will contact you via phone call or email with the
                        results of your interview.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>5. Job offer</h3>
                      <p>
                        If you successfully pass all stages of the interview
                        process, you will receive a job offer. An HR specialist
                        will contact you to discuss details, further steps and
                        your answer your questions.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_6.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StagesProductManagement
