import React from 'react';
import {IQuoteItem} from '../../../types';

interface IQuoteItemProps {
    quote: IQuoteItem;
}

class QuoteItem extends React.Component<IQuoteItemProps> {
    public render() {
        let quote = this.props.quote;

        return (
            <blockquote className="blockquote blockquote_sm">
                <p>{quote.title}</p>
                <p className="blockquote__text_sm">{quote.quote}</p>
                <footer className="blockquote-footer">
                    <strong>{quote.industry}</strong><br/>
                    <span>{quote.company}</span>
                </footer>
            </blockquote>
        )
    }
}

export default QuoteItem;
