const partners = [
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_hp.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_cisco.png'

    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_netapp.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_vmware.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_ms.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_aws.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_ms_azure.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_ibm.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_nutanix.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_purestorage.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_dell.png'
    },
    {
        image: '//img.veeam.com/careers/logo/partners_ru/logo_lenovo.png'
    },
];

export default partners;
