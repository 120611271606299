import React from 'react';
import {INewsContext, NewsContext} from './news.context';
import axios from 'axios';

interface INewsProviderProps {
    children: JSX.Element[] | JSX.Element;
}

interface INewsProviderState extends INewsContext {

}

class NewsProvider extends React.Component<INewsProviderProps, INewsProviderState> {
    componentDidMount() {
        this.setState({
            isLoading: true,
            isCompleted: false,
        });

        axios.get('/api/news').then(res => {
            this.setState({
                data: (res.data || []),
                isLoading: false,
                isCompleted: true
            });
        })
    }

    public render() {
        return (
            <NewsContext.Provider value={this.state}>
                {this.props.children}
            </NewsContext.Provider>
        )
    }
}

export default NewsProvider;
