import React from 'react';
import {IPartners} from '../../../types';

interface IPartnersProps {
    partner: IPartners;
}

class Partners extends React.Component<IPartnersProps> {
    public render() {
        let partner = this.props.partner;

        return (
            <div className="card rounded-0 border-0">
                <div className="card-body px-md-0">
                    <img alt="partner logo" className="img img-fluid" src={partner.image}/>
                </div>
            </div>
        )
    }
}

export default Partners
