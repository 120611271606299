import React from "react"
import { Link } from "react-router-dom"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutTechnicalDocumentation = ({
  department,
}: BaseDepartmentPagePropsType) => {
  return (
    <React.Fragment>
      <img
        className="img-pattern img-pattern-left-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
        alt=""
      />
      <img
        className="img-pattern img-pattern-right-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
        alt=""
      />
      <div className="container container-spacer-lg pb-0">
        <h1 className="display-1">Technical Writing Team</h1>
        <div className="row block-spacer">
          <div className="col-12 col-md-7">
            <p>
              Veeam® Technical Writing Team is responsible for creating and
              maintaining help systems for the products developed by the
              company. This team is a part of Research and Development (R&D). It
              works closely with Development, Quality Assurance (QA), and
              Technical Customer Support teams.
            </p>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
            <img
              src="//img.veeam.com/careers/img/img_ru/about/technical-documentation/tw_diagram.png"
              alt=""
              className="img-fluid mb-5"
            />
          </div>
        </div>
        <div className="card-deck flex-column">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5 text-center">
                  <img
                    src="//img.veeam.com/careers/departments/sales/section_3.png"
                    alt=""
                    className="img-fluid mb-5 w-80"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <h3>What the team does</h3>
                  <p>
                    Sometimes is difficult for the developer to explain a
                    functionality even though for them it seems perfectly clear.
                    This is where the Technical Writer acts as a translator
                    between the creator of the code and the end-user of the
                    software. A Technical Writer can explain complex things in
                    plain English.
                  </p>
                  <p>
                    At the same time, the style of presentation differs from the
                    artistic narration. A user guide only includes the necessary
                    information. It must also be stylistically neutral, focused
                    on the subject, and consistent. Software development deals
                    with complex subjects, and our job is to help the user avoid
                    fighting through convoluted word structures and unnecessary
                    details.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3>The work process</h3>
                  <p>
                    A Technical Writer is an information miner. There are
                    several ways to get the information:
                  </p>
                  <ul>
                    <li>
                      From a specification that explains the purpose of the
                      product and how it should work.
                    </li>
                    <li>From prototypes and early versions of the product.</li>
                    <li>
                      From a conversation with developers. This is the most
                      important source of information for Technical Writers.
                      Developers are the creators of new solutions, and they are
                      aware of the functionality details, upcoming changes, and
                      updates.
                    </li>
                  </ul>
                  <p>
                    In addition, QA Engineers and the Technical Customer Support
                    Team provide important details about practical nuances, the
                    necessary settings, requirements, and limitations of a
                    solution. A Technical Writer needs to establish professional
                    contacts, be diplomatic in communication, and be able to
                    find compromises for solving problems.
                  </p>
                </div>
                <div className="col-12 col-md-5 d-flex align-items-start justify-content-center">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/technical-documentation/section_2.png"
                    alt=""
                    className="img-fluid mb-5 w-65"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card-deck card-deck-banners block-spacer">
          {department && (
            <SidebannerItem banner={sidebanner[0]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[1]} department={department} />
          )}
          <Link
            className="card card-banner card-no-hover"
            to="/success/product-management"
          >
            <div className="card-header">
              <span className="icon-bg-whitepaper"></span>
              <span className="text-font">Working at Veeam</span>
            </div>
            <div className="card-body">
              <h3 className="display-3 header-custom-spacer text-font">
                Product Management success story{" "}
              </h3>
            </div>
            <div className="card-footer pt-0">
              <a
                className="btn btn-secondary"
                href="/success/product-management"
              >
                Read
              </a>
            </div>
          </Link>
        </div>
        {department && (
          <VacanciesSimilar department={department} learnMoreData={learnMore} />
        )}
        <div className="card-deck card-deck-banners block-spacer">
          {companybanner.slice(0, 3).map((banner) => (
            <BannersCompany banner={banner} key={`banner-${banner.title}`} />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutTechnicalDocumentation
