import { DepartmentAliases } from "../types";

const menu = [
    {
        department: `Technology`,
        item: [
            {
                title: `Corporate Technology`,
                src: `/about/${DepartmentAliases.CorporateTechnology}`
            },
            {
                title: `Product Research and Development`,
                src: `/about/${DepartmentAliases.ResearchAndDevelopment}`
            },
            {
                title: `Product Management`,
                src: `/about/${DepartmentAliases.ProductManagement}`
            },
            {
                title: `Quality Assurance`,
                src: `/about/${DepartmentAliases.QualityAssurance}`
            },
            {
                title: `Quality Assurance - Career Start`,
                src: `/qa/career-start/`
            },
            {
                title: `Technical Customer Support`,
                src: `/about/${DepartmentAliases.TechnicalCustomerSupport}`
            },
            {
                title: `Technical Writing`,
                src: `/about/${DepartmentAliases.TechnicalDocumentationDevelopment}`
            }
        ]
    }
];

export default menu;
