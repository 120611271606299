import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IStagesProps {
  department: IDepartment | null
}

class StagesDevelopment extends React.Component<IStagesProps> {
  public render() {
    let department = this.props.department

    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <div className="row block-spacer mt-0">
            <div className="col-12">
              <p className="text-placeholder">Research & Development / R&D</p>
              <h2>Interview stages</h2>
              <p>
                The selection of new R&D employees is carried out in several
                stages.
              </p>
              <p>
                Don’t be surprised if you are on a first-name basis during the
                interview. The culture at Veeam® is casual and friendly.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>1. Preliminary candidate screening</h3>
                      <p>
                        Preliminary selection is based on the information from
                        your resume and additional information we receive from
                        you (for example, a cover letter, a test task, an
                        example of a completed project and/or a working code
                        example).
                      </p>
                      <p>
                        Your resume should include detailed descriptions of
                        projects you’ve worked on, what your roles were, and
                        which technologies were applied.
                      </p>
                      <p>
                        Detailed resume information helps us understand the
                        level of your expertise so we can offer you a position
                        that’s a good fit.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/development/stage_1.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/development/stage_2.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>2. Telephone interview with an HR representative</h3>
                      <p>
                        During a telephone interview, an HR specialist gets to
                        know you and learn more about your experience. The HR
                        specialist will discuss about open positions, determine
                        your interest in it and answer your questions. You may
                        be asked several technical questions for an initial
                        assessment of your expertise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>3. Interview</h3>
                      <p>
                        The interview is held by an HR representative and Veeam
                        technical specialists. Don’t be surprised if your
                        interview in on a first-names basis. The culture at
                        Veeam® is welcoming and friendly.
                      </p>
                      <p>
                        At this stage, it is important to explain your
                        professional and technical knowledge. Next, we will tell
                        you about the project where we currently have an open
                        position and explain its challenges.
                      </p>
                      <p>
                        If you have previously completed a test task, we will
                        also discuss your results during the interview.
                      </p>
                      <p>
                        <em>
                          * - For some teams, there are several interview
                          stages. For example: the first stage could be meeting
                          the team leader and the second stage, the final
                          interview with the project manager.
                        </em>
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/development/stage_3.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/development/stage_4.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>4. Feedback after the interview</h3>
                      <p>
                        Within a few days after the interview (but no more than
                        a week), an HR specialist will contact you via phone
                        call or email with the results of your interview.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>5. Job offer</h3>
                      <p>
                        After successfully completing the interview, you will
                        receive an official job offer. If you accept it, we will
                        discuss your starting date at Veeam Software.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/development/stage_5.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StagesDevelopment
