import React from 'react';
import {ISocialPhotos} from '../../types';

interface ISocialPhotosProps {
    photos: ISocialPhotos;
}

class SocialPhotos extends React.Component<ISocialPhotosProps> {
    public render() {
        let photos = this.props.photos;
        return (
            <div
                className="card card-with-caption card-no-spacer-x bg-transparent border-0 justify-content-center align-items-center">
                <div className="card-header py-5 py-lg-0 justify-content-center">
                    <span className="text-white">{photos.title}</span>
                </div>
                <div className="card-body py-0">
                    <img src={photos.photo} alt="" className="img-fluid mb-3"/>
                    <div className="d-flex justify-content-between">
                    </div>
                </div>
            </div>
        )
    }
}

export default SocialPhotos;
