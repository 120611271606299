import React from 'react';
import {RouteComponentProps} from 'react-router';
import {NewsContext} from '../../components/providers/news.context';
import NewsItem from '../../components/news-item/news-item'

interface INewsItemProps extends RouteComponentProps <{
    id: string
}> {
}

class NewsArticle extends React.Component<INewsItemProps> {
    static contextType = NewsContext;
    context!: React.ContextType<typeof NewsContext>;

    componentDidUpdate(prevProps: INewsItemProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        let news = this.context && this.context.data && this.context.data.find((item) => item.id === +(this.props.match.params.id));
        let isCompleted = this.context && this.context.isCompleted;
        let isLoading = this.context && this.context.isLoading;

        return (
            <div className="container banner-spacer">
                {isLoading && <h4 className="text-center">Loading</h4>}
                {isCompleted && news && <NewsItem news={news}/>}
            </div>)
    }
}


export default NewsArticle;
