const companybanner = [
    {
        type: `Company`,
        title: `About us`,
        slogan: `What we do, where we do business and what our clients say about us`,
        src: `/company`
    },
    {
        type: `Benefits`,
        title: `Why Veeam?`,
        slogan: `Advantages of joining the Veeam Team `,
        src: `/reasons`
    },
    {
        type: `Media`,
        title: `News and articles`,
        slogan: `Important events and the latest news`,
        src: `/news`
    },
    {
        type: `CompanyInner`,
        title: `About us`,
        slogan: `What we do, where we do business and what our clients say about us`,
        src: `/company`
    },
];

export default companybanner;
