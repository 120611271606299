import React from 'react';
import {INewsCz} from '../../types';

export type INewsContext = {
    data: INewsCz[],
    isLoading: boolean,
    isCompleted: boolean,
    error: null | string
}

export const NewsContext = React.createContext<INewsContext>({
    data: [],
    isLoading: false,
    error: null,
    isCompleted: false
});
