import React from 'react';
import {IDepartments} from '../../../types';

interface IDepartmentsProps {
    departments: IDepartments;
}

class AboutBlocks extends React.Component<IDepartmentsProps> {
    public render() {
        let departments = this.props.departments;
        return (
            <div className="card card-basis border-0 align-items-center">
                <div className="card-header pb-0">
                    <img src={departments.icon} alt="" width={"125px"}/></div>
                <div className="card-body text-center">
                    <p className="mb-0">{departments.title}</p>
                    <p className="mb-0">{departments.department}</p>
                </div>
            </div>
        )
    }
}

export default AboutBlocks;

