import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutQa = ({ department }: BaseDepartmentPagePropsType) => {
  return (
    <React.Fragment>
      <img
        className="img-pattern img-pattern-left-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
        alt=""
      />
      <img
        className="img-pattern img-pattern-right-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
        alt=""
      />
      <div className="container container-spacer-lg">
        <h1 className="display-1">Quality Assurance / QA</h1>
        <div className="row block-spacer mt-0">
          <div className="col-12 col-md-7">
            <p className="block-spacer mt-0">
              The Veeam® Quality Assurance (QA) department provides the highest
              level of quality for Veeam products. Our products are large scale
              client server systems, complex and highly appealing, which makes
              the task of ensuring their quality extremely important. Veeam
              solutions are used every day by hundreds of thousands of companies
              around the world. Veeam’s existing products and teams are actively
              developing every day, which is why we are in a constant need of
              new skilled workers.
            </p>

            <h3>We invite you to join the QA department team if:</h3>
            <ul>
              <li>You have broad knowledge of the IT field</li>
              <li>You have a curious mind and like to analyze</li>
              <li>
                You are inquisitive and attentive; you notice flaws in the
                objects and processes that surround you
              </li>
              <li>
                You are a fast learner, like to solve interesting and atypical
                problems
              </li>
              <li>
                You want to work on a professional team, who are passionate
                about what they do
              </li>
            </ul>
            <p>
              <strong>
                Then we are very much looking forward to seeing you join the
                Veeam Team!
              </strong>
            </p>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
            <img
              src="//img.veeam.com/careers/img/img_ru/about/qa/qa_diagram.png"
              alt=""
              className="img-fluid mb-5"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>Career in Veeam QA</h2>
            <p>
              Veeam is growing rapidly. Our existing products are actively
              developed and new projects are starting. Development and QA Teams
              are growing permanently. Veeam provides a perfect chance for
              talented people to implement their professional and career plans,
              since our teams always have new opportunities for growth.
            </p>
            <p>
              <strong>
                The career path for a QA engineer at Veeam goes as follows:
              </strong>
            </p>
            <div className="card border-0">
              <div className="card-body text-center">
                <img
                  src="//img.veeam.com/careers/departments/about/career_path_qa.png"
                  alt=""
                  className="img-fluid d-none d-lg-inline-flex mb-5"
                />
                <img
                  src="//img.veeam.com/careers/img/img_ru/about/qa/chart-mobile.svg"
                  alt=""
                  className="img-fluid mb-5 mb-lg-0 w-50 d-inline-flex d-lg-none"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-5 text-center mb-5 mb-md-0">
                <img
                  src="//img.veeam.com/careers/departments/about/section_1.png"
                  alt=""
                  className="img-fluid mb-5 w-65"
                />
              </div>
              <div className="col-12 col-md-7">
                <h3>Junior QA Engineer</h3>
                <p>
                  Starting as a Junior QA Engineer, you will begin with testing
                  small project parts, study QA methods and principles, acquire
                  necessary technical skills and become an IT professional. You
                  will get the “field” knowledge and experience with Windows and
                  Linux OS, VMware, vSphere and Microsoft Hyper-V virtualization
                  platforms, public clouds like Amazon and Azure, and much more.
                </p>
                <p>
                  Challenging tasks and the informal working environment in our
                  QA Teams provide for rapid professional growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-7">
                <h3>QA Engineer and Experienced QA Engineer</h3>
                <p>
                  As you continue to work in the QA department, you will learn
                  how to manage more complicated and important areas,
                  progressing to QA Engineer and Experienced QA Engineer
                  positions. You will then study the quality of software
                  development and release process and understand interactions
                  between its participants.
                </p>
              </div>
              <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
                <img
                  src="//img.veeam.com/careers/departments/about/section_2.png"
                  alt=""
                  className="img-fluid mb-5 w-65"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img
                  src="//img.veeam.com/careers/departments/about/section_3.png"
                  alt=""
                  className="img-fluid mb-5 w-65"
                />
              </div>
              <div className="col-12 col-md-7">
                <h3>Technical or administrative growth?</h3>
                <p>
                  Having worked for several years and acquired solid experience
                  with QA, you will need to decide on your career development
                  path. Software QA, like many other areas, offers two main
                  development paths: technical and administrative.
                </p>
                <p>
                  Professionals who wish to develop technical skills will focus
                  on large-scale technical tasks, complicated experiments,
                  including performance testing, stress testing and load
                  testing.{" "}
                </p>
                <p>
                  They can lead a small group of engineers to solve complicated
                  tasks, mentor new team members, provide consultation to their
                  co-workers and so on.
                </p>
                <p>
                  Professionals who decide to follow the administrative path
                  will focus mainly on team leadership or department management,
                  including control, planning and assessment tasks for the team
                  or department.
                </p>
                <p>
                  Although you will come to find in our profession that human
                  and technical aspects are interconnected most of the time.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              Software testing and quality assurance provide for all-around
              development of an IT professional, allowing for intensive
              communication between all software development process
              participants and improvement of technical, social and management
              skills. An IT pro can make a choice from a wide variety of
              options!
            </p>
            <p>
              <strong>Welcome to Veeam QA!</strong>
            </p>
          </div>
        </div>
        <div className="card-deck card-deck-banners block-spacer">
          {department && (
            <SidebannerItem banner={sidebanner[0]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[1]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[2]} department={department} />
          )}
        </div>
        {department && (
          <VacanciesSimilar department={department} learnMoreData={learnMore} />
        )}
        <div className="card-deck card-deck-banners block-spacer">
          {companybanner.slice(0, 3).map((banner) => (
            <BannersCompany banner={banner} key={`banner-${banner.title}`} />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutQa
