import React from 'react';
import {IAboutCompanyBanner} from '../../../shared/types';
import {Link} from 'react-router-dom';

interface IAboutCompanyBannerProps {
    banner: IAboutCompanyBanner
}

class BannersCompany extends React.Component<IAboutCompanyBannerProps> {
    public render() {
        let banner = this.props.banner;
        let bannerStyle = banner.type === `Company` ? `bg-green` :
            banner.type === `Benefits` ? `bg-dark` : `bg-danger`;
        let titleStyle = banner.type === `Company` ? `icon-bg-globe_white` :
            banner.type === `Our benefits` ? `icon-bg-star` : `icon-bg-whitepaper`;

        return (
            <Link to={banner.src} className={bannerStyle + " card card-banner card-no-hover"}>
                <div className="card-header">
                    <span className={titleStyle}></span>
                    <span>{banner.type}</span>
                </div>
                <div className="card-body">
                    <h2 className="display-3 header-custom-spacer">{banner.title} </h2>
                    <h4 className="mb-0">{banner.slogan}</h4>
                </div>
                <div className="card-footer">
                    <Link to={banner.src}
                          className="btn btn-secondary">{banner.type === `Media` ? `Read` :
                        banner.type === `Company` ? `Get to know us ` :
                            banner.type === `Our benefits` ? `Discover` : `Read`}</Link>
                </div>
            </Link>
        )
    }
}

export default BannersCompany
