import React from "react"
import DepartmentSuccessDevelopment from "./department-success-development"
import DepartmentSuccessQa from "./department-success-qa"
import DepartmentSuccessCorporateTechnology from "./department-success-corporate-technology"
import DepartmentSuccessProduct from "./department-success-product"
import DepartmentSuccessSupport from "./department-success-support"
import NotFound from "../../../shared/components.shared/components/not-found/not-found"
import { IDepartment } from "../../../shared/types"
import { DepartmentAliases } from "../../types"

interface ISuccessAllProps {
  department: IDepartment | null
  alias: string
}

export const SuccessAll = ({ department, alias }: ISuccessAllProps) => {
  const getSuccessDepartment = () => {
    switch (alias) {
      case DepartmentAliases.ResearchAndDevelopment:
        return <DepartmentSuccessDevelopment department={department} />
      case DepartmentAliases.CorporateTechnology:
        return <DepartmentSuccessCorporateTechnology department={department} />
      case DepartmentAliases.QualityAssurance:
        return <DepartmentSuccessQa department={department} />
      case DepartmentAliases.ProductManagement:
        return <DepartmentSuccessProduct department={department} />
      case DepartmentAliases.TechnicalCustomerSupport:
        return <DepartmentSuccessSupport department={department} />
      default:
        return <NotFound />
    }
  }

  return getSuccessDepartment()
}
