import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Navbar} from 'react-bootstrap';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <img className="img-pattern img-pattern-right-bottom"
                 src="//img.veeam.com/careers/banners/banners_ru/pattern_right_bottom.png" alt=""/>
            <Container className="container container-footer d-flex flex-column-reverse flex-md-row col-md-row">
                <span className="text-muted text-center">©{(new Date()).getFullYear()} Veeam® Software | <a className="text-placeholder" href="//www.veeam.com/eula.html">EULA</a> | <a className="text-placeholder" href="//www.veeam.com/privacy-policy.html">Privacy Policy & Cookies </a>| <a className="text-placeholder" href="/gdpr">GDPR</a></span>
                <Navbar>
                    <Link className="sprites bg-instagram" target="_blank"
                          to="//www.instagram.com/veeam_czechia/ "></Link>
                    <Link className="sprites bg-linkedin" target="_blank"
                          to="//www.linkedin.com/company/veeam-software?ad=footer"></Link>
                    <Link className="sprites bg-spiceworks" target="_blank"
                          to="//community.spiceworks.com/pages/VeeamSoftware?ad=footer"></Link>
                    <Link className="sprites bg-youtube" target="_blank"
                          to="//www.youtube.com/user/YouVeeam?ad=footer"></Link>
                </Navbar>
            </Container>
        </footer>
    );
};

export default Footer;
