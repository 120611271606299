import React from 'react';
import {Link} from 'react-router-dom';

const BannersQa: React.FC = () => {
    return (
        <div className="card-deck card-deck-banners">
            <Link to="about/quality-assurance" className="card card-double card-no-hover">
                <div className="card card-banner card-image">
                    <img src="//img.veeam.com/careers/img/img_ru/about/qa/qa_diagram.png" alt=""/>
                </div>
                <div className="card">
                    <div className="card-header">
                        <span className="icon icon-bg-globe_green"></span>
                        <span className="text-font">Veeam Team</span>
                    </div>
                    <div className="card-body">
                        <h3 className="display-3 header-custom-spacer">About Quality Assurance </h3>
                    </div>
                    <div className="card-footer pt-0">
                        <Link to="about/quality-assurance" className="btn btn-secondary">Read</Link>
                    </div>
                </div>
            </Link>
            <Link to="success/quality-assurance" className="card card-banner card-no-hover card-fixed bg-green">
                <div className="card-header">
                    <span className="icon-bg-person"></span>
                    <span>Working at Veeam</span>
                </div>
                <div className="card-body">
                    <h3 className="display-3 header-custom-spacer">Quality Assurance success story </h3>
                </div>
                <div className="card-footer pt-0">
                    <Link to="success/quality-assurance" className="btn btn-secondary">Read</Link>
                </div>
            </Link>
        </div>
    )
};

export default BannersQa;
