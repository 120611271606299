const details = [
    {
        count: `82%`,
        details: `companies from the Fortune 500 list are our clients`
    },
    {
        count: `400 K+`,
        details: `customers`
    },
    {
        count: `200+`,
        details: `top industry awards`
    },
    {
        count: `35+`,
        details: `offices around the world`
    },
    {
        count: `440+`,
        details: ` employees in Czechia `
    },
    {
        count: `29 - 31`,
        details: `average age`
    }
];

export default details;
