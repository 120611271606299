const news = [
    {
        date: `08-08-2019`,
        headline: `How to work for Veeam: answer these 4 questions and land an interview`,
        article: [
            `Veeam, one of the world’s largest private software companies, a leader in backup solutions for virtual, physical, and cloud environments, currently employs more than 3,500 people in 35+ countries around the world. That includes 150 employees in Prague, a number that is set to significantly rise over the upcoming years. Last year, Veeam announced that it would be expanding—and hiring 500 employees to staff its new offices in the Czech capital. “There is no turnover in our teams,” says Igor Katsev, Head of Veeam Quality Assurance (QA). “But we are nevertheless always on the lookout: the quantity and complexity of Veeam products are growing exponentially.” Among those new hires, Veeam is currently looking for QA Engineers. `
        ],
        links: `/news/news-september-19`
    },
    {
        date: `11-07-2019`,
        headline: `Building a community while recruiting employees `,
        article: [
            `Veeam is planning to recruit hundreds of IT experts as part of its further expansion in Prague. In 2016, the global leader in intelligent data-management software opened its most important research and development center worldwide in the Czech capital. At the moment, that R&D center employs more than 100 people. Thanks to investments totaling $150 million, 500 additional software developers are expected to join them in the course of the next five years. The company will be looking for candidates at job fairs and universities, focusing primarily on Central Europe. "We know that IT recruitment is currently a highly competitive market, and so we have to provide extremely attractive offers in terms of both salaries and employee benefits. `
        ],
        links: ``
    },
    {
        date: `10-07-2019`,
        headline: `Veeam has surpassed a billion dollars in revenue and is putting 150 million into development on the overheated market in Prague`,
        article: [
            `ech entrepreneur Ratmir Timashev comes from Russia and, in some respects, is very similar to his compatriot, Eugene Kaspersky of Kaspersky Lab. Both own their own software companies, at least partially, and are not rushing them on to the stock market. Both are experiencing international business success. At the same time, both keep things informal and blend seamlessly into crowds of journalists, partners and customers, with whom they are ready to conduct rather open discussions when they attend conferences, parties and meetings. Timashev and Kaspersky have also both found themselves in Switzerland. `
        ],
        links: ``
    },
    {
        date: `14-06-19`,
        headline: `Software giant in Prague`,
        article: [
            `With sales of around $1 billion and more than 330,000 customers, Veeam® is one of the largest private software companies in the world, having reached an increase by 48,000 new customers last year. The organic growth of the company for twelve consecutive years has made it the leader in the cloud data management market, where companies spend about $30 billion a year. Based in Baar, Switzerland, and with branches in more than 35+ countries, Veeam was founded by Andrej Baronov and Ratmir Timashev in 2006.`
        ],
        links: ``
    }
];

export default news;
