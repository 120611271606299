import React from 'react';
import {IInformation} from '../../../types'

interface IInformationProps {
    data: IInformation;
}

export class Information extends React.Component <IInformationProps> {
    render() {
        let data = this.props.data;
        return (
            <div className="card border-0 bg-transparent">
                <div className="card-data"><h2 className={data.color + ` display-digits mb-2`}><em
                    className="nowrap">{data.data}</em></h2><span className="font-base">{data.description}</span>
                </div>
            </div>
        )
    }
};

export default Information;
