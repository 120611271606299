import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IDepartmentSuccessProps {
  department: IDepartment | null
}

class DepartmentSuccessDevelopment extends React.Component<IDepartmentSuccessProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0t">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <p className="text-placeholder">Research & Development/ R&D</p>
              <h1 className="display-1">Success Story</h1>
              <h3 className="header-custom-spacer-md">Tomáš Kubeš</h3>
              <h3 className="text-placeholder">
                Lead developer R&D Backup, Data Protection
              </h3>
              <ul className="list-unstyled">
                <li>
                  <p>
                    <strong>How did you begin working at the company?</strong>
                  </p>
                  <p>
                    Before I joined Veeam, my career path went from a junior to
                    a senior position, and then to management. After a few
                    years, I realized that the programming technologies I knew
                    were starting to become obsolete. Being a lead developer for
                    a long time is difficult because IT changes rapidly. Some of
                    my experience was no longer relevant. Looking for a new job
                    was a logical choice at this point in my career. Veeam
                    seemed like an interesting company because it is a software
                    company driven by programmers. Some of the reasons I chose
                    to work at Veeam is because it has its own products, it is a
                    reasonable and useful business and it has a positive
                    financial flow with no debt. The interview with my potential
                    superiors went well. They asked me relevant questions and
                    gave me good answers to my questions during the interview.
                    Finally, the job offer made by Veeam was competitive in the
                    Prague market.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How has your career advanced at the company?
                    </strong>
                  </p>
                  <p>
                    After I started in Veeam, I was not in a rush to be
                    promoted. Initially, I was asked to build my own team. I
                    said that I would first like to learn more about Veeam
                    technologies and processes. Later, I built a team, which
                    recently delivered Veeam Backup for Microsoft Azure.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What was it like to become a lead developer?
                    </strong>
                  </p>
                  <p>
                    A first, I was afraid I would be overloaded with
                    administrative and non-technical tasks, but it turned out
                    that the opposite was true. I have enough time to work with
                    the latest technologies, stay focused on a project and do
                    reviews and coding.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What qualities do you consider to be the most important
                      for a lead developer?
                    </strong>
                  </p>
                  <p>
                    Important qualities for a lead developer are experience
                    (technical and non-technical), diligence, soft skills,
                    self-organization and enthusiasm. The best software
                    developer I have ever known is someone who never attended
                    university. He did programming about 16 hours per day (job +
                    hobby). After a few years, his expertise was far greater
                    than any university graduate’s.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What are the benefits of Veeam as an employer?
                    </strong>
                  </p>
                  <p>
                    It is a stable company with a healthy financial flow. There
                    are experienced colleagues, including more senior employees
                    than at other companies. We have interesting projects. In
                    addition, Veeam uses standard, well-known, modern and
                    effective technologies. What I am learning here would also
                    be useful at other companies. As a bonus, employees are also
                    given parking spaces.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What personal qualities should a Veeam employee have?
                    </strong>
                  </p>
                  <p>
                    It depends on the position. A potential Veeam employee
                    should be able to effectively cooperate with colleagues,
                    have experience in the given field and be responsible,
                    open-minded and a fast learner.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How would you describe your job to a person completely
                      unfamiliar with the position?
                    </strong>
                  </p>
                  <p>
                    The Software Engineer job at Veeam is a standard software
                    development position, but with a few slight differences. It
                    is more focused on quality at Veeam. We cannot afford to
                    release low-quality products because of the high customer
                    expectations and the large number of customers we have. This
                    would cost us a lot on support. We are not expecting
                    developers to deliver zillions of features in a short time,
                    but those that are delivered must be 100% correct.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What do you especially like about your job?</strong>
                  </p>
                  <p>
                    I like the flexible working hours. My colleagues are nice. I
                    work with modern technologies, which is very exciting for
                    me. Veeam products are used by many large and respected
                    companies, so the impact of our work feels considerable.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 order-md-2 order-first  mb-5 mb-md-0">
              <img
                src="//img.veeam.com/careers/img/img_cz/success/success_development.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container container-spacer pt-0">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DepartmentSuccessDevelopment
