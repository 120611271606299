import React from 'react';
import {IValues} from '../../../types';

interface IValuesProps {
    value: IValues;
}

class Values extends React.Component<IValuesProps>{
    public render() {
        let value = this.props.value;
        return(
            <React.Fragment>
                <div className="card card-table bg-transparent border-0">
                    <div className="card-header">
                        <img src={value.src} alt="" className="img-fluid"/>
                    </div>
                    <div className="card-body">
                        <ul>
                            {value.list.map((item) =>
                                <li>{item}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

export default Values;
