const achievements = [
    {
        data: `$ 1B`,
        description: `Annual bookings in 2019`,
        color: `text-success`
    },
    {
        data: `82%`,
        description: `Fortune 500 customers`,
        color: `text-success`
    },
    {
        data: `400 K+`,
        description: `Customers worldwide`,
        color: `text-success`
    }
];

export default achievements;
