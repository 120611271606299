import React from 'react';
import {Link} from 'react-router-dom';

const BannersMarketing: React.FC = () => {
    return (
        <div className="card-deck card-deck-banners">
            <Link to="about/marketing" className="card card-double card-no-hover">
                <div className="card card-image">
                    <img src="//img.veeam.com/careers/banners/banners_ru/about.png" alt=""/>
                </div>
                <div className="card card-banner">
                    <div className="card-header">
                        <span className="icon icon-bg-globe_green"></span>
                        <span className="text-font">Veeam Team</span>
                    </div>
                    <div className="card-body">
                        <h3 className="display-3 header-custom-spacer">About Marketing </h3>
                        <h4 className="mb-0">

                        </h4>
                    </div>
                    <div className="card-footer pt-0">
                        <Link to="about/marketing" className="btn btn-secondary">Read</Link>
                    </div>
                </div>
            </Link>
            <Link to="faq/marketing" className="card card-banner card-no-hover card-fixed bg-green">
                <div className="card-header">
                    <span className="icon-bg-faq"></span>
                    <span>FAQ</span>
                </div>
                <div className="card-body">
                    <h3 className="display-3 header-custom-spacer">Marketing job information</h3>
                    <h4></h4>
                </div>
                <div className="card-footer pt-0">
                    <Link to="faq/marketing" className="btn btn-secondary">Read</Link>
                </div>
            </Link>
            <Link to="stages/marketing" className="card card-banner card-no-hover card-fixed bg-danger">
                <div className="card-header">
                    <span className="icon-bg-person"></span>
                    <span>Interview</span>
                </div>
                <div className="card-body">
                    <h3 className="display-3 header-custom-spacer">Marketing job interview stages</h3>
                </div>
                <div className="card-footer">
                    <Link to="stages/marketing" className="btn btn-secondary">Read</Link>
                </div>
            </Link>
            <Link to="success/it" className="card card-double card-no-hover">
                <div className="card card-image">
                    <img src="//img.veeam.com/careers/img/img_cz/success/success_it_home.png" alt=""/>
                </div>
                <Link to="../success/it" className="card card-banner card-no-hover">
                    <div className="card-header">
                        <span className="icon-bg-whitepaper"></span>
                        <span className="text-font">Working at Veeam</span>
                    </div>
                    <div className="card-body">
                        <h3 className="display-3 header-custom-spacer text-font">Information Technology success
                            story</h3>
                    </div>
                    <div className="card-footer pt-0">
                        <Link to="../success/it" className="btn btn-secondary">Read</Link>
                    </div>
                </Link>
            </Link>
        </div>
    )
};

export default BannersMarketing;
