import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IDepartmentSuccessProps {
  department: IDepartment | null
}

class DepartmentSuccessProduct extends React.Component<IDepartmentSuccessProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <p className="text-placeholder">Product Management / PM</p>
              <h1 className="display-1">Success Story</h1>
              <h3 className="header-custom-spacer-md">Alec King</h3>
              <h3 className="text-placeholder">Vice President</h3>
              <ul className="list-unstyled">
                <li>
                  <p>
                    <strong>When did you start working at Veeam?</strong>
                  </p>
                  <p>
                    I have stopped counting after ten years. I think officially
                    it was twelve years ago.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Can you tell in your own words what Veeam does?
                    </strong>
                  </p>
                  <p>
                    We are number one cloud data management company. We protect
                    our customers data.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How would you describe your job to a person completely
                      unfamiliar with it?
                    </strong>
                  </p>
                  <p>
                    In product management we must think of the next big thing,
                    and then not only think of it, but design it and build it
                    and deliver it to our customers.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What do you like most about Veeam?</strong>
                  </p>
                  <p>
                    The people. The culture. Maybe it is the same thing. I like
                    Veeam culture, the core values, I have been here a long time
                    and I know, that they are really real. People do live and
                    work by these core values, it is supported all the way to
                    the top management – you can hear our CEO Bill Largent talk
                    about core values. And there are so many people here that
                    really do show those values every day, and it make Veeam a
                    great place to work.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What keeps you inspired?</strong>
                  </p>
                  <p>
                    New challenges. Especially in research and development.
                    There is always cutting-edge technology a new way to do
                    things, a better way to do things, and it’s very disruptive.
                    Virtualization came along and completely disrupted the whole
                    IT industry, turned it upside down. It is disruptive but it
                    was an opportunity and Veeam took that opportunity. And in
                    Product management we have to be aware that next opportunity
                    could come any day, every day. We have to watch the new
                    technology, we have to explore our ideas, we have to alert
                    all the time.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>How do you come up with the new ideas?</strong>
                  </p>
                  <p>
                    I would love to take credit for all the great ideas, I think
                    I execute well on those ideas, but there are a lot of very
                    smart and talented people here at Veeam and when you put
                    that many smart and talented people together the ideas just
                    come.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What is changing in the industry?</strong>
                  </p>
                  <p>
                    Everything is changing, constantly. And in a very rapid
                    pace.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How has our job changed since you have become a VP?
                    </strong>
                  </p>
                  <p>
                    Twelve years ago, I started as a product manager, when Veeam
                    acquired the smaller company where I worked. I owned one of
                    the products, there were only two or three at that time, and
                    to be honest I don’t know if my job has really changed that
                    much. I own more products now, I have more responsibility,
                    Veeam is a much larger company, twenty – thirty times larger
                    company. Back then I liked the product management job
                    because I wore lots of different hats. I had to talk to
                    support, I spent a lot of time with marketing, of course I
                    spent most of my time with development and Q&A. We also had
                    to take the strategic decision with product management and
                    with the CTO office about what to explore and what feature
                    is next, what product is next, and my job is still the same
                    in that way it’s just it is many hundreds more people
                    involved in this process. But it is still the same and I
                    think, that’s why I am still here, I loved it when I joined,
                    and I still love it now. That is why I am still here – I was
                    a product manager, now I am a VP of product management, I am
                    still managing the product – that is what I like. I can say
                    that I love what I do and do believe that many people at
                    Veeam can say the same.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What do you especially like about your job?</strong>
                  </p>
                  <p>
                    I like the challenge, the change. It is a race, but I love
                    it. It is in no way boring.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4 order-md-2 order-first  mb-5 mb-md-0">
              <img
                src="//img.veeam.com/careers/img/img_cz/success/success_product-management.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container container-spacer pt-0">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DepartmentSuccessProduct
