import React from 'react';
import {Link} from 'react-router-dom';

const BannersProductManagement: React.FC = () => {
    return (
        <div className="card-deck card-deck-banners">
            <Link to="about/product-management" className="card card-double card-no-hover">
                <div className="card card-image">
                    <img
                        src="//img.veeam.com/careers/img/img_ru/about/product-management/product_diagram.png"
                        alt=""/>
                </div>
                <div className="card card-banner">
                    <div className="card-header">
                        <span className="icon icon-bg-globe_green"></span>
                        <span className="text-font">Veeam Team</span>
                    </div>
                    <div className="card-body">
                        <h3 className="display-3 header-custom-spacer">About Product Management </h3>
                    </div>
                    <div className="card-footer pt-0">
                        <Link to="about/product-management" className="btn btn-secondary">Read</Link>
                    </div>
                </div>
            </Link>
            <Link to="stages/product-management" className="card card-banner bg-green card-no-hover">
                <div className="card-header">
                    <span className="icon-bg-person"></span>
                    <span>Interview</span>
                </div>
                <div className="card-body">
                    <h3 className="display-3 header-custom-spacer">Product Management interview stages</h3>
                </div>
                <div className="card-footer pt-0">
                    <Link to="stages/product-management" className="btn btn-secondary">Read</Link>
                </div>
            </Link>
        </div>
    )
};

export default BannersProductManagement;
