import React from 'react';
import {IWhatYouDo} from '../../../types';

interface IWhatYouDoProps {
    bullets: IWhatYouDo
}

class InfoBlock extends React.Component<IWhatYouDoProps> {
    public render() {
        let bullets = this.props.bullets;

        return (
            <div className="card border-0 bg-transparent">
                <div className="card-header justify-content-center">
                    <img src={bullets.icon} alt=""/>
                </div>
                <div className="card-body text-center p-0">
                    <span>{bullets.content}</span><br/>
                    <span>{bullets.contentbottom}</span>

                </div>
            </div>
        )
    }
}

export default InfoBlock;
