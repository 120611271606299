import React from 'react'
import {IAwards} from '../../../types';

interface IAwardsProps {
    award: IAwards;
}

class Awards extends React.Component<IAwardsProps> {
    public render() {
        let award = this.props.award;

        return (
            <div className="row justify-content-center">
                <div className="col-12 col-md-7">
                    <h3 dangerouslySetInnerHTML={{__html: award.title}}/>
                    <div dangerouslySetInnerHTML={{__html: award.desc}}/>
                </div>
                <div className="col-12 col-md-5 text-center">
                    <img className="img img-fluid w-75" src={award.image} alt="award"/>
                </div>
            </div>
        )
    }
}

export default Awards;
