import React from "react"
import NotFound from "../../../shared/components.shared/components/not-found/not-found"
import AboutDevelopment from "./about-development"
import AboutQa from "./about-qa"
import AboutProductManagement from "./about-product-management"
import AboutPresales from "./about-presales"
import AboutTechnicalDocumentation from "./about-technical-documantation"
import AboutSupport from "./about-support"
import AboutCorporateTechnology from "./aboutCorporateTechnology"
import { IDepartment } from "../../../shared/types"
import { DepartmentAliases } from "../../types"

interface IAboutAllProps {
  alias: string
  department: IDepartment | null
}

export const AboutAll = ({ alias, department }: IAboutAllProps) => {
  switch (alias) {
    case DepartmentAliases.ResearchAndDevelopment:
      return <AboutDevelopment department={department} />
    case DepartmentAliases.CorporateTechnology:
      return <AboutCorporateTechnology department={department} />
    case DepartmentAliases.QualityAssurance:
      return <AboutQa department={department} />
    case DepartmentAliases.ProductManagement:
      return <AboutProductManagement department={department} />
    case DepartmentAliases.TechnicalDocumentationDevelopment:
      return <AboutTechnicalDocumentation department={department} />
    case DepartmentAliases.TechnicalCustomerSupport:
      return <AboutSupport department={department} />
    case DepartmentAliases.Sales:
      return <AboutPresales department={department} />
    default:
      return <NotFound />
  }
}
