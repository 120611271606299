import React from 'react';
import {INewsCz} from '../../types';

interface INewsProps {
    news: INewsCz;
}

class NewsItem extends React.Component<INewsProps> {

    public render() {
        let news = this.props.news;

        return (
            <article>
                <p><span className="text-placeholder">{news.date}</span></p>
                <h1 className="text-font display-1">{news.title}</h1>
                <div dangerouslySetInnerHTML={{__html: news.text}}/>
            </article>
        )
    }
}

export default NewsItem
