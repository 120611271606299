const employeesSupport = [
    {
        quote: `I was initially attracted by the company. I heard a lot about Veeam, I wanted to get here, and my expectations were justified in every sense. Moreover, I feel that I am now in the right place and with very cool people.`,
        name: `Kristina Ponomareva,`,
        position: `Associate Manager, Renewals, UK&Ireland Emerging Accounts`,
        image: `//img.veeam.com/careers/landing/img/carousel_veeam_employee_1.jpg`
    },
    {
        quote: `I am very grateful that I can work in Veeam for many reasons: a well-known and well-established company, comfortable conditions, attentiveness to all employees, non-working activities. But most of all, I appreciate the opportunity to work with nice, responsive people and to grow as a professional. Thanks to lectures, articles and simple communication with more experienced colleagues, I am becoming a better specialist every day. 
        And I am very glad that the company is devoting a lot of attention to education.`,
        name: `Alexandra Zyabkina,`,
        position: `Junior Customer Support Engineer, Tier 1`,
        image: `//img.veeam.com/careers/landing/img/carousel_veeam_employee_2_new.png`
    },
    {
        quote: `For me, Veeam is the embodiment of the American dream: the possibilities are unlimited, and it all depends on you`,
        name: `Evgenia Ushakova,`,
        position: `Inside Sales Projects Manager, EMEA`,
        image: `//img.veeam.com/careers/landing/img/carousel_veeam_employee_3.png?123`
    }
];


export default employeesSupport;
