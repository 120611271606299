import React from 'react';
import news from '../../mocks/news';
import News from '../../components/news/news';
import {NewsContext} from '../../components/providers/news.context';

interface ICompanyNewsState {
    page: number
}

class CompanyNews extends React.Component<{}, ICompanyNewsState> {

    static contextType = NewsContext;
    static itemsPerPage = 5;

    constructor(props: {}) {
        super(props);
        this.state = {
            page: 1
        };

        this.loadMore = this.loadMore.bind(this)
    }

    public render() {
        let visibleNews = news.slice(0, this.state.page * CompanyNews.itemsPerPage);

        return (
            <React.Fragment>
                <img className="img-pattern img-pattern-right-top"
                     src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png" alt=""/>
                <div className="container banner-spacer">
                    <h1 className="display-1">Veeam News</h1>
                    <NewsContext.Consumer>
                        {
                            // @ts-ignore
                            newsContext => <News news={newsContext && newsContext.data || []}/>
                        }
                    </NewsContext.Consumer>
                    <div className="block-spacer-top text-center">
                        {visibleNews.length < news.length &&
                        <button className="btn btn-outline-success" onClick={this.loadMore}>More news</button>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    protected loadMore(): void {
        this.setState({
            page: this.state.page + 1
        });
    }
}

export default CompanyNews;
