import React from 'react';
import {Link} from 'react-router-dom';

const BannersDev: React.FC = () => {
    return (
        <div className="card-deck card-deck-banners">
            <Link to="about/development" className="card card-double card-no-hover">
                <div className="card card-image">
                    <img src="//img.veeam.com/careers/img/img_ru/about/development/rd_diagram.png" alt=""/>
                </div>
                <div className="card card-banner">
                    <div className="card-header">
                        <span className="icon icon-bg-globe_green"></span>
                        <span className="text-font">Veeam Team </span>
                    </div>
                    <div className="card-body">
                        <h3 className="display-3 header-custom-spacer">About Research & Development</h3>
                    </div>
                    <div className="card-footer pt-0">
                        <Link to="about/development" className="btn btn-secondary">Read</Link>
                    </div>
                </div>
            </Link>
            <Link to="stages/development" className="card card-banner card-no-hover card-fixed bg-green">
                <div className="card-header">
                    <span className="icon-bg-person"></span>
                    <span>Interview</span>
                </div>
                <div className="card-body">
                    <h3 className="display-3 header-custom-spacer">Research & Development job interview stages</h3>
                </div>
                <div className="card-footer pt-0">
                    <Link to="stages/development" className="btn btn-secondary">Read</Link>
                </div>
            </Link>
        </div>
    )
};

export default BannersDev;
