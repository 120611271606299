import React, {MouseEvent} from 'react';
import {ISelectItem} from '../../../types';
import {Dropdown} from 'react-bootstrap';


interface IMultiSelectProps {
    options: ISelectItem[]
    id: string;
    placeholder?: string
    active?: number[];
    name?: string;

    onChange(values: any[], indexes: number[]): void;
}

interface IMultiSelectDefaultProps {
    placeholder: string;
}

interface IMultiSelectState {
    selected: boolean[];
}

class Multiselect extends React.Component<IMultiSelectProps, IMultiSelectState> {

    static defaultProps: IMultiSelectDefaultProps = {
        placeholder: 'Выберите'
    };

    constructor(props: IMultiSelectProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.state = {
            selected: this.props.options.map((item, index) => this.props.active !== undefined ? this.props.active.includes(index) : false)
        }
    }

    public reset(): void {
        this.setState({
            selected: []
        });
        this.props.onChange([], []);
    }

    public render() {
        let selected = this.state.selected;
        let options = this.props.options;
        let placeholder = this.props.placeholder;
        let toggleClassName = selected.length && options.filter((item, index) => selected[index]).length > 0 ? 'selected' : '';
        return (
            <div>
                <Dropdown>
                    <Dropdown.Toggle id="sl" className={toggleClassName}>
                        {selected.length && options.filter((item, index) => selected[index]).map(item => item.label).join(', ') || placeholder}
                    </Dropdown.Toggle>
                    {this.renderOptions(options)}
                </Dropdown>
            </div>
        );
    }

    protected handleChange(option: any, index: number) {
        if (option.disabled) {
            return;
        }

        let result = [...this.state.selected];
        result[index] = !result[index];
        this.setState({
            selected: result
        });
        this.props.onChange(this.props.options.filter((item, index) => result[index]), []);
    }

    protected renderOptions(options: ISelectItem[]) {
        return (
            <Dropdown.Menu>
                {
                    options.map((option, index) => (
                            <React.Fragment key={`${this.props.id}-option-${index}`}>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        defaultChecked={this.state.selected[index]}
                                        type="checkbox"
                                        id={`${this.props.id}-option-${index}`}
                                        disabled={option.disabled}
                                        onClick={(e: MouseEvent) => this.handleChange(option, index)}/>
                                    <label
                                        className="custom-control-label"
                                        htmlFor={`${this.props.id}-option-${index}`}>
                                        {option.label}
                                    </label>
                                </div>

                                {
                                    option.divider && <Dropdown.Divider/>
                                }
                            </React.Fragment>
                        )
                    )
                }
            </Dropdown.Menu>
        )
    }
}

export default Multiselect;
