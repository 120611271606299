import React from 'react';
import {Carousel} from 'react-bootstrap';

interface IClassName {
    active: number;
}

class CarouselVacancyQa extends React.Component<{}, IClassName> {
    constructor(props: any) {
        super(props);
        this.state = {
            active: 0,
        };
        this.setACtive = this.setACtive.bind(this);
    }

    setACtive(index: number) {
        this.setState(state => ({
            active: index,
        }))
    }

    render() {
        return (
            <div className="card-deck card-deck-vacancies-foldable flex-column flex-lg-row" id="vacancies-top">
                <div className={`card card-toggle${this.state.active === 0 && ' active' || ''}`}>
                    <div className="card-header pb-0 d-flex flex-column align-items-start">
                        <p><span className="text-with-icon text-warning">Hot Job!</span></p>
                        <h3 className="display-3 mb-0">Junior QA Engineer</h3>
                        <p className="text-slogan mt-5">Are you a recent university graduate interested in taking your first steps into the IT world?</p>
                    </div>
                    <div className="card-body vacancy-container vacancy-container-left">
                        <Carousel className="carousel-vacancies">
                            <Carousel.Item className="carousel-vacancies-inner-lg">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <p>Are you a recent university graduate interested in taking your first steps into the IT world?</p>
                                        <p>A lack of technical knowledge is not a problem at Veeam. For the junior position,
                                            Veeam has developed a training program that will allow you to quickly learn what you need to know.</p>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="d-none d-md-block">
                                            <img src="//img.veeam.com/careers/img/img_ru/career-start/qa/page_1.svg" alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className="carousel-vacancies-inner-lg">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <h3>What you will do:</h3>
                                        <ul>
                                            <li>In the first months, you’ll be immersed in technology and your profession.</li>
                                            <li>You will get field knowledge and experience with Windows, Linux OS, Microsoft server products (SQL Server, Exchange, SharePoint, IIS and others) and more.
                                                The QA Team offers challenging tasks, an informal work environment and rapid professional growth.</li>
                                            <li>After successfully completing an initial trial period, you will test Veeam products while interacting with other participants in the development process.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="d-none d-md-block">
                                            <img src="//img.veeam.com/careers/img/img_ru/career-start/qa/page_2.svg" alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className="carousel-vacancies-inner-lg">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <h3>What we expect from you:</h3>
                                        <ul>
                                            <li>A graduate of a technical university or a senior student whose dream is to work in the epicenter of IT</li>
                                            <li>An agile mind and you like to analyze</li>
                                            <li>An interest in technology and an understanding of Microsoft Windows or Linux as an advanced user or administrator</li>
                                            <li>A fast learner who likes to solve interesting and atypical problems</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="d-none d-md-block">
                                            <img src="//img.veeam.com/careers/img/img_ru/career-start/qa/page_3.svg" alt="" className="img-fluid"/>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="card-footer flex-column flex-md-row">
                        <button className="btn btn-outline-success btn-toggler" onClick={() => this.setACtive(0)}>Learn more</button>
                        <div className="footer-right ">
                            <a className="btn btn-success"
                               href="/vacancies/quality-assurance/junior_qa_engineer__remote_and_part_time_possible_-743999872723501" data-vacancy="Junior QA Engineer">Apply</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default CarouselVacancyQa;
