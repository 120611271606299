import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutSupport = ({ department }: BaseDepartmentPagePropsType) => {
  return (
    <React.Fragment>
      <img
        className="img-pattern img-pattern-left-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
        alt=""
      />
      <img
        className="img-pattern img-pattern-right-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
        alt=""
      />
      <div className="container container-spacer-lg pb-0">
        <h1 className="display-1">Technical Customer Support </h1>
        <div className="row">
          <div className="col-12 col-md-7">
            <p className="block-spacer mt-0">
              Veeam® is a leading developer of backup, disaster recovery and
              data management software for virtual, physical and cloud-based
              workloads, as well as the monitoring and planning of IT
              infrastructures. It is crucial for us that all the engineers on
              the Technical Customer Support team can speak the same language
              with the customer. That is why it is equally important not only to
              know the features of each product but also to have the skills to
              work with the entire technology stacks.
            </p>
            <p className="block-spacer mt-0">
              We have created a team of an extensive staff of engineers from
              different countries: Russia, Czech Republic, Romania, USA,
              Australia, China, and Japan, so that absolutely every customer
              (regardless of time zone and continent) can receive immediate
              assistance. Through these efforts, Veeam can support customers in
              more than 10 languages.
            </p>
            <p className="block-spacer mt-0">
              We not only value the volume of your knowledge and skills (if
              anything is critically important to the job, we will teach you)
              but also your desire to develop and share your experiences with
              the team and to work hard.
            </p>
            <h4 className="mb-4">
              The position of Customer Support Engineer is for you, if:{" "}
            </h4>
            <ul>
              <li>You are fluent in one or more foreign languages</li>
              <li>
                You want to put to practice IT skills you already have and are
                ready to learn new things
              </li>
              <li>
                You are passionate about working on a team and sharing your
                knowledge and experience with your colleagues
              </li>
              <li>
                You are ready to help others and will not hesitate to ask for
                help yourself
              </li>
              <li>
                You are ready to communicate on an equal footing with IT
                professionals and work with a variety of technologies and
                infrastructures of different degrees of complexity{" "}
              </li>
              <li>
                You are driven by the desire to grow professionally in the IT
                field
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
            <img
              className="img-fluid"
              src="//img.veeam.com/careers/img/img_ru/about/support/support_diagram.png"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="block-spacer">
              A career in the Technical Customer Support department
            </h2>
            <p>There are several career paths in Veeam: </p>
            <ul>
              <li>
                If one product is not enough you will have the opportunity to
                work with several, moving from one to another
              </li>
              <li>Do you want to master the skill?</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              You have the opportunity to move along the path of vertical growth
              (work with more serious problems that require communication with
              teams of developers and testers)
            </p>
            <ul>
              <li>
                Do you see yourself as a mentor or trainer? You can become part
                of the training by preparing training and certifications for
                newcomers and junior colleagues.
              </li>
              <li>
                Do you want to develop your leadership skills? Try your hand in
                the role of a Team Leader.
              </li>
              <li>
                Are you ready to try dealing with our largest and most serious
                clients? Try your skills on a team of Service Providers or
                Critical Incidents (SWAT).
              </li>
            </ul>
            <p>
              Veeam has a rich line of products and support is provided for each
              of them. Within the product teams, the technical support service
              is organized according to a three-level principle. This system
              provides an opportunity for vertical and horizontal growth.
            </p>
          </div>
        </div>
        {/*<div className="row">
                        <div className="col-12">
                            <div className="card-deck flex-column flex-lg-row">
                                <div className="card border-0 bg-transparent">
                                    <div className="card-header d-flex flex-row align-items-center"><img
                                        src="//img.veeam.com/careers/img/img_ru/about/support/step_1.svg?123" alt=""
                                        className="img-fluid"/><img
                                        src="//img.veeam.com/careers/img/img_ru/about/support/step_arrow.svg" alt=""
                                        className="img-fluid img-steps d-none d-lg-block"/></div>
                                </div>
                                <div className="card border-0 bg-transparent">
                                    <div className="card-header d-flex flex-row align-items-center"><img
                                        src="//img.veeam.com/careers/img/img_ru/about/support/step_2.svg?123" alt=""
                                        className="img-fluid w-lg-80"/>
                                        <img
                                            src="//img.veeam.com/careers/img/img_ru/about/support/step_arrow.svg" alt=""
                                            className="img-fluid img-steps d-none d-lg-block"/></div>
                                </div>
                                <div className="card border-0 bg-transparent">
                                    <div className="card-header d-flex flex-row align-items-center"><img
                                        src="//img.veeam.com/careers/img/img_ru/about/support/step_3.svg?123" alt=""
                                        className="img-fluid"/><img
                                        src="//img.veeam.com/careers/img/img_ru/about/support/step_arrow.svg" alt=""
                                        className="img-fluid img-steps d-none d-lg-block"/></div>
                                </div>
                                <div className="card border-0 bg-transparent">
                                    <div className="card-header d-flex flex-row align-items-center"><img
                                        src="//img.veeam.com/careers/img/img_ru/about/support/step_4.svg?123" alt=""
                                        className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
        <h2 className="block-spacer">Main product teams:</h2>
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <img
              className="img-fluid"
              src="//img.veeam.com/careers/img/img_ru/about/support/careers_support.png"
              alt=""
            />
          </div>
          <div className="col-12 d-lg-none">
            <img
              className="img-fluid"
              src="//img.veeam.com/careers/img/img_ru/about/support/careers_support_mobile.png"
              alt=""
            />
          </div>
        </div>
        {/*<div className="row">
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0 w-100"
                               href="//www.veeam.com/ru/vm-backup-recovery-replication-software.html?ad=menu-products"
                               target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/vbr.png" alt="" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0" href="//www.veeam.com/ru/windows-cloud-server-backup-agent.html?ad=menu-products" target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/windows.png" alt="" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0" href="//www.veeam.com/ru/linux-cloud-server-backup-agent.html?ad=menu-products" target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/linux.png" alt="" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0" href="//www.veeam.com/ru/backup-microsoft-office-365.html?ad=menu-product" target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/office-365.png" alt="" className="img-fluid"/>
                            </a>
                        </div>
                    </div>*/}
        {/*<div className="row justify-content-md-center">
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0" href="//www.veeam.com/ru/aws-backup.html?ad=menu-products" target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/aws.png" alt="" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0" href="//www.veeam.com/ru/virtualization-management-one-solution.html?ad=menu-products" target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/enterprise.png" alt="" className="img-fluid"/>
                            </a>
                        </div>
                        <div className="col-md-3 col-12">
                            <a className="card  card-no-hover border-0" href="//www.veeam.com/ru/cloud-connect-service-providers.html?ad=menu-products" target="_blank">
                                <img src="//img.veeam.com/careers/img/img_ru/about/support/vcse.png" alt="" className="img-fluid"/>
                            </a>
                        </div>

                    </div>*/}

        <div className="container container-spacer">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutSupport
