import React from "react"
import { Link } from "react-router-dom"
import achievements from "../../mocks/departments/mocks-sales-achievements"
import companybanner from "../../mocks/banners/mocks-companybanners"
import featuresPresales from "../../mocks/departments/mocks-presales-features"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import InfoBlock from "../../../shared/components.shared/components/info-block/info-block"
import Information from "../../../shared/components.shared/components/information/information"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutPresales = ({ department }: BaseDepartmentPagePropsType) => {
  return (
    <React.Fragment>
      <img
        className="img-pattern img-pattern-left-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
        alt=""
      />
      <img
        className="img-pattern img-pattern-right-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
        alt=""
      />
      <div className="container container-spacer-lg">
        <p className="text-placeholder">Veeam Sales</p>
        <h1 className="display-1">Presales Team</h1>
        <div className="row">
          <div className="col-12 col-md-7">
            <p>
              Veeam® Sales drives Veeam Software, which has been winning the
              loyalty of customers around the globe since 2006. Veeam reached
              $1B in annual bookings in 2019 with the market’s #1 backup and
              data replication product.
            </p>
            <p>
              Veeam currently has 4,200+ employees, with offices in more than
              35+ countries.
            </p>
            <p>
              The company offers internal trainings on Veeam products and
              intelligent business-to-business (B2B) sales. Sales techniques and
              Veeam product information are taught to everyone who joins the
              team.
            </p>
            <div className="block-spacer">
              <h3>Veeam Sales is divided into four different teams:</h3>
              <ul>
                <li>The Inside Sales Team</li>
                <li>The Field Sales Team</li>
                <li>The Presales Team</li>
                <li>The Renewals Team</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
            <img
              src="//img.veeam.com/careers/img/img_ru/about/presales/team.png"
              alt=""
              className="img-fluid mb-5"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>Veeam is proud of its achievements:</h2>
            <div className="card-deck">
              {achievements.map((item) => (
                <Information data={item} key={`item-${item.color}`} />
              ))}
            </div>
          </div>
        </div>
        <h2 className="block-spacer">About the Presales Team</h2>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-5 text-center">
                <img
                  src="//img.veeam.com/careers/departments/presales/section_1.png"
                  alt=""
                  className="img-fluid mb-5 w-65"
                />
              </div>
              <div className="col-12 col-md-7">
                <h3>What we do:</h3>
                <p>
                  We help customers and partners better understand the
                  technologies used in our solutions so they can take full
                  advantage of Veeam Software products.
                </p>
                <ul>
                  <p>
                    <strong>Specialists:</strong>
                  </p>
                  <li>Consult customers on technical issues</li>
                  <li>Help close deals.</li>
                  <li>Conduct webinars</li>
                  <li>Represent the company at technical exhibitions</li>
                  <li>
                    Help train sales colleagues and regularly participate in
                    trainings and webinars
                  </li>
                  <li>Develop high-level product expertise</li>
                </ul>
                <p>
                  As a result, the technical expertise among the department
                  specialist is always on the highest level.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img
                  src="//img.veeam.com/careers/departments/sales/section_3.png"
                  alt=""
                  className="img-fluid mb-5 w-65"
                />
              </div>
              <div className="col-12 col-md-7">
                <h3>Why Presales? </h3>
                <ul>
                  <li>To be a part of an international team</li>
                  <li>
                    To acquire a deep understanding of products and the industry
                  </li>
                  <li>Interesting business trips</li>
                  <li>Career growth</li>
                  <li>
                    Constant communication in a foreign language with native
                    speakers
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-spacer">
        <h3 className="display-3">
          Presales Team candidates possess these qualities:
        </h3>
        <div className="card-deck">
          {featuresPresales.map((item) => (
            <InfoBlock bullets={item} key={`item-${item.icon}`} />
          ))}
        </div>
      </div>
      <div className="container">
        <h3 className="display-3">Presales Team career path</h3>
        <div className="row">
          <div className="col-12 text-center">
            <img
              src="//img.veeam.com/careers/departments/presales/chart.png"
              alt=""
              className="img-fluid d-none d-lg-inline-flex mb-5"
            />
            <img
              src="//img.veeam.com/careers/img/img_ru/about/presales/scheme-mobile.svg"
              alt=""
              className="img-fluid mb-5 mb-lg-0 w-50 d-inline-flex d-lg-none"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card-deck card-deck-banners block-spacer">
          {department && (
            <SidebannerItem banner={sidebanner[0]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[1]} department={department} />
          )}
          <Link to="../success/it" className="card card-banner card-no-hover">
            <div className="card-header">
              <span className="icon-bg-whitepaper"></span>
              <span className="text-font">Working at Veeam</span>
            </div>
            <div className="card-body">
              <h3 className="display-3 header-custom-spacer text-font">
                Information Technology success story
              </h3>
            </div>
            <div className="card-footer pt-0">
              <Link to="../success/it" className="btn btn-secondary">
                Read
              </Link>
            </div>
          </Link>
        </div>
        {department && (
          <VacanciesSimilar department={department} learnMoreData={learnMore} />
        )}
        <div className="card-deck card-deck-banners block-spacer">
          {companybanner.slice(0, 3).map((banner) => (
            <BannersCompany banner={banner} key={`banner-${banner.title}`} />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutPresales
