import React from 'react';
import {Carousel} from 'react-bootstrap';
import details from '../../mocks/details';
import photos from '../../mocks/photos';

import employeesSupport from '../../mocks/internship/employeesSupport';
import interviewStagesQa from '../../mocks/internship/career-start-qa-interview-stages';
import offerStartQa from '../../mocks/internship/career-start-qa-what-we-offer';

import SocialPhotos from '../../components/social-photos/social-photos';
import CarouselVacancyQa
    from '../../../shared/components.shared/components/carousel-vacancies-all/carousel-vacancies-qa';
import VacancyQa from '../../../shared/components.shared/components/vacancies-all/vacancy-qa';
import CarouselEmployees from '../../../shared/components.shared/components/carousel-employees/carousel-employees';
import CompanyDetails from '../../../shared/components.shared/components/company-details/company-details';
import InfoBlock from '../../../shared/components.shared/components/info-block/info-block';
import CheckAll from '../../../shared/components.shared/components/check-all/check-all';
import stories from '../../../shared/mocks.shared/success-stories';
import SuccessStories from '../../../shared/components.shared/components/success-stories/success-stories';


interface ICompanyProps {
    setMenuTransparency(value: boolean): void
}

class CareerStartSales extends React.Component<ICompanyProps> {
    componentDidMount(): void {
        this.props.setMenuTransparency(true);
    }

    componentWillUnmount(): void {
        this.props.setMenuTransparency(false);
    }

    public render() {
        return (
            <React.Fragment>
                <img className="img-pattern img-pattern-left-top"
                     src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png" alt=""/>
                <div className="bg-dark">
                    <img className="img-pattern img-pattern-right-top"
                         src="//img.veeam.com/careers/landing/img/backgroun_top_right.png"/>
                    <div className="container banner-spacer">
                        <div className="row">
                            <div className="col-12 col-xl-7">
                                <div className="header-intro">
                                    <h1 className="display-1 text-white mb-3">The Quality Assurance (QA) team</h1>
                                    <h4 className="display-4 text-white mb-6">is recruiting talented graduates and
                                        novice professionals to work on Veeam® products and learn from Veeam QA
                                        professionals.</h4>
                                </div>
                                <a className="btn btn-secondary" href="#vacancies-apply">Apply now</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-spacer">
                    <p>Are you curious and attentive? Do you notice flaws in objects and processes? Are you interested
                        in the origins of things?
                        Are you interested in working in the field of software testing?</p>
                    <h3>At Veeam, you can start building a career right after your university graduation!</h3>

                    <CarouselVacancyQa/>
                    <div className="row">
                        <div className="col-12">
                            <CheckAll/>
                        </div>
                    </div>
                </div>
                <div className="container container-spacer">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="display-3 mb-6">What we offer:</div>
                            <div
                                className="card-deck card-deck-fixed flex-column flex-md-row flex-wrap justify-content-center">
                                {offerStartQa.map((item) =>
                                    <InfoBlock bullets={item} key={`item-${item.icon}`}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light">
                    <div className="container container-spacer">
                        <div className="row">
                            <div className="col-12">
                                <h1>Veeam is a leader</h1>
                                <p>Veeam is a leading developer of backup, disaster recovery and data management
                                    software for virtual, physical and cloud-based workloads.</p>
                                <p>Veeam takes care of all life cycle stages for our solutions: development and testing,
                                    implementation through an extensive affiliate network and technical support in
                                    different languages.</p>
                                <p>Veeam is growing quickly and always searching for the right people, from sales
                                    representatives to IT developers.</p>
                            </div>
                        </div>
                        <div className="card-deck card-deck-numbers block-spacer mt-0">
                            {details.map((detail) =>
                                <CompanyDetails details={detail} key={`detail-${detail.count}`}/>
                            )}
                        </div>
                        <h1>Veeam helps protect data and keeps businesses running smoothly.</h1>
                        <div className="card-deck card-deck-success">
                            {stories.map((story) =>
                                <SuccessStories story={story} key={`story-${story.link}`}/>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container container-spacer">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="display-3 mb-6">Stages of selection:</div>
                            <div className="card-deck flex-column flex-md-row justify-content-center">
                                {interviewStagesQa.map((item) =>
                                    <InfoBlock bullets={item} key={`item-${item.icon}`}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-dark position-relative">
                    <img className="img-pattern img-pattern-left-bottom"
                         src="//img.veeam.com/careers/landing/img/pattern_left_bottom.png"/>
                    <img className="img-pattern img-pattern-social"
                         src="//img.veeam.com/careers/banners/banners_ru/pattern_social.png" alt=""/>
                    <div className="container container-spacer pb-0">
                        <p className="mb-4 text-success">#veeamdreamteam</p>
                        <h2>At Veeam we have an amazing team where you will be appreciated.</h2>
                    </div>
                    <div className="container-spacer pt-0">
                        <Carousel className="d-none d-md-block carousel-photos">
                            <Carousel.Item>
                                <div className="card-deck flex-row flex-nowrap">
                                    {photos[0].map((photo) =>
                                        <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                    )}
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="card-deck flex-row flex-nowrap">
                                    {photos[1].map((photo) =>
                                        <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                    )}
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="card-deck flex-row flex-nowrap">
                                    {photos[2].map((photo) =>
                                        <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                    )}
                                </div>
                            </Carousel.Item>
                        </Carousel>
                        <Carousel className="d-clock d-md-none">
                            {photos[0].map((photo) =>
                                <Carousel.Item>
                                    <div className="card-deck d-flex flex-row flex-nowrap justify-content-center">
                                        <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </div>
                </div>

                <div className="container container-spacer-lg pb-0">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Carousel>
                                {employeesSupport.map((item) =>
                                    <Carousel.Item key={`item-${item.name}`}>
                                        <CarouselEmployees quote={item}/>
                                    </Carousel.Item>
                                )}
                            </Carousel>
                        </div>
                    </div>
                    <hr className="block-spacer"/>
                </div>
                <div className="container container-spacer pt-0" id="vacancies-apply">
                    <VacancyQa/>
                    <CheckAll/>
                </div>
            </React.Fragment>
        )
    }
}

export default CareerStartSales
