import React from 'react';
import {IProducts} from '../../../types'

interface IProductsProps {
    data: IProducts;
}

class OurProducts extends React.Component<IProductsProps> {
    public render() {
        let data = this.props.data;

        return (
            <div className="card border-0 bg-transparent">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-3">
                            <img src={data.image} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-9 pl-0">
                            <p className="text-white mb-0">{data.title}</p>
                            <a href={data.link} className="btn btn-link btn-link-cta btn-link-cta-light text-secondary">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OurProducts
