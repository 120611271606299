import React from 'react';


const LibraryEn: React.FC = () => {
    return (
        <div className="container banner-spacer">
            <h1 className="display-3">Recommended Literature for Software Developers</h1>
            <div className="row mb-5 mb-3">
                <div className="col-12"><h3 className="mb-2">Entry Level (for C#) – Junior</h3></div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row mb-5 py-4 bg-light d-none d-md-flex">
                        <div className="col-12 col-md-6">Topic</div>
                        <div className="col-12 col-md-3">Recommended book</div>
                        <div className="col-12 col-md-3">Author</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Algorithms and data structures, complexity of algorithms</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">Introduction to Algorithms</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Thomas H. Cormen, Charles E. Leiserson,</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Interfaces/classes/structs</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">CLR via C#</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Jeffrey Richter</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Collections/generics in .NET.: A common understanding of how
                            collections are organized, and when they should be used.
                        </div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">CLR via C#</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Jeffrey Richter</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Processes/threads/their synchronization: Synchronization
                            primitives
                        </div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">CLR via C#</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Jeffrey Richter</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6"> LINQ</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">CLR via C#</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Jeffrey Richter</div>
                    </div>
                </div>
            </div>
            <div className="row mb-5 mt-5">
                <div className="col-12">
                    <h3 className="mb-2">Entry level (for C++) – Junior</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row mb-5 d-none d-md-flex py-4 bg-light">
                        <div className="col-12 col-md-6">Topic</div>
                        <div className="col-12 col-md-3">Recommended book</div>
                        <div className="col-12 col-md-3">Author</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Algorithms and data structures, complexity of algorithms</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">Introduction to Algorithms</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Thomas H. Cormen, Charles E. Leiserson,</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Classes and structures (Three basic OOPs: encapsulation,
                            inheritance and polymorphism)
                        </div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">The C++ Programming Language</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Bjarne Stroustrup</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Error Handling and Exceptions</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">The C++ Programming Language</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Bjarne Stroustrup</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Work with streams and files</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">The C++ Programming Language</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Bjarne Stroustrup</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">C++ standard library: Strings, algorithms, containers, threads,
                            smart pointers, exceptions and iterators
                        </div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">The C++ Programming Language</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Bjarne Stroustrup</div>
                    </div>
                </div>
            </div>
            <div className="row mb-5 mt-5">
                <div className="col-12">
                    <h3 className="mb-2">Medium level </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row mb-5 py-4 bg-light d-none d-md-flex">
                        <div className="col-12 col-md-6">Topic</div>
                        <div className="col-12 col-md-3">Recommended book</div>
                        <div className="col-12 col-md-3">Author</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Structuring classes</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">Code Complete</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Steve McConnell</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Exception handling</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">Code Complete</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Steve McConnell</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Naming and using variables</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">Code Complete</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Steve McConnell</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>
                        <div className="col-12 col-md-6">Loops, branching operators</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>

                        <div className="col-12 col-md-3">Code Complete</div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>

                        <div className="col-12 col-md-3">Steve McConnell</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Topic</strong></h5></div>

                        <div className="col-12 col-md-6">
                            S.O.L.I.D. Principles
                        </div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Recommended book</strong></h5>
                        </div>
                        <div className="col-12 col-md-3">Agile Software Development. Principles, Patterns and
                            Practices
                        </div>
                        <div className="col-12 d-md-none"><h5 className="my-2"><strong>Author</strong></h5></div>
                        <div className="col-12 col-md-3">Robert C. Martin</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LibraryEn;
