import React from 'react';
import {Link} from 'react-router-dom';

const BannersPrague: React.FC = () => {
    return (
        <div className="card-deck card-deck-banners">
            <Link to="about/prague" className="card card-double card-no-hover">
                <div className="card card-image">
                    <img src="//img.veeam.com/careers/img/img_ru/about/prague/about_prague_homepage.png" alt=""/>
                </div>
                <div className="card card-banner">
                    <div className="card-header">
                        <span className="icon icon-bg-globe_green"></span>
                        <span>Команда</span>
                    </div>
                    <div className="card-body">
                        <h3 className="display-3 header-custom-spacer">О работе в Праге</h3>
                    </div>
                    <div className="card-footer pt-0">
                        <Link to="about/prague" className="btn btn-secondary">Подробнее</Link>
                    </div>
                </div>
            </Link>
            <Link to="success/prague" className="card card-banner card-no-hover card-fixed bg-green">
                <div className="card-header">
                    <span className="icon-bg-person"></span>
                    <span>Working at Veeam</span>
                </div>
                <div className="card-body">
                    <h3 className="display-3 header-custom-spacer">Узнай, как работают в Праге</h3>
                </div>
                <div className="card-footer pt-0">
                    <Link to="success/prague" className="btn btn-secondary">Подробнее</Link>
                </div>
            </Link>
        </div>
    )
};

export default BannersPrague;
