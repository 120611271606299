import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IStagesProps {
  department: IDepartment | null
}

class StagesCorporateTechnology extends React.Component<IStagesProps> {
  public render() {
    let department = this.props.department

    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <div className="row block-spacer mt-0">
            <div className="col-12">
              <p className="text-placeholder">Corporate Technology / CT</p>
              <h2>Interview stages</h2>
              <p>
                The Corporate Technology position’s selection process may vary
                depending on the Corporate Technology team for which you are
                applying.
              </p>
              <p>Below is the most common sequence of steps.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>1. Preliminary candidate screening</h3>
                      <p>
                        Preliminary selection is based on the information from
                        your resume and additional information we receive from
                        you (for example, a cover letter, a test task, an
                        example of a completed project and/or an example of a
                        working code).
                      </p>
                      <p>
                        It would be great if your resume has a detailed
                        description of an IT or software development project you
                        worked on, what your role was in the project and which
                        technologies were applied.
                      </p>
                      <p>
                        Detailed resume information helps us understand the
                        level of your expertise.
                      </p>
                      <p>
                        No need to mention technologies you haven’t previously
                        worked with.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_1.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_2.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>2. Telephone interview with an HR representative</h3>
                      <p>
                        During a telephone interview, an HR specialist gets to
                        know you and learn more about your experience. The HR
                        specialist will tell you about the position, determine
                        your interest in it and answer your questions. You might
                        be asked several technical questions for an initial
                        assessment of your expertise level.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>3. Test assignment</h3>
                      <p>
                        If you successfully pass a telephone interview with an
                        HR specialist, you will be asked to complete a test task
                        so we can better assess your level of professional
                        expertise.
                      </p>
                      <p>
                        The test task will not take much of your time. We
                        normally expect to receive the completed test in 3-4
                        days, although this may be negotiated. We always let job
                        seekers know their test results. NOTE: For some
                        positions, based on the results of the HR interview, we
                        can immediately schedule a short interview with a
                        manager via skype or by phone, after which we will
                        assign the test task.
                      </p>
                      <small className="text-muted">
                        NOTE: Stages of the interview process vary depending on
                        a team and may include test tasks and additional
                        interviews.
                      </small>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_3.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_4.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>4. The Interview</h3>
                      <p>
                        The interview is held by an HR representative and Veeam
                        technical specialists. They will explain the open
                        position and its future challenges. Next, you will have
                        the opportunity to demonstrate your professional and
                        technical knowledge. In addition, we will discuss the
                        results of your test task.
                      </p>
                      <p>
                        Subsequently, we may invite you to second round of
                        interviews, if necessary.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>5. Feedback after the interview</h3>
                      <p>
                        Within a few days after the interview, an HR specialist
                        will contact you via phone call or email with the
                        results of your interview.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_5.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_6.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>6. Job offer</h3>
                      <p>
                        If you successfully pass all stages of this process, you
                        will receive a job offer. An HR specialist will contact
                        you to provide information on next steps and to answer
                        your questions. If you accept it, we will discuss your
                        start date at Veeam® Software.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StagesCorporateTechnology
