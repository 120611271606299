const products = [
    {
        image: '//img.veeam.com/careers/img/img_ru/agents.png',
        link: '//www.veeam.com',
        title: 'Our products '
    },
    {
        image: '//img.veeam.com/careers/img/img_ru/clouds.png',
        link: '//www.veeam.com/cloud-data-management.html',
        title: 'Cloud Data Management Platform'
    }


];

export default products;
