import React, { useContext, useEffect } from "react"
import { useParams } from "react-router"
import NotFound from "../shared/components.shared/components/not-found/not-found"
import { DepartmentsContext } from "../shared/components.shared/components/providers/departments.context"
import { AboutAll } from "./pages/about-department/about-all"
import { SuccessAll } from "./pages/department-success/department-success-all"
import { FaqAll } from "./pages/faq-department/faq-all"
import { StagesAll } from "./pages/stages-department/stages-all"
import { DEPARTMENT_CONTEXT_PAGE } from "./types"

interface IDepartmentContextWrapperProps {
  page: DEPARTMENT_CONTEXT_PAGE
}

type AliasParamType = {
  alias: string
}

const DepartmentContextWrapper = (props: IDepartmentContextWrapperProps) => {
  const { alias } = useParams<AliasParamType>()
  const departmentContext = useContext(DepartmentsContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [alias])

  const getDepartmentContextPage = () => {
    const department = departmentContext?.isCompleted
      ? departmentContext.data.find((d) => d.alias === alias) ?? null
      : null

    switch (props.page) {
      case DEPARTMENT_CONTEXT_PAGE.ABOUT:
        return <AboutAll alias={alias} department={department} />
      case DEPARTMENT_CONTEXT_PAGE.FAQ:
        return <FaqAll department={department} alias={alias} />
      case DEPARTMENT_CONTEXT_PAGE.STAGES:
        return <StagesAll department={department} alias={alias} />
      case DEPARTMENT_CONTEXT_PAGE.SUCCESS:
        return <SuccessAll department={department} alias={alias} />
    }
    return <NotFound />
  }

  return getDepartmentContextPage()
}

export default DepartmentContextWrapper
