import React, { useContext } from "react"
import ctDepartments from "../../mocks/departments/mocks-ct-departments"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import AboutBlocks from "../../../shared/components.shared/components/departments/departments"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { DepartmentsContext } from "../../../shared/components.shared/components/providers/departments.context"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutCorporateTechnology = ({
  department,
}: BaseDepartmentPagePropsType) => {
  return (
    department && (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <h1 className="display-1">Corporate Technology</h1>
          <div className="row block-spacer">
            <div className="col-12">
              <p>
                The Corporate Technology department is responsible for the
                company's IT Infrastructure and Business Applications, provides
                technical leadership in technology investment decisions, and
                technical support to Veeam employees around the world. Our
                department enables the most effective business processes by
                providing employees with data-driven, automated, secure, and
                adaptive solutions that fuel Veeam's business growth.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3>Professional development </h3>
                  <p>
                    For effective problem solving, we believe it is extremely
                    important to invest in employee development. We value an
                    open-minded approach to what we do, a desire to learn new
                    areas and adopt best practices. Team members are welcome to
                    suggest and take various training programs sponsored by the
                    company, visit conferences that align with team strategic
                    objectives, participate in or hold internal knowledge
                    exchange sessions.
                  </p>
                  <p>
                    We don’t have an official mentoring program, as this is
                    compensated by the open-door, friendly working environment
                    where people learn from each other, like from senior
                    members, and grow as fast as they want.
                  </p>
                </div>
                <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/cis/about_2.svg"
                    alt=""
                    className="img-fluid mb-5 w-65"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5 text-center mb-5 mb-md-0">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/cis/about_3.svg"
                    alt=""
                    className="img-fluid mb-5 w-65"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <h3>How to become a part of our team </h3>
                  <p>
                    Do you want to join the team? We often look for people with
                    real, hands-on experience. Most importantly, we look for
                    someone who has the passion for technology, shares the same
                    attitude for quality of development, who has the energy and
                    ability to learn, proposes and becomes a world-class expert.
                    Apply now for a job that interests you and let’s have a
                    chat!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <h3 className="display-3">What are we doing exactly?</h3>
          <div className="card-deck">
            {ctDepartments.map((item) => (
              <AboutBlocks departments={item} />
            ))}
          </div>
        </div>
        <div className="container container-spacer">
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  )
}

export default AboutCorporateTechnology
