import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IFaqProps {
  department: IDepartment | null
}

class FaqCorporateTechnology extends React.Component<IFaqProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <p className="text-placeholder">Corporate Technology</p>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div className="row block-spacer-bottom">
            <div className="col-12">
              <ol>
                <li>
                  <p>
                    <strong>What will Veeam® focus on in my resume?</strong>
                  </p>
                  <p>
                    Your response to the position, your overall resume and your
                    work experience will be carefully studied. If you meet the
                    requirements for the selected vacancy, we will invite you to
                    the next stage. Try to briefly describe what you did in
                    previous positions: characterize the projects you worked on
                    and describe your exact responsibilities. List technologies
                    you used in previous projects. Since the first stage of
                    selection is based on a resume, the information in it should
                    be thorough and clear.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How can I interest Veeam if I don’t have any work
                      experience yet?
                    </strong>
                  </p>
                  <p>
                    If you don’t have any IT field experience, we will be
                    looking at your student projects or personal projects. We
                    will also look at your technical skills, education and any
                    certifications you have earned. We pay attention to anything
                    related to the IT field or software development.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>How do I prepare for an interview?</strong>
                  </p>
                  <p>
                    During the interview we will ask you about projects you have
                    worked on. It is best to highlight projects you feel were
                    the most significant and interesting to you. You should also
                    be ready to answer technical questions from the area you
                    would like to work in. You’ll also want to go over the
                    requirements for the vacancy that you are applying for and
                    information about our company.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What is Veeam’s work schedule?</strong>
                  </p>
                  <p>
                    Veeam employees have a flexible work schedule. We have a
                    five-day week (Monday through Friday), an eight-hour day
                    with a one-hour lunch break. The start time of a workday is
                    agreed upon individually with your team leader.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What is the company’s dress code?</strong>
                  </p>
                  <p>
                    Veeam does not have a dress code. Casual attire is always
                    okay.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Can I re-apply for a job in Corporate Technology?
                    </strong>
                  </p>
                  <p>
                    Yes, we are willing to review your application again if you
                    have gained additional experience and knowledge that you can
                    demonstrate during the interview.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Why are there always a lot of open positions at Veeam? Is
                      this related to staff turnover?{" "}
                    </strong>
                  </p>
                  <p>
                    This is due to the growth of the company. Most open jobs are
                    new positions created due to expansion, rather than
                    replacements for employees who have left Veeam.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default FaqCorporateTechnology
