const values = [
    {
        src: `//img.veeam.com/careers/img/img_ru/values/sells.svg`,
        list: [
            `We, collectively and individually, increase our company's profit`,
            `We understand what is important to our customers`,
            `We use our advantages, and know what distinguishes us from our competitors`
        ]
    },
    {
        src: `//img.veeam.com/careers/img/img_ru/values/team.svg`,
        list: [
            `We are passionate about what we do. Always`,
            `We help each other succeed`,
            `We are always open to the opinions of others`,
            `We know that lack of experience is not a problem`
        ]
    },
    {
        src: `//img.veeam.com/careers/img/img_ru/values/speed.svg`,
        list: [
            `We are hardworking and active`,
            `We are independent and have initiative`,
            `We do our best to finish the job`,
        ]
    },
    {
        src: `//img.veeam.com/careers/img/img_ru/values/keep_it_simple.svg`,
        list: [
            `We always think about efficiency`,
            `We do not overcomplicate, we keep it simple`
        ]
    },
    {
        src: `//img.veeam.com/careers/img/img_ru/values/innovate_and_terate.svg`,
        list: [
            `We work quickly and efficiently by solving tasks in stages`,
            `We try not to overanalyse, but try new things`,
            `We use our advantages and know what distinguishes us from our competitors`
        ]
    },
    {
        src: `//img.veeam.com/careers/img/img_ru/values/conversation.svg`,
        list: [
            `We are sincere and open`,
            `We are not exaggerating — our team and our products are really good`,
            `We are self-sufficient, but always happy to welcome new and talented people in the team`
        ]
    },
    {
        src: `//img.veeam.com/careers/img/img_ru/values/win.svg`,
        list: [
            `We love the excitement of competition and never give up`,
            `We are independent and initiative`,
            `We are optimistic and always believe in ourselves`
        ]
    }
];

export default values;
