const featuresPresales = [
    {
        icon: `//img.veeam.com/careers/departments/icons/icon_notebook.png`,
        content: `IT education and/or`,
        contentbottom: `work experience`
    },
    {
        icon: `//img.veeam.com/careers/departments/icons/icon_book.png`,
        content: `A fast learner who`,
        contentbottom: `likes to teach`
    },
    {
        icon: `//img.veeam.com/careers/departments/icons/icon_chat.png`,
        content: `Fluent in a foreign language and `,
        contentbottom: `interested in technology`
    },
    {
        icon: `//img.veeam.com/careers/departments/icons/icon_team.png`,
        content: `A team player`,
        contentbottom: ``
    }
];

export default featuresPresales;
