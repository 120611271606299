import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IFaqProps {
  department: IDepartment | null
}

class ProductManagement extends React.Component<IFaqProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <p className="text-placeholder">Product Management / PM</p>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div className="row block-spacer-bottom">
            <div className="col-12">
              <ol>
                <li>
                  <p>
                    <strong>
                      What principals do you follow when interviewing a
                      candidate?
                    </strong>
                  </p>
                  <p>
                    We try to comply with a balanced assessment of a candidate’s
                    abilities and potential. We also look at a candidate’s
                    priorities and aspirations.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>How can I get ready for the interview?</strong>
                  </p>
                  <p>
                    Learn as much as you can about our company, its products and
                    its technologies. You should also be prepared to be tested
                    on your foreign language knowledge, your analytical skills
                    and your technical skills.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do you only hire only professionals with extensive work
                      experience?
                    </strong>
                  </p>
                  <p>
                    No, we are interested in candidate with different levels of
                    work experience. Our company provides development
                    opportunities for professional growth and development.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Which degree is preferable for a position in Product
                      Management?
                    </strong>
                  </p>
                  <p>
                    Product Management is relatively young area of expertise,
                    and there are no specific degrees for this field.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Does this position involve travel?</strong>
                  </p>
                  <p>
                    Yes, business trips are possible. Participation in
                    exhibitions and conferences are a part of the job. Product
                    Managers actively interact with overseas colleagues,
                    partners and end users of Veeam IT solutions.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      If I don’t have relevant work experience, what are other
                      qualities that Veeam might be interested in?
                    </strong>
                  </p>
                  <p>
                    An excellent level of English proficiency and technical
                    knowledge is the way to go. We will also give you a test
                    task to understand if you are suitable for the position.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do you hire students? Is it possible to combine work and
                      school?
                    </strong>
                  </p>
                  <p>
                    The Product Manager has an intensive work schedule. We
                    mainly consider candidates who are ready to work full time.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How important it is for the Product Manager to understand
                      and share company’s goals?
                    </strong>
                  </p>
                  <p>
                    The Product Manager must know and understand the goals and
                    objectives of the company, with a vision of product
                    development that’s consistent with the company’s vision.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ProductManagement
