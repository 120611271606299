import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"
import { Link } from "react-router-dom"

interface IFaqProps {
  department: IDepartment | null
}

class TechnicalDocumentation extends React.Component<IFaqProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <p className="text-placeholder">Technical Writing Team</p>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div className="row block-spacer-bottom">
            <div className="col-12">
              <ol>
                <li>
                  <p>
                    <strong>
                      What language does a Technical Writer need to know?
                    </strong>
                  </p>
                  <p>
                    Veeam® technical documentation is currently developed in
                    English. Good knowledge of English is a must.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      I have no experience as a technical writer, but I'm
                      interested in the position. What is the minimum basic
                      knowledge and skills I must have to apply?
                    </strong>
                  </p>
                  <p>
                    Veeam will consider applicants without specific work
                    experience. However, it is very important for a candidate to
                    be fluent in English, have an interest in the IT industry,
                    possess a technical mindset, be a quick learner, and be a
                    competent writer. The documents our team creates must be
                    written correctly and clearly.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      The job description states that a candidate should know
                      the basics of HTML/CSS, XML. What level of knowledge is
                      required?
                    </strong>
                  </p>
                  <p>
                    Veeam Technical Writers perform a wide range of tasks
                    related to the development of documentation and its
                    preparation for publication. Often, when introducing new
                    formats or documentation templates, we make simple changes
                    to HTML code.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Are there now universities that train Technical Writers?
                    </strong>
                  </p>
                  <p>
                    Unfortunately, there is no such major today in universities.
                    Even the choice of educational courses for Technical Writers
                    is limited, and the knowledge gained in these courses is not
                    universal.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What qualities are most important for a Technical Writer?
                    </strong>
                  </p>
                  <p>
                    They are interested in technology and programming,
                    perseverance, attentiveness, and desire to master new
                    challenges and share knowledge.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            <Link
              className="card card-banner card-no-hover"
              to="/success/product-management"
            >
              <div className="card-header">
                <span className="icon-bg-whitepaper"></span>
                <span className="text-font">Working at Veeam</span>
              </div>
              <div className="card-body">
                <h3 className="display-3 header-custom-spacer text-font">
                  Product Management success story{" "}
                </h3>
              </div>
              <div className="card-footer pt-0">
                <a
                  className="btn btn-secondary"
                  href="/success/product-management"
                >
                  Read
                </a>
              </div>
            </Link>
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default TechnicalDocumentation
