import React from "react"
import { IVacancy } from "../../../types"

interface IVacancyItemProps {
  vacancy: IVacancy
}

class VacancyItem extends React.Component<IVacancyItemProps> {
  constructor(props: any) {
    super(props)
    this.updateTitle = this.updateTitle.bind(this)
    this.updateMeta = this.updateMeta.bind(this)
  }

  updateTitle(metaOg: any, desc: string) {
    return metaOg.setAttribute("content", desc)
  }

  updateMeta(vacancy: IVacancy, metTitle: any) {
    return (
      // @ts-ignore
      this.updateTitle(
        metTitle,
        vacancy && vacancy.title + " -  Job in Prague, Czechia | Veeam Software"
      ),
      (document.title =
        vacancy &&
        vacancy.title + " -  Job in Prague, Czechia | Veeam Software")
    )
  }

  componentDidMount(): void {
    let vacancy = this.props.vacancy
    let dynamicMetaTitleOg = document.querySelector('meta[property="og:title"]')

    this.updateMeta(vacancy, dynamicMetaTitleOg)
  }

  componentDidUpdate(
    prevProps: Readonly<IVacancyItemProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    let vacancy = this.props.vacancy
    let dynamicMetaTitleOg = document.querySelector('meta[property="og:title"]')

    this.updateMeta(vacancy, dynamicMetaTitleOg)
  }

  componentWillUnmount(): void {
    let dynamicMetaTitleOg = document.querySelector('meta[property="og:title"]')

    return (
      (document.title = " Job in Prague, Czechia | Veeam Software"),
      this.updateTitle(
        dynamicMetaTitleOg,
        " Job in Prague, Czechia | Veeam Software"
      )
    )
  }

  decodeHtml = (html: string) => {
    var txt = document.createElement("textarea")
    txt.innerHTML = html
    return txt.value
  }

  public render() {
    let vacancy = this.props.vacancy
    let titleClassName = vacancy.isHot ? "text-warning" : "text-normal"
    let date = new Date(vacancy.publicationDate)
    return (
      <div className="content-wrapper">
        <h3 className="text-placeholder header-custom-spacer">
          Jobs available in{" "}
          <span
            // @ts-ignore
            className={titleClassName}
          >
            {vacancy.department.title}
          </span>
        </h3>
        <h1 className="display-1">{vacancy.title}</h1>
        <p className="text-placeholder">
          Date of publication:{" "}
          {date.getDate() > 9 ? date.getDate() : `0` + date.getDate()}.
          {date.getMonth() >= 9
            ? date.getMonth() + 1
            : `0` + (date.getMonth() + 1)}
          .{date.getFullYear()}
        </p>
        <div className="text-placeholder mb-5">
          {vacancy.location.map(
            (location) => `${location.country} ${location.city}`
          )}
        </div>
        <div
          className="block-spacer"
          dangerouslySetInnerHTML={{
            __html: this.decodeHtml(vacancy.description),
          }}
        />
        {vacancy.responsibilities !== `` && (
          <div className="card">
            <div className="card-body">
              <h3 className="mb-4">Responsibilities:</h3>
              <div
                className="text-pre-wrap"
                dangerouslySetInnerHTML={{ __html: vacancy.responsibilities }}
              />
            </div>
          </div>
        )}
        {vacancy.qualifications !== `` && (
          <div className="card">
            <div className="card-body">
              <h3 className="mb-4">Qualifications:</h3>
              <div
                className="text-pre-wrap"
                dangerouslySetInnerHTML={{ __html: vacancy.qualifications }}
              />
            </div>
          </div>
        )}
        {vacancy.we_offer !== `` && (
          <div className="card">
            <div className="card-body">
              <h3 className="mb-4">We offer:</h3>
              <div dangerouslySetInnerHTML={{ __html: vacancy.we_offer }} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default VacancyItem
