import React from 'react';
import {Carousel} from 'react-bootstrap';
import learnMore from '../../mocks/learn-more';
import photos from '../../mocks/photos';
import socials from '../../mocks/social';
import reasons from '../../../shared/mocks.shared/reasons';
import LearnMore from '../../../shared/components.shared/components/learn-more/learn-more';
import Reason from '../../../shared/components.shared/components/reason/reason'
import Social from '../../../shared/components.shared/components/social/social';
import SocialPhotos from '../../components/social-photos/social-photos';


const Reasons: React.FC = () => {
    return (
        <React.Fragment>
            <img className="img-pattern img-pattern-left-top"
                 src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png" alt=""/>
            <img className="img-pattern img-pattern-right-top"
                 src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png" alt=""/>
            <div className="container banner-spacer">
                <h1 className="display-1">Why Veeam?</h1>
                <h3>In addition to career opportunities and professional development,
                    our employees have at least eight more reasons to work at Veeam<sup>®</sup>:</h3>
                <div className="card-deck">
                    {reasons.map((reason) =>
                        <Reason reasons={reason} key={`reason-${reason.title}`}/>
                    )}
                </div>
                <div className="block-spacer">
                    <h2>Follow us</h2>
                    <div className="card-deck d-flex flex-row justify-content-around">
                        {socials.map((social) =>
                            <Social social={social} key={`social-${social.link}`}/>
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-dark position-relative">
                <img className="img-pattern img-pattern-social"
                     src="//img.veeam.com/careers/banners/banners_ru/pattern_social.png" alt=""/>
                <div className="container container-spacer pb-0">
                    <p className="mb-4 text-success">#veeamdreamteam</p>
                    <h2>At Veeam we have an amazing team <br/>where you will be appreciated.</h2>
                </div>
                <div className="container-spacer pt-0">
                    <Carousel className="d-none d-md-block carousel-photos">
                        <Carousel.Item>
                            <div className="card-deck flex-row flex-nowrap">
                                {photos[0].map((photo) =>
                                    <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                )}
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="card-deck flex-row flex-nowrap">
                                {photos[1].map((photo) =>
                                    <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                )}
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="card-deck flex-row flex-nowrap">
                                {photos[2].map((photo) =>
                                    <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                )}
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    <Carousel className="d-clock d-md-none">
                        {photos[0].map((photo) =>
                            <Carousel.Item>
                                <div className="card-deck flex-row flex-nowrap justify-content-center">
                                    <SocialPhotos photos={photo} key={`photo-${photo.title}`}/>
                                </div>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Reasons;
