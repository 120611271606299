import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutDevelopment = ({ department }: BaseDepartmentPagePropsType) => {
  return (
    <React.Fragment>
      <img
        className="img-pattern img-pattern-left-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
        alt=""
      />
      <img
        className="img-pattern img-pattern-right-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
        alt=""
      />
      <div className="container container-spacer-lg pb-0">
        <h1 className="display-1">Research & Development / R&D</h1>
        <div className="row block-spacer">
          <div className="col-12 col-md-7">
            <p>
              Veeam® specializes in creating boxed software products for the
              business-to-business market, with a focus on administrators of
              information systems that use VMware and Microsoft virtualization
              technologies.
            </p>
            <p className="block-spacer mt-0">
              Thanks to an innovative approach to development, plus a
              professional research and development team, Veeam provides the # 1
              data protection and recovery solutions in the EMEA region,
              according to the 2020 IDC Semi-Annual Software Tracker for Data
              Replication & Protection.
            </p>
            <h3>Why programmers want to work for us: </h3>
            <p>
              Founded in 2006, Veeam has been a long-time leader in providing
              Cloud Data Management™ for large enterprises. We are constantly
              growing, moving forward and creating new opportunities to help our
              employees achieve their professional and career goals.
            </p>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
            <img
              src="//img.veeam.com/careers/img/img_ru/about/development/rd_diagram.png"
              alt=""
              className="img-fluid mb-5 mb-lg-0"
            />
          </div>
        </div>
        <div className="card-deck flex-column">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5 text-center">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/development/about_1.svg"
                    alt=""
                    className="img-fluid mb-5 mb-lg-0 w-65"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <p>
                    <strong>What this means for you:</strong>
                  </p>
                  <p>
                    At Veeam, you will learn how to create quality solutions
                    that work for a wide range of configurations and diverse
                    infrastructures, along with how to develop products that can
                    be effectively supported remotely.
                  </p>
                  <p>
                    Unlike custom software, there is no specifically- designed
                    configuration for our solutions. The product must work on
                    all architectures a user may encounter. If an error occurs,
                    technical support specialists must be able to understand its
                    cause without going “into the field.” To do this, our
                    solutions are equipped with several additional components
                    (i.e., subsystems).
                  </p>
                  <p>
                    However, when the number of users reaches hundreds of
                    thousands, statistics come into play — there will always be
                    a client who is unsatisfied. Fortunately, we don’t scrimp on
                    technology or on refining our solutions; we focus on
                    dependability. This is provided at all levels of
                    development, from the code’s architectural development to
                    the testing laboratories.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-7">
                  <p>
                    <strong>
                      You can be at the apex of the latest technological
                      advances.
                    </strong>
                  </p>
                  <p>
                    Veeam has been creating backup products for data centers and
                    cloud infrastructures since 2006. We started as a developer
                    of a highly specialized solution to back up virtual machines
                    (VMs) on the VMware platform. Since then, we’ve been
                    constantly introducing new technologies and implementing the
                    most advanced functionalities in order to successfully
                    compete in the software solutions market.
                  </p>
                  <p>
                    We collaborate with hypervisor and cloud manufacturers and
                    provide an SDK for working with physical machines. Our
                    partners include the world’s leading companies, including
                    NetApp, HP, Hitachi and more.
                  </p>
                </div>
                <div className="col-12 col-md-5 text-center order-first order-md-last">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/development/about_2.svg"
                    alt=""
                    className="img-fluid mb-5 mb-lg-0 w-65"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5 d-flex align-items-center justify-content-center">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/development/about_3.svg"
                    alt=""
                    className="img-fluid mb-5 mb-lg-0 w-65"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <p>
                    We help develop technologies that are not yet available to
                    the public by participating in beta programs and sending
                    feedback about the proposed solutions to manufacturers. This
                    is how we influence how new products will look in their
                    final version.
                  </p>
                  <p>
                    <strong>Our development stack includes:</strong> C#, C++,
                    C++CLI, JS, ReactJS, REST, PowerShell, Visual Studio, Visual
                    Studio Code, Rider; Microsoft TFS, Git, Jenkins, TeamCity,
                    Go and many more.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-7">
                  <p>
                    <strong>“It just works” </strong> is what we always hear
                    from our clients.
                  </p>
                  <p>
                    These aren’t just empty words. The philosophy of our
                    solutions is good quality above all else. Products must be
                    intuitive and work 24.7.365 in any data center.{" "}
                  </p>
                  <p>Our solutions are also recognized in the field:</p>
                  <ul>
                    <li>
                      Veeam — #1 Data Protection Supplier in EMEA (Europe,
                      Middle East, Africa) according to IDC Software Tracker*
                    </li>
                    <li>
                      Veeam — Recognized Global Leader according to Gartner
                      reports
                    </li>
                  </ul>
                  <p>
                    You can read about our other awards{" "}
                    <a
                      className="btn btn-link"
                      href="//www.veeam.com/awards.html"
                      target="_blank"
                    >
                      here
                    </a>
                  </p>
                </div>
                <div className="col-12 col-md-5 d-flex align-items-center justify-content-center order-first order-md-last">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/development/about_4.svg"
                    alt=""
                    className="img-fluid mb-5 mb-lg-0 w-65"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5 d-flex align-items-center justify-content-center">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/development/about_5_new.svg"
                    alt=""
                    className="img-fluid mb-5 mb-lg-0 w-65"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <p>
                    <strong>Career growth opportunities</strong>
                  </p>
                  <p>
                    Having spent several years in their industry gaining solid
                    experience, professionals are eventually faced with making a
                    choice about their future career path. For developers, as a
                    rule, there are two main lines of growth and development:
                    Technical and administrative roles. We’re no exception. Our
                    careers in research and development follow a path of
                    administrative growth and technical leadership. The first
                    path is chosen by those who want to develop their managerial
                    skills, and the second by those who prefer doing technical
                    things and diving deeper into their specialty. You can also
                    combine both, which is encouraged.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container-spacer">
        <h3 className="display-3">Career development in R&D department</h3>
        <div className="row">
          <div className="col-12 text-center">
            <img
              src="//img.veeam.com/careers/img/img_ru/about/development/about_scheme.svg"
              alt=""
              className="img-fluid mb-5 mb-lg-0 d-none d-lg-inline-flex"
            />
            <img
              src="//img.veeam.com/careers/img/img_ru/about/development/about_scheme-mobile.png"
              alt=""
              className="img-fluid mb-5 mb-lg-0 w-50 d-inline-flex d-lg-none"
            />
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="card-deck card-deck-banners block-spacer">
          {department && (
            <SidebannerItem banner={sidebanner[0]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[1]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[2]} department={department} />
          )}
        </div>
        {department && (
          <VacanciesSimilar department={department} learnMoreData={learnMore} />
        )}
        <div className="card-deck card-deck-banners block-spacer">
          {companybanner.slice(0, 3).map((banner) => (
            <BannersCompany banner={banner} key={`banner-${banner.title}`} />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutDevelopment
