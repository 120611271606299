import React from "react"
import FaqQa from "./faq-qa"
import FaqCorporateTechnology from "./faq-corporate-technology"
import FaqDevelopment from "./faq-development"
import FaqSupport from "./faq-support"
import { IDepartment } from "../../../shared/types"
import ProductManagement from "./product-management"
import TechnicalDocumentation from "./technical-documentation"
import NotFound from "../../../shared/components.shared/components/not-found/not-found"
import { DepartmentAliases } from "../../types"

interface IFaqAllProps {
  department: IDepartment | null
  alias: string
}

export const FaqAll = ({ department, alias }: IFaqAllProps) => {
  const getFaqDepartment = () => {
    switch (alias) {
      case DepartmentAliases.ResearchAndDevelopment:
        return <FaqDevelopment department={department} />
      case DepartmentAliases.CorporateTechnology:
        return <FaqCorporateTechnology department={department} />
      case DepartmentAliases.QualityAssurance:
        return <FaqQa department={department} />
      case DepartmentAliases.ProductManagement:
        return <ProductManagement department={department} />
      case DepartmentAliases.TechnicalDocumentationDevelopment:
        return <TechnicalDocumentation department={department} />
      case DepartmentAliases.TechnicalCustomerSupport:
        return <FaqSupport department={department} />
      default:
        return <NotFound />
    }
  }

  return getFaqDepartment()
}
