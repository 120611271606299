import React from 'react';

interface ITypewriterTextProps {
    items: string[];
    delay?: number
}

interface ITypewriterTextState {
    active: number;
    text: string;
}


class TypewriterText extends React.Component<ITypewriterTextProps, ITypewriterTextState> {

    interval: number | null;

    constructor(props: ITypewriterTextProps) {
        super(props);
        this.state = {
            active: 0,
            text: ''
        };
        this.interval = null;
        this.animate = this.animate.bind(this);
    }

    public componentDidMount(): void {
        this.animate();
    }

    public componentWillUnmount(): void {
        if (this.interval !== null) {
            clearInterval(this.interval);
        }
    }

    public render() {
        return (
            <em>{this.state.text}|</em>
        )

    }

    protected animate(): void {
        let done = false,
            writing = true,
            delayCount = 5,
            delay = this.props.delay || 100;
        // @ts-ignore
        this.interval = setInterval(() => {
            if (!done && delayCount === 0) {
                let {text, active} = this.state,
                    {items} = this.props,
                    result = text;
                if (writing && text.length < items[active].length) {
                    result = items[active].substr(0, text.length + 1);
                    writing = result.length !== items[active].length;
                    if (!writing) {
                        delayCount = 5;
                    }
                } else {
                    result = items[active].substr(0, text.length - 1);
                    writing = result.length === 0;
                    done = writing;
                }
                this.setState({
                    text: result
                });
            } else if (delayCount > 0) {
                delayCount -= 1;
            } else {
                delayCount = 5;
                this.setState({
                    active: this.state.active < this.props.items.length - 1 ? this.state.active + 1 : 0
                });
                done = false;
            }
        }, delay);

    }
}

export default TypewriterText
