import React from 'react';
import VacanciesList from '../vacancies-list/vacancies-list';
import SidebannerVacancies from '../sidebanner-vacancies/sidebanner-vacancies';
import {ILearnMore, IVacancyDepartment} from '../../../types';

interface IVacancyProps {
    department: IVacancyDepartment;
    learnMoreData: ILearnMore;
}

class VacanciesSimilar extends React.Component<IVacancyProps> {

    public render() {
        let learnMoreData = this.props.learnMoreData;
        return (
            <section>
                <h2>Similar jobs:</h2>
                <div className="row">
                    <div className="col-12 col-md-8 mb-5 mb-md-0">
                        <VacanciesList limit={3} departmentAlias={this.props.department.alias} learnMoreData={learnMoreData}/>
                    </div>
                    <div className="col-12 col-md-4">
                        <SidebannerVacancies department={this.props.department}/>
                    </div>
                </div>
            </section>
        )
    }
}

export default VacanciesSimilar
