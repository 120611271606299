import React from 'react';
import {Link} from 'react-router-dom';
import {IVacancy} from '../../../types';

interface IVacancyListItemProps {
    vacancy: IVacancy
}

class VacancyListItem extends React.Component<IVacancyListItemProps> {

    public render() {
        let vacancy = this.props.vacancy;
        let titleIcon = vacancy.isHot ? 'icon icon-bg-alert' : '';
        let titleClassName = vacancy.isHot ? 'text-warning' : 'text-placeholder';

        return (
            <Link to={`/vacancies/${vacancy.department.alias}/${vacancy.routeAlias}`} className="card card-sm card-no-hover">
                <div className="card-header pb-2">
                    {titleIcon === `` ? `` : <span className={titleIcon}></span>}
                    <span className={titleClassName}>{vacancy.department.title}</span>
                </div>
                <div className="card-body py-0">
                    <h3 className="header-custom-spacer-md text-font">{vacancy.title}</h3>
                </div>
                <div className="card-footer pt-3 flex-column flex-sm-row">
                    <span className="location">
                        <span className="icon icon-bg-globe_green d-none d-md-flex"></span>
                        <span
                            className="text-placeholder">{vacancy.location.map((item) => item.city
                        )}, {
                                vacancy.location.map((item) => item.country)} </span></span>
                    <Link className="btn btn-link btn-link-cta mt-4 mt-sm-0 py-0" data-vacancy={vacancy.title} to={`/vacancies/${vacancy.department.alias}/${vacancy.routeAlias}`}>Learn more</Link>
                </div>
            </Link>
        );
    }
}

export default VacancyListItem;
