import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IDepartmentSuccessProps {
  department: IDepartment | null
}

class DepartmentSuccessCorporateTechnology extends React.Component<IDepartmentSuccessProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <p className="text-placeholder">Corporate Technology</p>
              <h1 className="display-1">Success Story</h1>
              <h3 className="header-custom-spacer-md">Natalia Masson</h3>
              <h3 className="text-placeholder">
                Manager, Data Management Platforms
              </h3>
              <div className="block-spacer">
                <ul className="list-unstyled">
                  <li>
                    <p>
                      <strong>
                        Can you describe the hiring process when you interviewed
                        at Veeam®?
                      </strong>
                    </p>
                    <p>
                      I started working at Veeam in 2015 after finishing a major
                      project at my previous job. I wanted to continue working
                      as an analyst, but my previous workplace lacked that
                      opportunity. I updated my resume and, shortly after, I was
                      contacted by the Veeam Recruitment Team. Even then, Veeam
                      was a major player in the market, so I was very
                      interested.
                    </p>
                    <p>
                      During the interview, it became obvious that the job
                      implied working in the department of Backend Systems (the
                      previous name was Computer Information Systems department,
                      or CIS). I was somewhat disappointed at first because at
                      many companies, the department for development of internal
                      systems is rather small and deals mostly with writing
                      time-tracker. Then, I after I had a chance to talk to
                      several of my future colleagues, I was pleasantly
                      surprised that, even then, the department was large and
                      included several platforms with complex logic.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        How has your career advanced at the company?
                      </strong>
                    </p>
                    <p>
                      I came to Veeam because I wanted to continue working as an
                      analyst, but after several weeks, I was offered a role as
                      a product owner on a wonderful team, which already existed
                      and supported our web site. I decided to give it a try
                      because I was interested in the scrum process. I was
                      partly involved in the scrum process’s set up at my
                      previous employment. So, I became a manager, rather than
                      analyst.
                    </p>
                    <p>
                      Over time, the company had a need for a data warehouse,
                      and that’s how my current team, the Data Warehouse (DWH)
                      Team, was formed. At first, there was only me and a
                      technical leader. Later, we added an analyst. We currently
                      have 11 people on the team: database engineers, data
                      analysts, a business analyst, a systems analyst and a
                      tester.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        What do the Corporate Technology department and the DWH
                        team do?
                      </strong>
                    </p>
                    <p>
                      Veeam has two large directions. The first one is product
                      direction because if you don’t have a great product there
                      is nothing to sell. When this great product appears, you
                      must develop marketing and sales, and this is the second
                      global direction. The larger the company, the wider and
                      more complex set of tools used. And let’s not forget that
                      any business should develop. Therefore, is it crucial for
                      the company to have strong internal development teams to
                      support technical platforms. It is vital for the
                      understanding of how this whole complex ecosystem will
                      grow further for these teams to work together and interact
                      well with one another. This is what Corporate Technology
                      does.
                    </p>
                    <p>
                      The Corporate Technology department has grown a lot,
                      compared to when I started. Five years ago, it only
                      consisted of four to five teams and two of them supported
                      the website. Now, it is a large cross-platform unit.
                    </p>
                    <p>
                      Our team is developing and supporting a data warehouse.
                      When a company reaches a certain size, it becomes too
                      expensive to store and process data in different places.
                      Instead, you need to have a kernel where all data is
                      stored and processed. We have an interesting and complex
                      set of technical requirements because the storage must be
                      stable, be fault-tolerant, must integrate with other
                      systems and must blend into a variety of processes. As a
                      result, the storage becomes like a hub, on which many of
                      the company's business processes rely on.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        What qualities do you consider to be the most important
                        when you look for new team members?
                      </strong>
                    </p>
                    <p>
                      On the one hand, we pay close attention to technical
                      skills because hard skills are very important for working
                      with data. On the other hand, soft skills of our potential
                      employees are also crucial to us. Risks connected with the
                      support of a data warehouse are high, so the team must
                      understand each other very well and be open to dialog.
                    </p>
                    <p>
                      We try to ensure that all newcomers quickly get involved
                      in the processes. Additionally, the desire to learn
                      something new is critical, even if a person is already an
                      excellent specialist in any given technology. Furthermore,
                      if you look closely at our team, you will see that we are
                      all quite different. We are not looking for a specific
                      type, but rather for specialists who can offer new ideas
                      and work well together. We try to be flexible and expect
                      this from potential candidates.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        What personal qualities should a Veeam employee have?
                      </strong>
                    </p>
                    <p>
                      For such a large company, we have an open organizational
                      structure and very dynamic work. It is unlikely that one
                      person can always rely on one skill or technology.
                      Overall, Veeam offers excellent opportunities for
                      professional growth.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4 order-md-2 order-first  mb-5 mb-md-0">
              <img
                src="//img.veeam.com/careers/img/img_ru/success-departments/success_cis.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container container-spacer pt-0">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DepartmentSuccessCorporateTechnology
