import React from 'react';
import {ISuccessStories} from '../../../types';

interface ISuccessStoriesProps {
    story: ISuccessStories;
}

class SuccessStories extends React.Component<ISuccessStoriesProps> {
    public render() {
        let story = this.props.story;

        return (
            <div className="card rounded-0 border-0 bg-transparent">
                <img className="img-fluid" src={story.image} alt=""/>
                <figcaption className="figure-caption mt-5"><strong>{story.company}</strong></figcaption>
                <a href={story.link} className="btn btn-link btn-link-cta" target="_blank">Customer story</a>
            </div>
        )
    }
}

export default SuccessStories
