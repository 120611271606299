import React from 'react';
import {ISocial} from '../../../types';

interface ISocialProps {
    social: ISocial;
}

class Social extends React.Component<ISocialProps> {
    public render() {
        let social = this.props.social;

        return (
            <a href={social.link} target="_blank" className="card border-0 flex-column">
                <div className="card-header card-header-fixed p-0">
                    <img src={social.image} alt=""/>
                </div>
            </a>
        )
    }
}

export default Social;
