import React from "react"
import { IVacancy } from "../../../types"

interface IVacancyButton {
  vacancy: IVacancy
}

class ApplyButton extends React.Component<IVacancyButton> {
  public render() {
    let vacancy = this.props.vacancy

    const appendParam = (url: string, param: string) => {
      const hasParams = url.indexOf("?") !== -1

      if (hasParams) {
        return url + "&" + param
      } else {
        return url + "?" + param
      }
    }

    const param = "gh_src=22853c72teu"
    const applyUrl = appendParam(vacancy.applyUrl, param)

    return (
      <a
        href={applyUrl}
        className="btn btn-success btn-cta mr-lg-4"
        rel="noopener noreferrer"
        target="_blank"
      >
        Apply for a job
      </a>
    )
  }
}

export default ApplyButton
