import React from 'react'
import { Carousel } from 'react-bootstrap'
import Awards from '../../../shared/components.shared/components/awards/awards'
import CompanyDetails from '../../../shared/components.shared/components/company-details/company-details'
import OurProducts from '../../../shared/components.shared/components/our-products/our-products'
import Partners from '../../../shared/components.shared/components/partners/partners'
import QuoteItem from '../../../shared/components.shared/components/quote-item/quote-item'
import Social from '../../../shared/components.shared/components/social/social'
import SuccessStories from '../../../shared/components.shared/components/success-stories/success-stories'
import Values from '../../../shared/components.shared/components/values/values'
import partners from '../../../shared/mocks.shared/partners'
import stories from '../../../shared/mocks.shared/success-stories'
import values from '../../../shared/mocks.shared/values'
import Video from '../../components/video/video'
import awards from '../../mocks/awards'
import details from '../../mocks/details'
import products from '../../mocks/products'
import quotes from '../../mocks/quotes'
import socials from '../../mocks/social'

interface ICompanyProps {
  setMenuTransparency(value: boolean): void
}

class Company extends React.Component<ICompanyProps> {
  componentDidMount(): void {
    this.props.setMenuTransparency(true)
  }

  componentWillUnmount(): void {
    this.props.setMenuTransparency(false)
  }

  public render() {
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="bg-dark bg-about-company">
          <div className="container banner-spacer">
            <div className="row">
              <div className="col-12 col-md-8">
                <h1 className="display-1">Veeam is a leader</h1>
                <p>
                  Our company is a leading developer of backup, disaster
                  recovery and data management software for virtual, physical
                  and cloud-based workloads.
                </p>
                <p>
                  We take care of all stages of the life cycle for our
                  solutions: development and testing, implementation through an
                  extensive affiliate network and technical support in different
                  languages.
                </p>
                <p>
                  Veeam grows everyday and that is why we are always searching
                  for the right people: from sales representatives to IT
                  developers.{' '}
                </p>
                <div className="card-deck container-spacer pb-0">
                  {products.map((product) => (
                    <OurProducts
                      data={product}
                      key={`product-${product.title}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container container-spacer">
          <h2>Veeam is proud of:</h2>
          <div className="card-deck card-deck-numbers">
            {details.map((detail) => (
              <CompanyDetails details={detail} key={`detail-${detail.count}`} />
            ))}
          </div>
        </div>
        <div className="container container-spacer">
          <h2>
            How Veeam helps protect data and keep your business running
            smoothly:
          </h2>
          <div className="card-deck card-deck-success">
            {stories.map((story) => (
              <SuccessStories story={story} key={`story-${story.link}`} />
            ))}
          </div>
        </div>
        <div className="container container-spacer">
          <h2>Our partners:</h2>
          <div className="card-deck card-deck-partners flex-row flex-wrap justify-flex-start justify-content-xl-between">
            {partners.map((partner) => (
              <Partners partner={partner} key={`partner-${partner}`} />
            ))}
          </div>
        </div>
        <div className="bg-light">
          <div className="container container-spacer">
            <h2>What customers say about us:</h2>
            <div className="row justify-content-center">
              <div className="col-12 col-md-9 text-center">
                <Carousel>
                  {quotes.map((quote) => (
                    <Carousel.Item key={`quote-${quote.title}`}>
                      <QuoteItem quote={quote} />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <a
                  href="//www.gartner.com/reviews/market/data-center-backup-and-recovery-solutions/vendor/veeam/product/veeam-backup-replication
        "
                  className="btn btn-outline-success block-spacer-top"
                  target="_blank"
                >
                  More feedback
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container container-spacer-lg">
          <h2>Follow us</h2>
          <div className="card-deck d-flex flex-row justify-content-around">
            {socials.map((social) => (
              <Social social={social} key={`social-${social.link}`} />
            ))}
          </div>
        </div>
        <div className="container container-spacer">
          <h2>Rustonka office in Prague</h2>
          <div className="row">
            <div className="col-12 col-md-6">
              <p>
                Veeam® Software is an international IT company that develops
                backup, disaster recovery and data management software for
                virtual, physical and cloud-based workloads. Founded in 2006,
                with headquarters in Baar, Switzerland, Veeam now globally
                employs over 4200+ employees in 35+ countries.
              </p>
              <p className="mb-3">
                Our first office in Czech Republic was set up in 2011 as a Sales
                and Marketing office and was upgraded to the R&D hub in 2016.
                Our existing products are actively developed, and new projects
                are started. Our departments — mainly the QA & Development teams
                — are growing rapidly and provide a perfect chance for talented
                people to implement their professional and career plans.
                Constant progress and development is our priority! If you share
                our vision, become part of the Veeam Team!
              </p>
              <div className="card border-0">
                <div className="card-body">
                  <p className="contacts__map">
                    BC Rustonka building R3, Sokolovská street 695 / 115b,
                    Prague 8-Karlín, 186 00
                  </p>
                  <p className="contacts__phone">
                    <a href="tel:+420 226 251 819">+420 226 251 819</a>
                    <span className="pl-lg-5">
                      Toll-Free:{' '}
                      <span className="text-success">800 022 924</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-5 mb-md-0">
              <Video />
            </div>
            <div className="col-12 ">
              <iframe
                src="//my.matterport.com/show/?m=W3gV1k265H5"
                width="100%"
                height="412"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="container container-spacer">
          <h2>Our awards</h2>
          <Carousel className="carousel-awards">
            {awards.map((award) => (
              <Carousel.Item key={`award-${award.title}`}>
                <Awards award={award} />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="bg-light">
          <div className="container container-spacer">
            <h2>Our values</h2>
            <div className="card-deck">
              {values.map((value) => (
                <Values value={value} key={`value-${value.src}`} />
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Company
