import React from 'react';
import {ICompanyDetails} from '../../../types';

interface ICompanyDetailsProps {
    details: ICompanyDetails
}

class CompanyDetails extends React.Component<ICompanyDetailsProps> {
    public render() {
        let details = this.props.details;
        return (
            <div className="card border-0 bg-transparent">
                <div className="card-body">
                    <div className="card-data">
                        <h2 className="display-digits text-success mb-2"><em className="nowrap">{details.count}</em>
                        </h2><span
                        className="font-base">{details.details}</span></div>
                </div>
            </div>
        )
    }
}

export default CompanyDetails
