import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import companybanner from '../../mocks/banners/mocks-companybanners';
import learnMore from '../../mocks/learn-more';
import ApplyButton from '../../../shared/components.shared/components/apply-button/apply-button'
import BannersCompany from '../../../shared/components.shared/components/banners-company/banners-company';
import ShareVacancy from '../../../shared/components.shared/components/share-vacancy/share-vacancy'
import SidebannerVacancies from '../../../shared/components.shared/components/sidebanner-vacancies/sidebanner-vacancies';
import VacancyItem from '../../../shared/components.shared/components/vacancy-item/vacancy-item';
import VacanciesList from '../../../shared/components.shared/components/vacancies-list/vacancies-list';
import {VacanciesContext} from '../../../shared/components.shared/components/providers/vacancies.context';

interface IVacancyProps extends RouteComponentProps <{
    department: string,
    alias: string
}> {
}

class Vacancy extends React.Component<IVacancyProps> {

    static contextType = VacanciesContext;
    context!: React.ContextType<typeof VacanciesContext>;

    componentDidUpdate(prevProps: IVacancyProps) {
        if (prevProps.match.params.alias !== this.props.match.params.alias) {
            window.scrollTo(0, 0);
        }
    }

    public render() {
        let vacancy = this.context && this.context.data && this.context.data.find(item => item.routeAlias === this.props.match.params.alias);
        let isCompleted = this.context && this.context.isCompleted;
        let isLoading = this.context && this.context.isLoading;
        return (
            <React.Fragment>
                <img className="img-pattern img-pattern-left-top"
                     src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png" alt=""/>
                <img className="img-pattern img-pattern-right-top"
                     src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png" alt=""/>
                <div className="container container-spacer-lg">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            {isLoading && <h4 className="text-center">Loading</h4>}
                            {isCompleted && vacancy && <VacancyItem vacancy={vacancy}/>}
                            {isCompleted && !vacancy && <h1>Job was not found</h1>}
                            <div className="card card-cta">
                                <div className="card-body py-0">
                                    {isCompleted && vacancy && <ApplyButton vacancy={vacancy}/>}
                                    {isCompleted && vacancy && <ShareVacancy vacancy={vacancy}/>}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="btn-wrapper d-none d-md-block">
                                {isCompleted && vacancy && <ApplyButton vacancy={vacancy}/>}
                            </div>
                            <BannersCompany banner={companybanner[3]}/>
                        </div>
                    </div>
                    <div className="col-12"><h2>Similar jobs</h2></div>
                    <div className="row">
                        <div className="col-12 col-md-8 mb-5 mb-md-0">
                            <VacanciesList limit={3} departmentAlias={vacancy && vacancy.department.alias}
                                           excludedVacancy={vacancy} learnMoreData={learnMore}/>
                        </div>
                        <div className="col-12 col-md-4">
                            {vacancy && vacancy.department &&
                            <SidebannerVacancies department={vacancy && vacancy.department}/>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

export default Vacancy;
