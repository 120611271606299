import React from "react"
import { Link } from "react-router-dom"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IStagesProps {
  department: IDepartment | null
}

class StagesTechnicalDocumentation extends React.Component<IStagesProps> {
  public render() {
    let department = this.props.department

    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <div className="row block-spacer mt-0">
            <div className="col-12 col-md-8">
              <p className="text-placeholder">Technical Writing Team</p>
              <h2>Interview stages</h2>
              <p>
                To join the team you have to pass several interview stages.
                Don’t be surprised with if the interview will be somewhat
                informal. The culture at Veeam® is casual and friendly.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>1. Preliminary candidate screening</h3>
                      <p>
                        Preliminary selection is based on the information from
                        your resume. What matters during this stage?{" "}
                      </p>
                      <ul>
                        <li>
                          Proficiency in English, which is our source language
                        </li>
                        <li>
                          Projects you participated in and your specific role
                        </li>
                        <li>Your education</li>
                        <li>
                          Any other information to help Veeam evaluate your
                          knowledge and potential for this role
                        </li>
                      </ul>
                      <p>
                        <strong>TIP:</strong> Go to the{" "}
                        <a
                          className="btn btn-link"
                          href="//www.veeam.com/documentation-guides-datasheets.html"
                          target="_blank"
                        >
                          documentation section
                        </a>{" "}
                        on the corporate website for examples of technical
                        documents to get a general idea of what a{" "}
                        <strong>Technical Writer</strong> creates.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_1.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_2.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>2. Telephone interview with an HR Representative</h3>
                      <p>
                        During a telephone interview, an HR Representative will
                        get to know you and learn more about your experience.
                        The HR Representative will discuss the open position,
                        determine your interest in the positions, and answer
                        your questions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>3. Home task </h3>
                      <p>
                        After the telephone interview with the HR
                        Representative, you will be asked to complete a home
                        task, which differs depending on the position you are
                        applying to (junior, middle or senior levels). This task
                        will assess your knowledge of English and your ability
                        to process information.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/about/technical-documentation/section_2.png"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_4.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>4. Interview</h3>
                      <p>
                        The interview lasts for about an hour and a half and
                        consists of several parts:
                      </p>
                      <ul>
                        <li>
                          Getting acquainted and discussing your interests and
                          professional goals.
                        </li>
                        <li>
                          Asking you about any technologies mentioned in your
                          resume.
                        </li>
                        <li>
                          Answering your questions about working in the team.
                        </li>
                      </ul>
                      <p>
                        If you fail to answer questions about a specific tool or
                        process, this does not necessarily mean that you are not
                        a good fit for Veeam. Veeam evaluates your ability to
                        think, whether you want to develop your skills and if
                        you are ready to work towards your career goals.
                      </p>
                      <p>Tips to prepare for the interview</p>
                      <ul>
                        <li>
                          Make sure you're familiar with the topics you
                          mentioned in your resume.
                        </li>
                        <li>
                          Read about industry best practices (for example{" "}
                          <a
                            href="//www.veeam.com/documentation-guides-datasheets.html"
                            className="btn btn-link"
                            target="_blank"
                          >
                            here
                          </a>
                          )
                        </li>
                        <li>
                          Read our{" "}
                          <a
                            className="btn btn-link"
                            href="//helpcenter.veeam.com/docs/styleguide/tw/intro.html"
                          >
                            style guide
                          </a>
                        </li>
                        <li>Read about Veeam and its products.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>5. Test task</h3>
                      <p>
                        After successfully passing the interview, Veeam will
                        bring you to the office or connect with you via a video
                        call for the most important task. The test task is like
                        the home task you did earlier but with the time limit.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/about/technical-documentation/section_2.png"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_5.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>6. Feedback after the interview</h3>
                      <p>
                        A few days after the interview, an HR Representative
                        will contact you by phone or email with your results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>7. Job offer</h3>
                      <p>
                        After successfully completing the interview process, you
                        will receive an official job offer. If you accept it,
                        Veeam will discuss your start date so your new career
                        can begin!
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/cis/stage_6.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            <Link
              className="card card-banner card-no-hover"
              to="/success/product-management"
            >
              <div className="card-header">
                <span className="icon-bg-whitepaper"></span>
                <span className="text-font">Working at Veeam</span>
              </div>
              <div className="card-body">
                <h3 className="display-3 header-custom-spacer text-font">
                  Product Management success story{" "}
                </h3>
              </div>
              <div className="card-footer pt-0">
                <a
                  className="btn btn-secondary"
                  href="/success/product-management"
                >
                  Read
                </a>
              </div>
            </Link>
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StagesTechnicalDocumentation
