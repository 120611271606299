import React from 'react'
import {IEmployees} from '../../../types';

interface IEmployeesProps {
    quote: IEmployees;
}

class CarouselEmployees extends React.Component<IEmployeesProps> {
    public render() {
        let quote = this.props.quote;
        return (
            <div className="row row-spacer-lg">
                <div className="col-12 col-lg-7">
                    <blockquote className="blockquote blockquote-fixed">
                        <p>{quote.quote}</p>
                        <footer className="blockquote-footer">
                            <strong className="text-blockquote">{quote.name}</strong><br/> {quote.position}</footer>
                    </blockquote>
                </div>
                <div className="col-12 col-lg-5 d-none d-lg-block">
                    <div className="card border-0">
                        <div className="card-body">
                            <img alt="quote" className="img-fluid img-fluid-blockquote" src={quote.image}/>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default CarouselEmployees;
