const stories = [
    {
        image: '//img.veeam.com/careers/banners/banners_ru/success_porsche.png',
        link: '//www.veeam.com/success-stories/veeam-porsche.html',
        company: 'Porsche Informatik'
    },
    {
        image: '//img.veeam.com/careers/banners/banners_ru/success_hardrock.png',
        link: '//www.veeam.com/success-stories/hard-rock-hotel-and-casino-success-story.html',
        company: 'Hard Rock Hotel & Casino'
    },
    {
        image: '//img.veeam.com/careers/banners/banners_ru/success_loreal.png',
        link: '//www.veeam.com/success-stories/loreal-denmark-ensures-reliable-rto-and-rpo-with-veeam-backup-and-replication.html',
        company: 'L’Oréal Denmark'
    },
    {
        image: '//img.veeam.com/careers/banners/banners_ru/success_raiffeisen.png',
        link: '//www.veeam.com/success-stories/european-bank-chooses-veeam-backup-and-replication-for-its-simplicity-and-reliability.html',
        company: 'Raiffeisen Bank Polska S. A.'
    }
];

export default stories;
