const interviewStagesQa = [
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_user.png`,
        content: `1. Preliminary candidate screening`,
        contentbottom: ``
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_mobile.png`,
        content: `2. HR interview`,
        contentbottom: ``
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_note.png`,
        content: `3. Technical interview`,
        contentbottom: ``
    }
];


export default interviewStagesQa;
