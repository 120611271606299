import React from 'react';

const VacancySales: React.FC = () => {
    return (
        <div className="card-deck mb-5">
            <div className="card">
                <div className="card-header">
                    <h5 className="text-with-icon text-warning mb-0">Hot job!</h5>
                </div>
                <div className="card-body py-0 text-left d-flex flex-column justify-content-between">
                    <h1>Junior QA Engineer</h1>
                    <p>Just graduated from university and taking your first steps into the IT world?</p></div>
                <div className="card-footer justify-content-end">
                    <a className="btn btn-success" href="/vacancies/quality-assurance/junior_qa_engineer__remote_and_part_time_possible_-743999872723501">Apply now</a>
                </div>
            </div>
        </div>
    )
};

export default VacancySales;
