const sidebanner = [
    {
        type: `Interview`,
        icon: `icon-bg-person`,
        title: ` interview stages`,
        description: ``,
        src: `../stages/`,
        id: 0
    },
    {
        type: `FAQ`,
        icon: `icon-bg-faq`,
        title: `job information `,
        description: ``,
        src: `../faq/`,
        id: 1
    },
    {
        type: `Working at Veeam`,
        icon: `icon-bg-whitepaper`,
        title: `success story  `,
        description: ``,
        src: `../success/`,
        id: 2
    },
    {
        type: `Veeam Team`,
        icon: `icon-bg-person`,
        title: `About`,
        description: ``,
        src: `../about/`,
        id: 3
    },
    {
        type: `Карьера в Veeam Presales`,
        icon: `icon-bg-person`,
        title: `Об отделе Presales`,
        description: ``,
        src: `../about/presales/`,
        id: 4
    },
    {
        type: `Карьера в Veeam Renewals`,
        icon: `icon-bg-person`,
        title: `Об отделе Renewals`,
        description: ``,
        src: `../about/renewals/`,
        id: 5
    }
];

export default sidebanner;
