const reasons = [
    {
        icon: `//img.veeam.com/careers/img/img_ru/reasons/reason-compensation.png`,
        title: `Meal tickets`,
        reason: `You will receive a card with a monthly allowance, which is accepted in most restaurants and supermarkets. `
    },
    {
        icon: `//img.veeam.com/careers/img/img_ru/reasons/reason-fitness.png`,
        title: `Multisport card `,
        reason: `You will receive a universal fitness card for visiting 99% of 
        Czech sports facilities (fitness, yoga, squash, tennis, rock climbing, 
        fitness classes for children, swimming pool, sauna, etc.).`
    },
    {
        icon: `//img.veeam.com/careers/img/img_ru/reasons/reason-clubs.png`,
        title: `Playrooms`,
        reason: `Game room, yoga room, game zone, table tennis, kicker, relaxation room, shower and changing room.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_dialog.png`,
        title: `Language courses (ENG/CZ)`,
        reason: `Czech and English courses.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_kaka.png`,
        title: `Fruits and cookies`,
        reason: `You will find tea, coffee and sweets in our kitchen every day.`
    },
    {
        icon: `//img.veeam.com/careers/img/img_ru/reasons/reason-transport.png`,
        title: `Transportation reimbursement`,
        reason: `Yearly public transportation ticket reimbursed in full.`
    },
    {
        icon: `//img.veeam.com/careers/img/img_ru/reasons/reason-ensurance.png`,
        title: `Health benefits`,
        reason: `<ul><li class="mb-0">Premium healthcare plan;</li><li class="mb-0">Four sick days per year without a doctor’s note;</li><li class="mb-0">Top-up payments for sick leave of up to 100% of your salary (up to 21 dat per year)</li></ul>`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_mobile.png`,
        title: `Corporate mobile plan`,
        reason: `Mobile plan for employee paid in full with unlimited calls, SMS, and internet connection. Competitive mobile plan options for your family.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_cafe.png`,
        title: `Cafeteria points `,
        reason: `An additional annual budget that you can spend on a variety of benefits such as travel, sport, wellness, education and many more.`
    }
];

export default reasons;
