import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IStagesProps {
  department: IDepartment | null
}

class StagesSupport extends React.Component<IStagesProps> {
  public render() {
    let department = this.props.department

    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0">
          <div className="row block-spacer mt-0">
            <div className="col-12">
              <p className="text-placeholder">Technical Customer Support</p>
              <h2 className="header-custom-spacer-md">Interview stages</h2>
              <p>Preliminary candidate screening</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>1. Preliminary candidate screening </h3>
                      <p>
                        Preliminary IT candidate screening is based on your
                        resume information. When we are reviewing your CV, we
                        pay particularly close attention to your previous
                        experience in IT and technical support. We also
                        investigate the technologies you have previously worked
                        with and assess your level of foreign language
                        proficiency. The more straightforward you are in your
                        assessment of your own knowledge and skills, the higher
                        your chances are for success!
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_1.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_2.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>2. Telephone interview with an HR representative</h3>
                      <p>
                        We will be pre-checking your knowledge of English,
                        discussing your motivation and expectations, as well as
                        asking several technical questions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>3. Hard and soft skill check</h3>
                      <p>
                        For a Veeam® Technical Customer Support Engineer, it is
                        equally as important for you to be able to communicate
                        with the customers as it is that you are a great
                        technical specialist. Therefore, this stage will be held
                        online. We will discuss your understanding of the term
                        ‘technical support’ and talk about the qualities
                        necessary for communicating with the customer.
                      </p>
                      <p>
                        This part will be followed by a discussion of your
                        technical knowledge and skills.
                      </p>
                      <p>
                        The total duration of the interview is one and a half to
                        two hours. At the end, we will offer you a practice test
                        to take at home.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_3.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_4.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>4. Interview</h3>
                      <p>
                        The first meeting will be with your future team leader.
                        During this stage we will discuss your previous
                        experience, education and projects you worked on before,
                        in detail. You will be able to ask questions about the
                        company, position and work.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>5. Language check</h3>
                      <p>
                        An ultra-short call, lasting for 15-20 minutes, during
                        which we will be additionally (and decisively!) checking
                        your level of proficiency in a foreign language.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_5.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_6.svg"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>6. Feedback after the interview</h3>
                      <p>
                        Within a few days (but no more than seven) after the
                        interview, an HR specialist will contact you by phone or
                        email with the results of your interview. You will be
                        able get detailed feedback from us if something went
                        wrong.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>7. Job offer</h3>
                      <p>
                        After successfully completing the interview, you will
                        receive an official job offer. After that, the only
                        thing we will have to discuss would be your starting
                        date and welcoming you to Veeam Software.
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/img/img_ru/stages/support/stage_7.svg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StagesSupport
