const awards = [
    {
        title: `Cw 100 best places to work in it 2020`,
        desc: `<p>Veeam has been named one of the Best Places to Work in IT by 
        <a class="btn btn-link" href="//www.idginsiderpro.com/" target="_blank">IDG Insider Pro</a> and 
        <a class="btn btn-link" href="//www.computerworld.com/" target="_blank">Computerworld</a>. The 27th annual report highlights Veeam amongst the 100 top U.S. employers that offer IT professionals challenging projects, robust salaries, superior benefits, 
        flexible work/life options, and unsurpassed access to training and career advancement opportunities.</p>
        <p><em>“From a team of eight during our start-up phase to more than 4,300 colleagues globally, Veeam fosters an environment where employees think and react quickly and have the opportunity to be part of our team in driving customer success and their own career,” said Bill Largent, CEO at Veeam. “As the leader in data backup and protection, Veeam is known for our history of innovation, competitiveness, and impressive talent. We support and foster an entrepreneurial atmosphere that is nimble and flexible enough to empower individual initiative and growth, even though we operate as a global, billion-dollar company. We are honored with this award and will continue to support the development and leadership of all Veeam employees and build a strong culture that focuses on both employee and customer success and makes Veeam a desirable team to be a part of.”</em> </p>`,
        image: `//img.veeam.com/careers/img/img_cz/awards/2020.png`
    },
    {
        title: `Veeam Named 2021 Best of VMworld Winner `,
        desc: `<p>Veeam was named the 2021 <a class="btn btn-link" href="//searchservervirtualization.techtarget.com/photostory/252507765/Winners-of-the-Best-of-VMworld-2021-Awards/4/Business-Continuity-and-Data-Protection-2021-winner" target="_blank">Best of VMworld</a> Winner in the Business Continuity and Data Protection category for Veeam Backup and Replication V11. TechTarget’s SearchServerVirtualization.com™ staff appointed a team of experts and editors that selected winning products from all those that entered. The team judged the products according to their innovation, value, performance, functionality and ease of use. </p>
               <p>According to the judges, <em>"With release 11, Veeam has closed the gap between them and their competitors, and they have made their product into a fully complete solution. A lot of the features people were looking for, like storage reduction on premises and moving it out to the cloud, are now included." </em></p>`,
        image: `//img.veeam.com/careers/img/img_cz/awards/2021.png`
    },
    {
        title: `Veeam Backup & Replication is #1 on G2’s 2022 Best IT Management Software list `,
        desc: `<p>Veeam® Software has earned several honors from G2, one of the world’s most trusted and largest tech marketplaces and used by more than 60 million software buyers annually.</p>
        <ul>
            <li class="mb-0">Veeam Backup & Replication: #1 in <a class="btn btn-link" href="//www.g2.com/best-software-companies/top-it-management?rank=1#rank-1" target="_blank">Best IT Management Products for 2022</a> </li>
            <li class="mb-0">Veeam Backup & Replication: Top 100  <a class="btn btn-link" href="//www.g2.com/best-software-companies" target="_blank">Best Software Products for 2022</a> </li>
            <li class="mb-0">Veeam Backup for AWS: #9 in  <a class="btn btn-link" href="//www.g2.com/best-software-companies/top-it-management?rank=9#rank-9" target="_blank">Best IT Management Products for 2022</a> </li>
        </ul>
        <p>G2 is the definitive online source of information to discover, review and manage the technology that businesses of any size need to reach and outgrow their potential. Its annual Best Software List ranks the world’s best software companies and products, based on authentic and timely reviews from real users. </p>
        <p>Known as the most advanced data protection solution for cloud, virtual, physical, NAS and enterprise applications, read G2 reviews for <a class="btn btn-link" href="//www.g2.com/products/veeam-veeam-backup-replication/reviews#reviews" target="_blank">Veeam Backup & Replication™</a> and <a class="btn btn-link" href="//www.g2.com/products/veeam-backup-for-aws/reviews#reviews" target="_blank">Veeam Backup for AWS</a>.</p>`,
        image: `//img.veeam.com/careers/img/img_cz/awards/2022.png`
    },
    {
        title: `Cloud1002019`,
        desc: `<p><a class="btn btn-link" href="//www.veeam.com/?ad=awards" target="_blank">Veeam</a> has, for the fourth consecutive year, been named on the Forbes 2019 Cloud 100, the definitive ranking of the top 100 private cloud companies in the world, published by Forbes in partnership with <a class="btn btn-link" href="//www.bvp.com/" target="_blank"> Bessemer Venture Partners</a> and <a class="btn btn-link" href="//www.salesforce.com/company/ventures/" target="_blank">Salesforce Ventures</a></p>
        <p><em>“For four years now, we have ranked the best and brightest emerging companies in the cloud sector,” said Alex Konrad, Forbes editor of The Cloud 100. “With so many businesses growing fast in the cloud, from cybersecurity and marketing to data analytics and storage, it’s harder than ever to make the Cloud 100 list – but with more elite company if you do. Congratulations to each of the 2019 Cloud 100 honorees and the 20 Rising Stars honorees poised to join their ranks!” </em></p>
        <p>The Forbes 2019 Cloud 100 and 20 Rising Stars lists are published online at <a class="btn btn-link" href="//www.forbes.com/cloud100" target="_blank">www.forbes.com/cloud100</a> and will appear in the September 2019 issue of Forbes magazine. </p>`,
        image: `//img.veeam.com/careers/img/img_cz/awards/2019.png`
    },
    {
        title: `Veeam® came in at the top again in the Champions category in the Canalys Leadership Matrix! `,
        desc: `<p>The Canalys Leadership Matrix assesses vendor performance in the channel based on channel feedback into the Canalys Vendor Benchmark over the last 12 months. It combines partner feedback from Canalys’ unique Vendor Benchmark tool with an independent assessment by Canalys’ analysts of each vendor’s momentum in the channel. This is based on their investments, strategy improvements and execution. Champions have the highest scores in the Canalys Vendor Benchmark. Champions exhibit common characteristics such as ongoing improvements in channel processes to drive simplicity and ease of doing business, investments in programs and a demonstrable commitment to growing the share of revenue generated through partners. </p>
        <p>The awards can be viewed <a class="btn btn-link" href="https://www.canalys.com/freereport/latest-free-report?id=14976" target="_blank">here</a>.</p>`,
        image: `//img.veeam.com/careers/img/img_cz/awards/2018.png`
    }
];

export default awards
