import React from 'react';
import VacancyListItem from '../vacancy-list-item/vacancy-list-item';
import {IVacanciesContext, VacanciesContext} from '../providers/vacancies.context';
import Fuse from 'fuse.js';
import {
    ISelectItem,
    IVacanciesListFilter,
    IVacancy,
    IVacancyLanguage,
    IVacancyLocation,
    IVacancyTag,
    ILearnMore
} from '../../../types';
import LearnMore from '../learn-more/learn-more';


interface IVacanciesListProps {
    limit?: number,
    departmentAlias?: string | null;
    excludedVacancy?: IVacancy | null;
    filter?: IVacanciesListFilter;
    tag?: IVacancyTag | null;
    homepage?: boolean;
    learnMoreData: ILearnMore;

}

class VacanciesList extends React.Component<IVacanciesListProps> {
    public render() {
        return (
            <VacanciesContext.Consumer>
                {
                    context => this.renderArray(context)
                }
            </VacanciesContext.Consumer>
        );
    }

    protected renderArray(context: IVacanciesContext | null) {
        let departmentAlias = this.props.departmentAlias;
        let excludedVacancy = this.props.excludedVacancy;
        let filter = this.props.filter;
        let tag = this.props.tag;
        let learnMoreData = this.props.learnMoreData;
        let result = context && context.data && context.data.length > 0 &&
            context.data || [];
        if (filter && filter.query && filter.query.length > 1) {
            const fuse = new Fuse(result, {
                threshold: 0.1,
                minMatchCharLength: 2,
                includeScore: true,
                ignoreLocation: true,
                keys: [
                    {
                        name: 'title',
                        weight: 1
                    },
                    {
                        name:  'qualificationsStripedTags',
                        weight: 0.8
                    }
                ],
                sortFn: (a, b) => a.score - b.score
            })
            // @ts-ignore
            let searchResult = fuse.search(filter.query);
            result = searchResult.map((ref) => ref.item);
        }
        result = result.filter((vacancy) => {
                    let result = vacancy.isVisible && vacancy.department.alias !== 'sales' && vacancy.department.alias !== 'marketing';
                    if (departmentAlias) {
                        result = result && vacancy.department.alias == departmentAlias;
                    }
                    if (excludedVacancy) {
                        result = result && vacancy.routeAlias !== excludedVacancy.routeAlias;
                    }
                    if (filter && filter.department) {
                        result = result && (filter.department === 'all' || vacancy.department.alias === filter.department);
                    }
                    if (filter && filter.experience) {
                        result = result && (filter.experience === 'all' || filter.experience === vacancy.experience.alias);
                    }
                    if (filter && filter.languages && filter.languages.length > 0) {
                        result = result && filter.languages.some((option: ISelectItem) => {
                            return vacancy.language.some((lang: IVacancyLanguage) => option.value === lang.id)
                        });
                    }
                    if (filter && filter.location) {
                        result = result && vacancy.location.some((loc: IVacancyLocation) => filter && (filter.location === -1 || loc.id === filter.location));
                    }
                    if (tag) {
                        let tags = this.props.homepage ? vacancy.homepageTags : vacancy.tags;
                        result = result && tags.some((item: IVacancyTag) => tag && item.type === tag.type && item.value === tag.value);
                    }
                    return result;
                })
                .sort((a:IVacancy, b:IVacancy) => {
                    return filter && filter.query && filter.query.length > 1 ? 0 : Number(b.isHot) - Number(a.isHot);
                }) || [];
        if (this.props.limit) {
            result = result.slice(0, this.props.limit)
        }
        return (<div className="h-100 d-flex flex-column">
            {result.length > 0 && result.map((vacancy) =>
                <VacancyListItem vacancy={vacancy} key={`vacancy-${vacancy.id}`}/>)
            }
            {result.length === 0 && !this.props.homepage &&
            <div className="card z-index-overflow">
                <div className="card-body">
                    <h3>No matches were found for your {filter && filter.query !== `` ? `"${filter && filter.query}"` : ``} query :(</h3>
                    <img src="//img.veeam.com/careers/icons/arrow_revert.png" alt=""/>
                </div>
            </div>
            }
            {
                result.length === 0 && this.props.homepage &&
                <LearnMore fromList={true} learnMore={learnMoreData}/>
            }
        </div>)
    }
}

export default VacanciesList;
