const offerStartQa = [
    {
        icon: `//img.veeam.com/careers/landing/img/icons/wwo_02.png`,
        content: `Meal tickets`,
        contentbottom: `You will receive a card with a monthly allowance, which is accepted in most restaurants and supermarkets.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_pingpong.png`,
        content: `Multisport card`,
        contentbottom: `You will receive a universal fitness card for visiting 99% of Czech sports facilities (fitness, yoga, squash, tennis, rock climbing, fitness classes for children, swimming pool, sauna, etc.). Multisport card is paid in full.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_chess.png`,
        content: `Office perks`,
        contentbottom: `Game room, sports room, game zone, table tennis, table football, relaxation room, shower and changing room.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_dialog.png`,
        content: `Language courses (ENG/CZ)`,
        contentbottom: `Czech and English courses: including professional IT English.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_kaka.png`,
        content: `Snacks and drinks`,
        contentbottom: `You will find fruits, healthy snacks, tea, coffee and sweets in our kitchen every day.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_buss.png`,
        content: `Transportation reimbursement`,
        contentbottom: `Yearly public transportation ticket reimbursed in full.`
    },
    {
        icon: `//img.veeam.com/careers/img/img_ru/reasons/reason-ensurance.png`,
        content: `Healthcare`,
        contentbottom: `Four sick days per year without a doctor’s note. Sick leave is paid in full - up to 21 days. Flexible Healthcare plan for employees and kids.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_mobile.png`,
        content: `Corporate mobile plan`,
        contentbottom: `Mobile plan with unlimited calls, SMS and internet connection.`
    },
    {
        icon: `//img.veeam.com/careers/icons/icons-all/icon_cafe.png`,
        content: `Cafeteria points `,
        contentbottom: `An additional annual budget that you can spend on a variety of benefits such as travel, sport, wellness, education and many more.`
    }
];

export default offerStartQa;
