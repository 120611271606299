import React, {MouseEvent} from 'react';
import {ISelectItem} from '../../../types';
import {Dropdown} from 'react-bootstrap';


interface ISelectProps {
    options: ISelectItem[];
    placeholder?: string
    active?: number;
    name?: string;

    onChange(value: any, index: number): void;
}

interface ISelectDefaultProps {
    placeholder: string;
}

interface ISelectState {
    selected: number | null,
}

class Select extends React.Component<ISelectProps, ISelectState> {

    static defaultProps: ISelectDefaultProps = {
        placeholder: 'Выберите'
    };

    constructor(props: ISelectProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.reset = this.reset.bind(this);
        this.state = {
            selected: this.props.active !== undefined ? this.props.active : null
        }
    }

    public reset(): void {
        this.setState({
            selected: null
        });
        this.props.onChange(undefined, -1);
    }

    public render() {
        let selected = this.state.selected;
        let options = this.props.options;
        let placeholder = this.props.placeholder;
        return (
            <div className="select">
                <Dropdown>
                    <Dropdown.Toggle id="sl" className={selected !== null ? 'selected' : ''}>
                        {selected !== null ? options[selected].label : placeholder}
                    </Dropdown.Toggle>
                    {this.renderOptions(options)}
                </Dropdown>
            </div>
        );
    }

    protected handleChange(option: any, index: number) {
        if (option.disabled) {
            return;
        }
        this.setState({
            selected: index
        });
        this.props.onChange(option.value, index);
    }

    protected renderOptions(options: ISelectItem[]) {
        return (
            <Dropdown.Menu>
                {
                    options.map((option, index) => (
                            <React.Fragment key={`option-${index}`}>
                                <Dropdown.Item
                                    onClick={(e: any) => this.handleChange(option, index)}
                                    className={option.disabled ? 'text-muted disabled' : ''}
                                >{option.label}
                                </Dropdown.Item>
                                {
                                    option.divider && <Dropdown.Divider/>
                                }
                            </React.Fragment>
                        )
                    )
                }
            </Dropdown.Menu>
        )
    }
}

export default Select;
