import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IDepartmentSuccessProps {
  department: IDepartment | null
}

class DepartmentSuccessQa extends React.Component<IDepartmentSuccessProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <p className="text-placeholder">Quality Assurance / QA</p>
              <h1 className="display-1">Success story</h1>
              <h3 className="header-custom-spacer-md">Jan Faltys</h3>
              <h3 className="text-placeholder">QA Engineer</h3>
              <div className="block-spacer">
                <ul className="list-unstyled">
                  <li>
                    <p>
                      <strong>How did your career begin at Veeam®?</strong>
                    </p>
                    <p>
                      Smoothly! I was given a tour of the office and I was
                      introduced to my colleagues. I was then handed over to my
                      very capable mentor who I could approach with any question
                      about the company and the job.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>How has your career advanced at Veeam?</strong>
                    </p>
                    <p>
                      At first my main goal was to prove myself in the new
                      environment and to get proficient so that I could
                      contribute to my team’s efforts. Later, I appreciated
                      knowing how to get the job done and being recognized for
                      my knowledge. My promotion after just 10 months speaks
                      volumes.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        What are some of the benefits of Veeam as an employer?
                      </strong>
                    </p>
                    <p>
                      To put it simply, the job itself. It offers intriguing
                      opportunities to advance my skill set in the quickly
                      evolving area of cloud solutions. All this comes in a nice
                      wrapping of a new, modern office building, a competitive
                      benefits package and an interesting variety of tasks in a
                      company that is not burdened by excessive bureaucratic
                      procedures.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        What personal qualities should a potential Veeam
                        employee have?
                      </strong>
                    </p>
                    <p>
                      The candidate should be able to pay attention to details,
                      be friendly, be curious, be proactive and be able to think
                      outside of the box. When a release date is approaching,
                      the ability to remain stress-free also comes in handy.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        How would you describe your job to a person completely
                        unfamiliar with it?
                      </strong>
                    </p>
                    <p>
                      The main goal of my job is to make sure that a solution
                      works as it should, and as a user would expect. That’s the
                      core part of the job, but the crucial aspect includes
                      making sure that a product elevates the user experience to
                      a new level so the customer can rely on it at any time. As
                      an example, I can describe a simple test case about
                      sending an email. Clicking SEND must send an email, simple
                      enough, but if the user is writing about an attachment in
                      the body of the email and there is no attachment, the
                      program needs to ask the user if the attachment has been
                      forgotten. If the person isn’t bored by now, I would
                      describe the designing of test cases covering as many
                      scenarios as possible.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        What do you especially like about your job?
                      </strong>
                    </p>
                    <p>
                      It isn’t boring and I don’t have the feeling that I am
                      stagnating in any way. I can develop in areas I find
                      interesting. My team also offers flexibility. I admit the
                      last bit can get tricky as the job can get demanding at
                      times.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4 order-md-2 order-first  mb-5 mb-md-0">
              <img
                src="//img.veeam.com/careers/img/img_cz/success/success_it.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container container-spacer pt-0">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DepartmentSuccessQa
