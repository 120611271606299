const quotes = [
    {
        title: `Slick, easy to use and effective solution for an enterprise`,
        quote: ` An effective backup and recovery environment, delivering significantly faster recovery of VM’s`,
        industry: `Manager Storage & Compute Europe in the Finance Industry `,
        company: `Company Size 3B - 10B USD`
    },
    {
        title: `Reliable, easy to use, works perfect for us and we'll continue to use it!`,
        quote: ` My organization has been a Veeam customer for several years. We run backups with BU&R for our entire virtualized environment every single night. I can say with confidence that it is one of the most reliable, easy to use backup solutions I've encountered.  as a more cost effective solution than traditional enterprise players, previously used by the bank.`,
        industry: `Senior System Administrator in the Education Industryg`,
        company: `Company Size <50M USD`
    },
    {
        title: `Amazing product, easy to use, loaded with features/options, quick and fantastic support`,
        quote: `It's rare that I find a software suite that I absolutely love. Veeam is one of those few products that seems like it's going to be too good to be true, but does exactly what it promises to do. We run multiple VMware servers with around two dozen virtual machines spread across them. Veeam gives the flexibility to ensure that all of our virtual machines are backed up as often as we deem necessary with the ability to test within a sandbox`,
        industry: `IT Manager in the Manufacturing Industry `,
        company: `Company Size <50M USD`
    },
    {
        title: `Easy to use, fantastic product`,
        quote: `The interface is user-friendly. The installation process and agent installation are very simple, especially for Windows Operating systems. It is the perfect solution for virtualization infrastructures. The manufacturer is particularly focused on virtualization systems. Already there is free product available for physical systems. especially the consistency of the systems backed up and compression ratios are noteworthy. Secure all your data with Veeam.`,
        industry: `Network Administrator in the Services Industry `,
        company: `Company Size 50M - 250M USD`
    }
];

export default quotes;
