import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import sidebanner from "../../mocks/banners/sidebanners"
import learnMore from "../../mocks/learn-more"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IDepartmentSuccessProps {
  department: IDepartment | null
}

class DepartmentSuccessSupport extends React.Component<IDepartmentSuccessProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0">
          <div className="row">
            <div className="col-12 col-md-8 ">
              <p className="text-placeholder">Technical Customer Support </p>
              <h1 className="display-1">Success Story</h1>
              <h3 className="header-custom-spacer-md">Marius Nita</h3>
              <h3 className="text-placeholder">Customer Support Engineer </h3>
              <h4>
                <em>
                  "I also learned so much from the people around me. I teamed up
                  with three amazing guys and we tackled the challenges
                  together. If any environment can be both competitive and
                  collaborative, Veeam has it. There was friendly rivalry
                  regarding the number of cases we closed and the tickets we
                  processed but we were all trying to help each other to grow."
                </em>
              </h4>
              <h3>Getting to grips with the complexity</h3>
              <p>
                Marius had worked on a helpdesk providing local IT support in
                Bucharest, but moving to Veeam was something else. “Everything
                is on a different scale: larger infrastructures and
                environments, incredibly complex systems, the latest data
                storage technologies. I’m lucky that technology is not just my
                job; it’s my passion in life, it defines me as a person. That’s
                a pretty good starting point for a career at Veeam.”
              </p>
              <p>
                We have a ramp-up program for new hires and Marius’ first three
                months were an intensive training experience. “It showed me how
                everything can be applied to real-life scenarios. I studied
                Informatics at university and I learned the theory surrounding
                email infrastructures in the cloud. Coming here, I could see how
                everything works and interconnects.”
              </p>
              <p>
                Marius was given training materials and lectures, plus a mentor
                who was also his team leader and could answer all his questions.
                “I also learned so much from the people around me. I teamed up
                with three amazing guys and we tackled the challenges together.
                If any environment can be both competitive and collaborative,
                Veeam has it. There was friendly rivalry regarding the number of
                cases we closed and the tickets we processed but we were all
                trying to help each other to grow.”
              </p>
              <p>
                This competition really pushed Marius on. “I’m usually quite
                self-critical but I’m proud of the way I’ve progressed. I came
                in as a junior in 2017, then was promoted to Customer Support
                Engineer. Now I’m a tier 2 Support Engineer, making training and
                developing tests for others, and dealing with the more complex
                cases escalated by tier 1 Engineers. And all the time, being
                part of a large international company, I’m dealing with people
                from different cultures and nationalities.”{" "}
              </p>
            </div>
            <div className="col-12 col-md-4 order-md-2 order-first  mb-5 mb-md-0">
              <img
                src="//img.veeam.com/careers/img/img_cz/success/success_support.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="container container-spacer pt-0">
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DepartmentSuccessSupport
