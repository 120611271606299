const socials = [
    {
        image: `//img.veeam.com/careers/img/img_ru/social/social_twitter.png`,
        desc: ``,
        link: `//twitter.com/veeam`
    },
    /*{
        image: `//img.veeam.com/careers/img/img_ru/social/social_facebook.png`,
        desc: ``,
        link: `//www.facebook.com/VeeamSoftware/`
    },*/
    {
        image: `//img.veeam.com/careers/img/img_ru/social/social_linkedin.png`,
        desc: ``,
        link: `//www.linkedin.com/company/veeam-software/`
    },
    /*{
        image: `//img.veeam.com/careers/img/img_ru/social/social_instagram.png`,
        desc: ``,
        link: `//www.instagram.com/veeam_czechia/`
    },*/
    {
        image: `//img.veeam.com/careers/img/img_ru/social/social_youtube.png`,
        desc: ``,
        link: `//www.youtube.com/user/YouVeeam/`
    }


];

export default socials;
