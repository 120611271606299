import React from 'react';



interface IErrorBoundaryState {
    hasError: boolean;
    error: any,
    errorInfo: any
}

class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
    constructor(props:any) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error:any) {
        return { hasError: true };
    }


    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.hasError) {
            return(
                <div className="bg bg-green error-message">
                    <div className="container container-spacer-lg">

                        <h1 className="mb-3">Something went wrong and we are fixing it. </h1>
                        <h2 className="mt-0">Please, try to visit our site later.</h2>
                    </div>
                </div>
            )
        }

        if (this.state.errorInfo && this.state.errorInfo ) {
            console.log(this.state.errorInfo&&this.state.errorInfo);
        }

        return this.props.children;
    }
}


export default ErrorBoundary;
