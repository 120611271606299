import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import { IDepartment } from "../../../shared/types"

interface IStagesProps {
  department: IDepartment | null
}

class StagesQa extends React.Component<IStagesProps> {
  public render() {
    let department = this.props.department

    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg pb-0">
          <div className="row block-spacer mt-0">
            <div className="col-12">
              <p className="text-placeholder">Quality Assurance / QA</p>
              <h2>Interview stages</h2>
              <p>
                The selection of new QA employees is carried out in several
                stages. Don’t be surprised if you are on a first-name basis
                during the interview. The culture at Veeam® is casual and
                friendly.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>1. Preliminary candidate screening</h3>
                      <p>
                        Preliminary selection is based on the information from
                        your resume. What we pay attention to during this stage:
                      </p>
                      <ul>
                        <li>
                          Your technical profile, including experience with
                          operating systems, networks, databases, programming
                          languages, automation tools, etc.
                        </li>
                        <li>
                          Projects in which you participated and your specific
                          role
                        </li>
                        <li>Education</li>
                        <li>
                          Other information that helps us evaluate your
                          knowledge and potential
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/departments/stages/stage_1.png"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/departments/stages/stage_2.png"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>2. Telephone interview with an HR representative</h3>
                      <p>
                        Candidates who have passed the preliminary stage of
                        selection are contacted by an HR representative to set
                        up a telephone interview.
                      </p>
                      <p>
                        During the telephone interview, the HR specialist will
                        get to know you better, talk about open positions and
                        answer your questions.
                      </p>
                      <p>
                        After the HR call, your CV will be presented to our
                        Hiring Team. At this stage, if we choose to proceed with
                        your application, one of two options will happen: 
                      </p>
                      <ol>
                        <li>
                          We will ask you to perform a technical task to assess
                          your skills.
                        </li>
                        <li>
                          You will be invited to participate in a technical
                          interview.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>3. Technical interview</h3>
                      <p>
                        A technical interview can last up to 2 hours and
                        consists of several parts:
                      </p>
                      <ul>
                        <li>
                          Meet and greet to discuss your work experience,
                          professional goals and your interests.
                        </li>
                        <li>
                          Technical experience, when we will learn about your
                          technical awareness, including knowledge of operating
                          systems, networks, databases, etc. We may also ask you
                          about any technology topics mentioned in your resume.
                        </li>
                        <li>
                          Work experience, when we will talk about your previous
                          software testing experience.
                        </li>
                        <li>
                          Future potential tests, when we will ask you to test
                          an object, phenomenon or functional fragment.
                        </li>
                        <li>
                          Logical tasks, which are simple, but interesting,
                          mathematical and logical problems so you can
                          demonstrate logic, common sense and the ability to
                          find ways out of unusual situations.
                        </li>
                      </ul>
                      <p>
                        As a result of the first interview, we may invite you to
                        attend a second interview if we feel the need. Your
                        performance will be evaluated comprehensively. If, for
                        instance, you failed to answer a Windows question or
                        were unable to solve a test task, this doesn’t
                        necessarily mean that you are not suitable for a QA
                        position. We also evaluate the way you think, your
                        potential and your desire to grow and develop your
                        skills; these traits are far more important than your
                        current knowledge or skills.
                      </p>
                      <h5 className="mb-3 block-spacer">
                        <strong>How to get ready for the interview:</strong>
                      </h5>
                      <ul>
                        <li>
                          Be sure you have the knowledge described in your
                          resume.
                        </li>
                        <li>
                          Thoroughly study important software testing and QA
                          material. Be ready to explain why you want to work in
                          field.
                        </li>
                        <li>
                          Study Windows Server Administration 2008/2012/2016.
                        </li>
                        <li>
                          Refresh your computer network knowledge (Oliver books
                          are very helpful).
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/departments/stages/stage_3.png"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-5 text-center">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/departments/stages/stage_4.png"
                        alt=""
                      ></img>
                    </div>
                    <div className="col-12 col-md-7">
                      <h3>4. Feedback after the interview</h3>
                      <p>
                        Within a few days after the interview, the HR specialist
                        will contact you via phone call or email with the
                        results of your interview.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <h3>5. Job offer</h3>
                      <p>
                        After successfully completing the interview, you will
                        receive an official job offer. If you accept it, we will
                        discuss your starting date at Veeam.
                      </p>
                      <p>
                        <strong>And the adventures can begin!</strong>
                      </p>
                    </div>
                    <div className="col-12 col-md-5 text-center order-first order-md-last">
                      <img
                        className="img-fluid mb-5 w-65"
                        src="//img.veeam.com/careers/departments/stages/stage_5.png"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[1]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default StagesQa
