const ctDepartments = [
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/end_user_department_1.svg`,
        title: `End User Services`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/business_partner_department_2.svg`,
        title: `Business Partners and PMO`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/core_services_department_3.svg`,
        title: `Core Services`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/business_application_department_4.svg`,
        title: `Business Applications & Automation`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/data_department_5.svg`,
        title: `Data`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/architecture_department_6.svg`,
        title: `Architecture`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/security_department_7.svg`,
        title: `Security`,
        department: ``
    },
    {
        icon: `//img.veeam.com/careers/img/img_cz/about/ct/performance_department_8.svg`,
        title: `Performance & Compliance`,
        department: ``
    }
];

export default ctDepartments;
