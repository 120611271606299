import React from 'react';
import qs from 'qs';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {debounce} from 'throttle-debounce';
import learnMore from '../../mocks/learn-more';
import {VacanciesContext} from '../../../shared/components.shared/components/providers/vacancies.context';
import VacanciesCount from '../../../shared/components.shared/components/vacancies-count/vacancies-count';
import VacanciesList from '../../../shared/components.shared/components/vacancies-list/vacancies-list';
import VacanciesListFilter
    from '../../../shared/components.shared/components/vacancies-list-filter/vacancies-list-filter';
import {IVacanciesListFilter, IVacancyTag} from '../../../shared/types';
import {ThemeContext} from "../../../shared/components.shared/components/providers/theme.context";

interface IVacanciesState {
    filter: IVacanciesListFilter;
    tag: IVacancyTag | null;
}

class Vacancies extends React.Component<RouteComponentProps, IVacanciesState> {

    constructor(props: any) {
        super(props);
        let queryParams = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true
        });
        let tag = null;
        let tagParam = queryParams.tag ? queryParams.tag.split(':') : [];
        if (tagParam.length === 2) {
            tag = {
                name: '',
                type: tagParam[0],
                value: tagParam[1]
            }
        }
        this.state = {
            filter: {
                query: queryParams.query || '',
                department: queryParams.department || ''
            },
            tag
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleTagSelect = this.handleTagSelect.bind(this);
        this.updateRouteParams = debounce(1000, this.updateRouteParams.bind(this));
    }

    render() {
        return (
            <React.Fragment>
                <img className="img-pattern img-pattern-right-top"
                     src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png" alt=""/>
                <div className="container container-spacer-lg">
                    <h1 className="display-1 header-custom-spacer">Build your career at Veeam</h1>
                    <h3>Vacancies open:
                        <VacanciesContext.Consumer>
                            {
                                (vacanciesContext) =>
                                    <VacanciesCount vacancies={vacanciesContext && vacanciesContext.data || []}
                                    />
                            }
                        </VacanciesContext.Consumer>
                    </h3>
                    <div className="row block-spacer-top">
                        <div className="col-12 col-lg-4">
                            <ThemeContext.Consumer>
                                {
                                    (theme) =>
                                        <VacanciesContext.Consumer>
                                            {
                                                (vacanciesContext) =>
                                                    <VacanciesListFilter onChange={this.handleFilterChange}
                                                                         list={vacanciesContext && vacanciesContext.data || []}
                                                                         initFilter={this.state.filter}
                                                                         searchQuery={theme.searchQuery}
                                                                         setSearchQuery={theme.setSearchQuery}
                                                    />
                                            }
                                        </VacanciesContext.Consumer>
                                }
                            </ThemeContext.Consumer>
                        </div>
                        <div className="col-12 col-lg-8">
                            <VacanciesList filter={this.state.filter} tag={this.state.tag} learnMoreData={learnMore}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    protected handleFilterChange(filterName: string, filterValue: any): void {
        this.setState((prevState: IVacanciesState) => {
            return {
                filter: {
                    ...prevState.filter,
                    [filterName]: filterValue
                }
            }
        });

        this.updateRouteParams();
    }

    protected handleTagSelect(tag: IVacancyTag): void {
        this.setState({
            tag
        });
        this.updateRouteParams();
    }

    protected updateRouteParams() {
        let history = this.props.history;
        let filter = this.state.filter;
        let department = filter.department === undefined ? 'all' : filter.department;
        let tag = this.state.tag ? `${this.state.tag.type}:${this.state.tag.value}` : '';
        let languages = filter.languages && filter.languages.map((langOption) => langOption.value).join(',') || '';

        history.push(window.location.pathname + `?query=${filter.query}&department=${department}&tag=${tag}&languages=${languages}`);
    }
}

export default withRouter(Vacancies);
