import React from "react"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IFaqProps {
  department: IDepartment | null
}

class FaqQa extends React.Component<IFaqProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <p className="text-placeholder">Quality Assurance / QA</p>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div className="row block-spacer-bottom">
            <div className="col-12">
              <ol>
                <li>
                  <p>
                    <strong>
                      What is a QA engineer and how can I find out if I am
                      qualified?
                    </strong>
                  </p>
                  <p>
                    A QA engineer should be curious, attentive, have an agile
                    and flexible mind, enjoy solving puzzles, be logical and be
                    able to apply common sense. The ideal QA engineer is always
                    open to new ideas, loves to study the behavior of different
                    objects and systems, sees flaws that others may miss, and is
                    always thinking about how to fix issues. If you possess any
                    or all these qualities, you might be a good fit for this
                    position.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Why are there always a lot of open vacancies at Veeam
                      Software (including QA) ?
                    </strong>
                  </p>
                  <p>
                    This is due to the growth of the company. Most open jobs are
                    new positions created due to expansion rather than
                    replacements for employees who have left Veeam ®. We do not
                    have much staff turnover. People who join Veeam usually stay
                    for a long time.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Can I become part of the Veeam QA team with little-to-no
                      experience in testing/IT?
                    </strong>
                  </p>
                  <p>
                    Yes, but it all depends on you. A keen desire to learn is
                    far more important for us than the formal experience
                    indicated in your resume. Such a desire can compensate for
                    knowledge and experience gaps.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do you hire senior students? Is it possible to combine
                      work with study?
                    </strong>
                  </p>
                  <p>
                    Yes, senior students are welcome. Combining work with school
                    is also possible. In this case, we create an individual work
                    schedule of at least 30 hours a week, which is the minimum
                    we require.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Why do your job description and interview focus so much on
                      operating systems and network technologies knowledge?
                    </strong>
                  </p>
                  <p>
                    This is fundamental knowledge, which you must have to test
                    solutions properly. Veeam products are complex client-server
                    systems, which is why QA engineers need to understand the
                    environment in which these products work.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What strategies/methods are used when testing Veeam
                      products?
                    </strong>
                  </p>
                  <p>
                    In general, we use the strategies of black and gray boxes.
                    The white box is presented sporadically. Testing is carried
                    out both manually and through automation.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do I have to know any programming languages to work in
                      Veeam QA?
                    </strong>
                  </p>
                  <p>
                    No. Additional knowledge is, of course, welcomed and can be
                    put use on projects.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Do you use automated testing?</strong>
                  </p>
                  <p>
                    Yes, automation of our products has been developing
                    dynamically. In our test automation, we frequently use
                    Python, C# and PowerShell. Occasionally, when the need
                    arises, we also use C/C++. When designing and implementing
                    our tests, we often use white box testing. Also of note,
                    working in our test automation is not limited to working
                    with Veeam's products, but can also include writing code
                    that uses AWS, Azure, Google Cloud, and other major
                    services.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do you require knowledge of the English language?
                    </strong>
                  </p>
                  <p>
                    We do require a minimal knowledge of English, including
                    being able to read English text.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What is the work schedule on QA team?</strong>
                  </p>
                  <p>
                    The work schedule is flexible. You can shift your eight-hour
                    work day to the mornings or evenings, although we have
                    general rule to be present at the office from 12 p.m. to 6
                    p.m. to interact with other teammates.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default FaqQa
