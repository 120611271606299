import './header.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Image, Nav, Navbar, NavDropdown, Row} from 'react-bootstrap';
import {IDepartment} from '../../../shared/types';
import menu from '../../mocks/menu';
import {DepartmentsContext} from '../../../shared/components.shared/components/providers/departments.context';
import Menu from '../../../shared/components.shared/components/menu/menu';
import SearchForm from '../search-form/search-form';
import {ThemeContext} from "../../../shared/components.shared/components/providers/theme.context";

interface IDepartmensGroup {
    header: string;
    list: IDepartment[]
}

interface IHeaderProps {
    transparentMenu: boolean
}

interface IMenuToggleState {
    hideMenu: boolean;
    changeBackground: boolean;
    background: string;
    hideSubMenu: boolean;
}

class Header extends React.Component<IHeaderProps, IMenuToggleState> {
    constructor(props: any) {
        super(props);
        this.state = {
            hideSubMenu: false,
            hideMenu: false,
            changeBackground: false,
            background: '',
        };
        this.handleClick = this.handleClick.bind(this);
        this.hideCollapsedMenu = this.hideCollapsedMenu.bind(this);
        this.hideCollapsedSubMenu = this.hideCollapsedSubMenu.bind(this);
        this.changeToDarkOnScroll = this.changeToDarkOnScroll.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            changeBackground: !state.changeBackground,
        }))
    }

    hideCollapsedMenu() {
        this.setState(state => ({
            hideMenu: !state.hideMenu
        }))
    }

    hideCollapsedSubMenu() {
        this.setState(state => ({
            hideSubMenu: !state.hideSubMenu
        }))
    }

    changeToDarkOnScroll(transparentMenu = this.props.transparentMenu): void {
        if (window.scrollY > 0) {
            this.setState({background: transparentMenu && '#004550' || '#fff'});
        } else {
            this.setState({background: 'transparent'})
        }
    }

    componentWillReceiveProps(nextProps: Readonly<IHeaderProps>, nextContext: any): void {
        if (this.props.transparentMenu !== nextProps.transparentMenu) {
            this.changeToDarkOnScroll(nextProps.transparentMenu);
        }
    }

    componentDidMount(): void {
        window.addEventListener(`scroll`, () => this.changeToDarkOnScroll());
    }

    render() {
        let logoPath = `//img.veeam.com/careers/logo/veeam/logo.svg`;
        let menuTransparencyClass = this.props.transparentMenu && 'bg-transparent' || '';
        let buttonBackgroundChange = this.props.transparentMenu && 'btn btn-outline-info' || 'btn btn-outline-success';
        return (
            <header className={menuTransparencyClass}>
                <div className="header header-desktop d-none d-lg-block"
                     style={{backgroundColor: this.state.background}}>
                    <Container>
                        <Row>
                            <Col>
                                <Navbar className="justify-content-between">
                                    <Navbar className="Collapse">
                                        <Nav>
                                            <Link to="/"><Image className="img-logo" src={logoPath}/></Link>
                                            <NavDropdown id="site-switcher" title="Czechia ">
                                                <NavDropdown.Item href="//careers.veeam.com">Global</NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav>
                                    </Navbar>
                                    <Nav>
                                        {/*
                                         // @ts-ignore */}
                                        <NavDropdown className="dropdown-search" title="" id="basic-nav-dropdown" expand={!this.state.hideSubMenu}>
                                            <div className="container px-0 px-md-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <ThemeContext.Consumer>
                                                            {
                                                                (theme) =>
                                                                    <SearchForm searchQuery={theme.searchQuery}
                                                                                setSearchQuery={theme.setSearchQuery}/>
                                                            }
                                                        </ThemeContext.Consumer>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavDropdown>
                                        <Nav.Link as={Link} to={"/company"}>Company</Nav.Link>
                                        <DepartmentsContext.Consumer>
                                            {
                                                (departmentsContext) => this.renderDepartments(departmentsContext && departmentsContext.data || [], 'lg')
                                            }
                                        </DepartmentsContext.Consumer>
                                        <Nav.Link as={Link} to={"/vacancies"}>
                                            <button
                                                className={buttonBackgroundChange}>All jobs
                                            </button>
                                        </Nav.Link>
                                    </Nav>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="header header-mobile bg-dark d-block d-lg-none">
                    <Container>
                        <Row>
                            <Col>
                                <Navbar className="d-flex" collapseOnSelect expand="lg" expanded={this.state.hideMenu}>
                                    <Link to="/"><Image className="mr-3"
                                                        src={logoPath}
                                                        width="100"/></Link>
                                    <Nav className="flex-row align-items-center">
                                        <Nav className="navbar-nav-top flex-row">
                                            <NavDropdown id="site-switcher" title="CZ" className="site-switcher">
                                                <NavDropdown.Item href="//careers.veeam.com">Global</NavDropdown.Item>
                                            </NavDropdown>
                                            {/*
                                         // @ts-ignore */}
                                            <NavDropdown className="dropdown-search d-flex px-2 ml-2" title=""
                                                /*
// @ts-ignore */
                                                         id="basic-nav-dropdown" expand={!this.state.hideSubMenu}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <ThemeContext.Consumer>
                                                                {
                                                                    (theme) =>
                                                                        <SearchForm searchQuery={theme.searchQuery}
                                                                                    setSearchQuery={theme.setSearchQuery}/>
                                                                }
                                                            </ThemeContext.Consumer>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavDropdown>
                                        </Nav>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav"
                                                       className="navbar-nav pr-0" onClick={this.hideCollapsedMenu}/>
                                        <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse-menu">
                                            <Nav>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Nav.Link as={Link} to={"/company"}
                                                                      onClick={this.hideCollapsedMenu}>Company</Nav.Link>
                                                            <DepartmentsContext.Consumer>
                                                                {
                                                                    (departmentsContext) => this.renderDepartments(departmentsContext && departmentsContext.data || [], 'sm')

                                                                }
                                                            </DepartmentsContext.Consumer>
                                                            <Nav.Link as={Link} to={"/vacancies"}
                                                                      onClick={this.hideCollapsedMenu}>All
                                                                jobs</Nav.Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Nav>
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
        );
    }

    protected renderDepartments(departments: IDepartment[], keyPrefix: string) {
        let result: IDepartmensGroup[] = [];
        departments.forEach((department) => {
            let found = result.find(item => item.header === department.type);
            if (found) {
                found.list.push(department);
            } else {
                result.push({
                    header: department.type,
                    list: [department]
                });
            }
        });


        return (
            <NavDropdown title="Departments" id="basic-nav-dropdown"
                /*
// @ts-ignore */
                         expand={!this.state.hideSubMenu}>
                <div className="container px-0 px-md-4">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="d-flex flex-lg-row flex-column flex-wrap flex-xl-nowrap justify-content-around">
                                <NavDropdown.Item className="btn btn-back d-block d-lg-none text-placeholder"
                                                  onClick={this.hideCollapsedSubMenu}>All departments</NavDropdown.Item>
                                {menu.map((item) => <Menu key={`item-${item.department}`}
                                                          menu={item}
                                                          hideSecondaryMenu={this.hideCollapsedMenu}/>)}
                            </div>
                        </div>
                    </div>
                </div>
            </NavDropdown>
        )
    }
}

export default Header;
