import React from 'react';
import {IReasons} from '../../../types';

interface IReasonsProps {
    reasons: IReasons;
}

class Reason extends React.Component<IReasonsProps> {
    public render() {
        let reasons = this.props.reasons;
        return (
            <div className="card card-basis card-no-spacer-x border-0">
                <div className="card-header pb-0">
                    <img src={reasons.icon} alt=""/></div>
                <div className="card-body">
                    <p><strong>{reasons.title}</strong></p>
                    <p dangerouslySetInnerHTML={{__html:reasons.reason}}/>
                </div>
            </div>
        )
    }
}

export default Reason;
