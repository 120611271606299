const photos = [
    [
        {
            title: `Bike Race for IT Geeks.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_1.png`
        },
        {
            title: `Break Time`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_3.png`
        },
        {
            title: `International Women's Day.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_5.png`
        },
        {
            title: `Meet up IT Geek Hub - Presentation.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_4.png`
        },
        {
            title: `Meet Up @IT Geek Hub.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_6.png`
        },
        {
            title: `Veeam Cares. 30 bikes charity event. `,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_8.png`
        }
    ],
    [
        {
            title: `Veeam Cares - charity event.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_9.png`
        },
        {
            title: `Veeam Green Day Celebration.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_10.png`
        },
        {
            title: `Veeam Xmas party - Veeam Rocks.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_11.png`
        },
        {
            title: `Summer. Veeam. Badminton.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_12.png`
        },
        {
            title: `Having fun at summer party.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_2.png`
        },
        {
            title: `Veeam summer party.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_7.png`
        }
    ],
    [
        {
            title: `Bike Race for IT Geeks.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_1.png`
        },
        {
            title: `Break Time`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_3.png`
        },
        {
            title: `International Women's Day.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_5.png`
        },
        {
            title: `Meet up IT Geek Hub - Presentation.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_4.png`
        },
        {
            title: `Meet Up @IT Geek Hub.`,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_6.png`
        },
        {
            title: `Veeam Cares. 30 bikes charity event. `,
            photo: `//img.veeam.com/careers/img/img_cz/photos_social/photo_8.png`
        }
    ]
];

export default photos;
