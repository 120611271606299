import React from 'react';
import BannersCareerStart from './banners-career-start';
import BannersDev from './banners-development';
import BannersCorporateTechnology from './banners-corporate-technology';
import BannersMarketing from './banners-marketing';
import BannersProductManagement from './banners-product-management';
import BannersPrague from './banners-prague';
import BannersQa from './banners-qa';
import BannersSales from './banners-sales';
import BannersSupport from './banners-support';
import BannersTechnicalDocumentation from './banners-technical-documentation';

interface ITagProps {
    tag: string
}

class BannersDepartmentAll extends React.Component<ITagProps> {
    public render() {

        let component = null;
        let tag = this.props.tag;
        switch (tag) {
            case `marketing` :
                component = <BannersMarketing/>;
                break;
            case `sales` :
                component = <BannersSales/>;
                break;
            case `quality-assurance` :
                component = <BannersQa/>;
                break;
            case `without` :
                component = <BannersCareerStart/>;
                break;
            case `ct` :
                component = <BannersCorporateTechnology/>;
                break;
            case `development` :
                component = <BannersDev/>;
                break;
            case `support` :
                component = <BannersSupport/>;
                break;
            case `product-management` :
                component = <BannersProductManagement/>;
                break;
            case `technical-documentation` :
                component = <BannersTechnicalDocumentation/>;
                break;
            case `prague` :
                component = <BannersPrague/>;
                break;
        }
        return component;
    }
}

export default BannersDepartmentAll;
