import React from 'react'
import {ILearnMore} from "../../../types";

interface ILearnMoreBanner {
    fromList?: boolean,
    learnMore: ILearnMore,
}

class LearnMore extends React.Component<ILearnMoreBanner> {
    public render () {
        let fromList = this.props.fromList;
        let learnMore = this.props.learnMore;

        return (
            <div className="card flex-column flex-md-row z-index-overflow card-pattern-bg">
                <div className="card-body">
                    {!fromList &&
                    <div className="row">
                        <div className="col-12 offset-xl-2 col-xl-10">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="display-3 header-custom-spacer">{learnMore.title}</h2>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-8">
                                    <h3 className="mb-md-0">{learnMore.action}</h3>
                                </div>
                                <div className="col-12 col-md-4">
                                    <a href={learnMore.src}
                                       className="btn btn-cta btn-secondary" target="_blank">{learnMore.button}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    }
                    {fromList &&
                    <div className="row">
                        <div className="col-12">
                            <div className="row mb-5">
                                <div className="col-12">
                                    <h2 className="display-3 header-custom-spacer">{learnMore.titleNoVacancies}</h2>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h3 className="mb-lg-0">{learnMore.action}</h3>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <a href={learnMore.src}
                                       className="btn btn-cta btn-secondary" target="_blank">{learnMore.button}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default LearnMore;
