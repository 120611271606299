import React from "react"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import companybanner from "../../mocks/banners/mocks-companybanners"
import BannersCompany from "../../../shared/components.shared/components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { BaseDepartmentPagePropsType } from "../../../shared/types"

const AboutProductManagement = ({
  department,
}: BaseDepartmentPagePropsType) => {
  return (
    <React.Fragment>
      <img
        className="img-pattern img-pattern-left-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
        alt=""
      />
      <img
        className="img-pattern img-pattern-right-top"
        src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
        alt=""
      />
      <div className="container container-spacer-lg pb-0">
        <h1 className="display-1">Product Management / PM</h1>
        <div className="row block-spacer">
          <div className="col-12 col-md-7">
            <p>
              The Veeam® Product Management department combines three different
              fields — development, marketing and sales. There are three main
              tasks in Product Management — monitoring the current market
              situation, product marketing and support of the sales team.
            </p>
            <p>
              We are in constant connection with clients and partners so they’re
              aware of all the new technical trends in the industry. The main
              objectives of this team are to plan new features, draw up the plan
              of development, conduct technical meetings, conferences and
              events.
            </p>
            <p>
              That is why the ability to analyze trends and prioritize, run a
              dialog, be attentive to details and dive into a subject are so
              important for a product manager. The employees of this department
              capture all the trends in the IT industry, recognize the needs of
              customers and find the best way to meet them.
            </p>
          </div>
          <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
            <img
              src="//img.veeam.com/careers/img/img_ru/about/product-management/product_diagram.png"
              alt=""
              className="img-fluid mb-5"
            />
          </div>
        </div>
        <div className="card-deck flex-column">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col -12 col-md-5 text-center mb-5 mb-md-0">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/cis/about_2.svg"
                    alt=""
                    className="img-fluid mb-5 w-65"
                  />
                </div>
                <div className="col-12 col-md-7">
                  <h3>Professional development </h3>
                  <p>
                    A member of the product management team should be like the
                    many-armed god Shiva.
                  </p>
                  <p>
                    Because you cannot plan a new feature without the ability to
                    analyze competitors, you cannot manage a product without a
                    clear understanding of the requirements of a user. You also
                    won’t be able to conduct a solid presentation without deep
                    knowledge of the technology.
                  </p>
                  <p>
                    In addition, you need to know and comprehend the basics of
                    conducting business. That is one of many reasons why we are
                    always constantly learning and improving all these areas of
                    expertise — to stay informed of the situation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-7">
                  <h3>How to become a part of the team? </h3>
                  <p>
                    This department is a perfect place for someone with the
                    technical background who wants to use their knowledge in the
                    business world. With us you will learn how this unique and
                    high-tech product, which has become the leader in the
                    market, is created.
                  </p>
                  <p>
                    You will work in a fast-growing, international company and
                    exchange opinions with IT professionals using Veeam
                    solutions in different parts of the world every day.
                  </p>
                </div>
                <div className="col-12 col-md-5 order-first order-md-last text-center mb-5 mb-md-0">
                  <img
                    src="//img.veeam.com/careers/img/img_ru/about/cis/about_3.svg"
                    alt=""
                    className="img-fluid mb-5 w-65"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="card-deck card-deck-banners block-spacer mt-0">
          {department && (
            <SidebannerItem banner={sidebanner[0]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[1]} department={department} />
          )}
          {department && (
            <SidebannerItem banner={sidebanner[2]} department={department} />
          )}
        </div>
        {department && (
          <VacanciesSimilar department={department} learnMoreData={learnMore} />
        )}
        <div className="card-deck card-deck-banners block-spacer">
          {companybanner.slice(0, 3).map((banner) => (
            <BannersCompany banner={banner} key={`banner-${banner.title}`} />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AboutProductManagement
