import React from "react"
import { IMenu } from "../../../types"
import { NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"

interface IMenuProps {
  menu: IMenu
  hideSecondaryMenu: any
}

class Menu extends React.Component<IMenuProps> {
  public render() {
    let menu = this.props.menu
    let hideSecondaryMenu = this.props.hideSecondaryMenu

    return (
      <React.Fragment>
        <ul className="dropdown-section list-unstyled">
          <h3 className="text-placeholder">{menu.department}</h3>
          {menu.item.map((item) => (
            <NavDropdown.Item
              as={Link}
              className="dropdown-item"
              to={item.src}
              onClick={hideSecondaryMenu}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          ))}
        </ul>
        <ul className="dropdown-section list-unstyled">
          <h3 className="text-placeholder">Operations</h3>
          <a
            href="//careers.veeam.com/opportunities?utm_source=cz.careers.veeam.com&utm_medium=referral&utm_campaign=czcareers"
            className="dropdown-item"
            rel="noopener noreferrer"
            target="_blank"
          >
            Marketing & Sales
          </a>
        </ul>
      </React.Fragment>
    )
  }
}

export default Menu
