import React from "react"
import StagesDevelopment from "./stages-development"
import StagesProductManagement from "./stages-product-management"
import StagesQa from "./stages-qa"
import StagesSupport from "./stages-support"
import StagesTechnicalDocumentation from "./stages-technical-documentation"
import { IDepartment } from "../../../shared/types"
import NotFound from "../../../shared/components.shared/components/not-found/not-found"
import StagesCorporateTechnology from "./stages-corporate-technology"
import { DepartmentAliases } from "../../types"

interface IStagesAllProps {
  department: IDepartment | null
  alias: string
}

export const StagesAll = ({ department, alias }: IStagesAllProps) => {
  const getStagesDepartment = () => {
    switch (alias) {
      case DepartmentAliases.ResearchAndDevelopment:
        return <StagesDevelopment department={department} />
      case DepartmentAliases.CorporateTechnology:
        return <StagesCorporateTechnology department={department} />
      case DepartmentAliases.QualityAssurance:
        return <StagesQa department={department} />
      case DepartmentAliases.ProductManagement:
        return <StagesProductManagement department={department} />
      case DepartmentAliases.TechnicalDocumentationDevelopment:
        return <StagesTechnicalDocumentation department={department} />
      case DepartmentAliases.TechnicalCustomerSupport:
        return <StagesSupport department={department} />
      default:
        return <NotFound />
    }
  }

  return getStagesDepartment()
}
