export interface ISocialPhotos {
  title: string
  photo: string
}

export interface INewsCz {
  id: number
  title: string
  text: string
  link: string
  img: string
  date: string
}

export enum DepartmentAliases {
  CorporateTechnology = "corporate-technology",
  Facilities = "facilities",
  Finance = "finance",
  Hr = "hr",
  Marketing = "marketing",
  ProductManagement = "product-management",
  Purchasing = "purchasing",
  QualityAssurance = "quality-assurance",
  ResearchAndDevelopment = "research-and-development",
  Sales = "sales",
  Strategy = "strategy",
  TechnicalCustomerSupport = "technical-customer-support",
  TechnicalDocumentationDevelopment = "technical-documentation-development",
}

export enum DEPARTMENT_CONTEXT_PAGE {
  ABOUT,
  STAGES,
  FAQ,
  SUCCESS,
}
