import React from "react"
import { Link } from "react-router-dom"
import companybanner from "../../mocks/banners/mocks-companybanners"
import learnMore from "../../mocks/learn-more"
import sidebanner from "../../mocks/banners/sidebanners"
import BannersCompany from "../../components/banners-company/banners-company"
import SidebannerItem from "../../../shared/components.shared/components/sidebanner-item/sidebanner-item"
import VacanciesSimilar from "../../../shared/components.shared/components/vacancies-similar/vacancies-similar"
import { IDepartment } from "../../../shared/types"

interface IFaqProps {
  department: IDepartment | null
}

class FaqSupport extends React.Component<IFaqProps> {
  public render() {
    let department = this.props.department
    return (
      <React.Fragment>
        <img
          className="img-pattern img-pattern-left-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_left_middle.png"
          alt=""
        />
        <img
          className="img-pattern img-pattern-right-top"
          src="//img.veeam.com/careers/banners/banners_ru/pattern_right_top.png"
          alt=""
        />
        <div className="container container-spacer-lg">
          <p className="text-placeholder">Technical Customer Support</p>
          <h2>Frequently Asked Questions (FAQs)</h2>
          <div className="row block-spacer-bottom">
            <div className="col-12">
              <ol>
                <li>
                  <p>
                    <strong>
                      How to know if I am a good fit for Technical Customer
                      Support?
                    </strong>
                  </p>
                  <p>
                    Good knowledge of the product is important for a Veeam®
                    Technical Support Engineer, as is knowledge of a foreign
                    language. Having a client-oriented approach is also
                    necessary. More information about the stages of the
                    interview can be found{" "}
                    <Link to="/stages/support" className="btn btn-link">
                      here
                    </Link>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Will I be able to work in the Veeam Technical Customer
                      Support department without work experience?
                    </strong>
                  </p>
                  <p>
                    Yes, you can work in the Technical Customer Support
                    department without experience. The important things for us
                    are your ability to speak a foreign language and the
                    technical knowledge required by the job in question. Your
                    desire to learn and develop will be a big advantage.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do I have to speak English fluently to work at Veeam
                      Technical Customer Support?
                    </strong>
                  </p>
                  <p>
                    Our department works with customers from different
                    countries. So, we don’t only need people who are fluent in
                    English but also people who are fluent in French, German,
                    Spanish and Italian.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How many interview stages should I expect? How are the
                      interviews are conducted?
                    </strong>
                  </p>
                  <p>
                    Interviews with candidates for Veeam Technical Customer
                    Support are held in several stages. You can learn more about
                    them{" "}
                    <Link to="/stages/support" className="btn btn-link">
                      here
                    </Link>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How should I prepare for a job interview in the Technical
                      Customer Support department?
                    </strong>
                  </p>
                  <ul>
                    <li>
                      Get acquainted with the information about our company and
                      products — it can be found on our website{" "}
                      <a
                        href="//veeam.com"
                        target="_blank"
                        className="btn btn-link"
                      >
                        www.veeam.com
                      </a>
                    </li>
                    <li>Read the vacancy requirements.</li>
                    <li>
                      If you’re not familiar with these topics, we recommend you
                      research them.
                    </li>
                    <li>Get ready for the interview in a foreign language.</li>
                  </ul>
                  <p>
                    Sincere interest and a desire to learn are often more
                    important to us than awards and titles!
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      To whom does Veeam Software provide customer support?
                    </strong>
                  </p>
                  <p>
                    Veeam is a leading developer of backup, disaster recovery,
                    and data management software for virtual, physical, and
                    cloud-based workloads, as well as monitoring and planning of
                    IT infrastructures. We have over 400,000 users worldwide. In
                    general, these are mainly IT specialists at medium and large
                    organizations. To them, we provide highly qualified
                    technical support.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What is the work schedule like?</strong>
                  </p>
                  <p>
                    The work schedule at Veeam is flexible. We have a five-day
                    working week, an eight-hour working day with a one-hour
                    lunch break. The time that you start your workday is
                    adjustable (as agreed with your manager). In addition, some
                    customer support teams work four days a week for 10 hours.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Will there be any training?</strong>
                  </p>
                  <p>
                    A training course has been developed for new employees to
                    learn about our complex products more easily and quickly.
                    During this time, you’ll learn everything you need to know
                    about Veeam products and how to provide expert customer
                    support.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Are there any prospects for professional and career
                      growth?
                    </strong>
                  </p>
                  <p>
                    Veeam is in a stage of active growth. For this reason,
                    career development is quite fast and directly depends on the
                    talents and efforts of the employee. There are three lines
                    of customer support in our department. The first line is the
                    starting point, nevertheless, all first-line specialists are
                    technical experts and solve most of the incoming cases. We
                    fill any emerging vacancies among the second-and third-line
                    customer support specialists by promoting the most talented
                    employees from the first and second lines respectively.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Where can I get acquainted with the lifestyle of the
                      company?
                    </strong>
                  </p>
                  <p>
                    You can find out about Veeam corporate life on the career
                    site,{" "}
                    <a
                      href="//www.facebook.com/veeamcareers"
                      target="_blank"
                      className="btn btn-link"
                    >
                      Facebook
                    </a>{" "}
                    and{" "}
                    <a
                      href="//www.instagram.com/veeamcareers/"
                      target="_blank"
                      className="btn btn-link"
                    >
                      Instagram.
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Can I apply for several job postings simultaneously?
                    </strong>
                  </p>
                  <p>
                    Yes, we encourage you to browse our many vacancies and
                    select those that will be interesting for you.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Why are there always so many vacancies at Veeam? Is this
                      due to staff turnover?
                    </strong>
                  </p>
                  <p>
                    We do indeed have many open positions. This is due to the
                    company’s growth rather than to staff turnover. Our number
                    of customers is growing very quickly, so we’re expanding the
                    Customer Support department.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="card-deck card-deck-banners block-spacer">
            {department && (
              <SidebannerItem banner={sidebanner[3]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[0]} department={department} />
            )}
            {department && (
              <SidebannerItem banner={sidebanner[2]} department={department} />
            )}
          </div>
          {department && (
            <VacanciesSimilar
              department={department}
              learnMoreData={learnMore}
            />
          )}
          <div className="card-deck card-deck-banners block-spacer">
            {companybanner.slice(0, 3).map((banner) => (
              <BannersCompany banner={banner} key={`banner-${banner.title}`} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default FaqSupport
