import React, {ChangeEvent, createRef} from 'react';
import {
    IDepartment,
    ISelectItem,
    IVacanciesListFilter,
    IVacancy,
    IVacancyLanguage,
    IVacancyLocation
} from '../../../types';
import Select from '../select/select';
import {DepartmentsContext} from '../providers/departments.context';
import Multiselect from '../multiselect/multiselect';
import {defaultsEnum} from '../../../../global/types';


interface IVacanciesListFilterProps {
    list: IVacancy[];
    initFilter?: IVacanciesListFilter;
    searchQuery: string;
    setSearchQuery(q: string): void
    onChange(name: string, value: any): void;
}

class VacanciesListFilter extends React.Component<IVacanciesListFilterProps, IVacanciesListFilter> {

    static contextType = DepartmentsContext;
    context!: React.ContextType<typeof DepartmentsContext>;
    private queryInput = createRef<HTMLInputElement>();
    private experienceInput = createRef<Select>();
    private departmentInput = createRef<Select>();
    private locationInput = createRef<Select>();
    private languageInput = createRef<Multiselect>();

    constructor(props: IVacanciesListFilterProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.mapDepartmentToOption = this.mapDepartmentToOption.bind(this);
        this.mapLangaugeToOption = this.mapLangaugeToOption.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.state = {
            query: this.props.searchQuery,
            department: undefined,
            languages: undefined,
            location: undefined,
            experience: undefined,
            ...this.props.initFilter
        }
    }

    public render() {
        let departmentOptions = this.context && this.context.data.map(this.mapDepartmentToOption) || [];
        departmentOptions = departmentOptions.filter((department) =>
            department.value !== 'marketing' && department.value !== 'sales');

        if (departmentOptions.length > 0) {
            departmentOptions.unshift({
                value: 'all',
                label: 'All departments',
                divider: true
            });
        }

        let languageOptions: ISelectItem[] = [];
        let experienceOptions: ISelectItem[] = [];
        experienceOptions.unshift({
            value: 'all',
            label: 'Any experience',
            divider: true
        });
        let locationOptions: ISelectItem[] = [];
        locationOptions.unshift({
            value: -1,
            label: 'Любой регион',
            divider: true
        });

        this.props.list.forEach((vacancy: IVacancy) => {
            vacancy.language.forEach((lang: IVacancyLanguage) => {
                let include = languageOptions.some((item: ISelectItem) => lang.id === item.value);
                if (!include) {
                    languageOptions.push(this.mapLangaugeToOption(lang));
                }
            });
            let includeExpirience = experienceOptions.some((item: ISelectItem) => vacancy.experience.alias === item.value);
            if (!includeExpirience && vacancy.experience.alias) {
                experienceOptions.push({
                    value: vacancy.experience.alias,
                    label: vacancy.experience.name,
                    disabled: false
                });
            }
            vacancy.location.forEach((location: IVacancyLocation) => {
                let include = locationOptions.some((item: ISelectItem) => location.id === item.value);
                if (!include) {
                    locationOptions.push(this.mapLocationToOption(location));
                }
            });
        });

        departmentOptions.forEach((department) => {
            department.count = this.props.list.reduce((acc: number, vacancy: IVacancy) => {
                if ([vacancy.department.alias, 'all'].includes(department.value) ) {
                    acc++;
                }
                return acc;
            }, 0);
        });


        let sortedDepartments = departmentOptions.sort((a: any, b: any) => a.count > b.count ? -1 : 1);
        let selectedDepartment = sortedDepartments.findIndex(option => option.value === this.state.department);
        return (
            <div>
                <div className="form-group">
                    <input className="form-control"
                           name="query"
                           type="search"
                           value={this.props.searchQuery}
                           onChange={this.handleChange}
                           placeholder="Keyword"
                           ref={this.queryInput}
                    />
                    <span className="icon-bg-search"/>
                </div>
                <div className="form-group">
                    {!!departmentOptions.length && <Select name="department"
                                                         onChange={(value, index) => this.handleSelectChange('department', value)}
                                                         active={selectedDepartment > -1 ? selectedDepartment : undefined}
                                                         options={departmentOptions} ref={this.departmentInput}
                                                         placeholder="All departments"
                    />
                    }
                </div>
                {!!languageOptions.length &&
                <div className="form-group">
                    <Multiselect id="lang"
                                 onChange={(values) => this.handleSelectChange('languages', values)}
                                 options={languageOptions}
                                 ref={this.languageInput}
                                 placeholder="All languages"/>
                </div>
                }
                {!!(experienceOptions.length > 1) &&
                <div className="form-group">
                    <Select name="experience"
                            onChange={(value) => this.handleSelectChange('experience', value)}
                            options={experienceOptions}
                            ref={this.experienceInput}
                            placeholder="Any experience"
                    />
                </div>
                }

                <div className="form-group">
                    <button className="btn btn-link" onClick={this.resetFilter}>Clear filters</button>
                </div>
            </div>
        );
    }

    protected mapDepartmentToOption(department: IDepartment): ISelectItem {
        return {
            value: department.alias,
            label: department.title,
            disabled: !this.props.list.some(vacancy => vacancy.department.alias === department.alias)
        };
    }

    protected mapLangaugeToOption(language: IVacancyLanguage): ISelectItem {
        return {
            value: language.id,
            label: language.name,
            disabled: false
        };
    }

    protected mapLocationToOption(location: IVacancyLocation): ISelectItem {
        return {
            value: location.id,
            label: `${location.country}, ${location.city}`,
            disabled: false
        };
    }

    protected handleChange(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]: value
        });

        if (name === 'query') {
            this.props.setSearchQuery(value);
        }
        this.props.onChange(name, value);
    }

    protected handleSelectChange(name: string, value: any) {
        this.setState({
            [name]: value
        });
        this.props.onChange(name, value);
    }

    protected resetFilter(): void {
        if (this.queryInput.current) {
            this.queryInput.current.value = '';
            this.setState({
                query: ''
            });
            this.props.onChange(this.queryInput.current.name, this.queryInput.current.value);
        }
        if (this.experienceInput.current) {
            this.experienceInput.current.reset();
        }
        if (this.departmentInput.current) {
            this.departmentInput.current.reset();
        }
        if (this.languageInput.current) {
            this.languageInput.current.reset();
        }
        if (this.locationInput.current) {
            this.locationInput.current.reset();
        }
    }

    componentDidUpdate(prevProps: IVacanciesListFilterProps) {
        if (this.props.searchQuery !== prevProps.searchQuery) {
            this.props.onChange('query', this.props.searchQuery);
        }
    }
}

export default VacanciesListFilter;
