import React from "react";
import { Route, Switch } from "react-router-dom";
import Gdpr from "../../pages/gdpr/gdpr";
import { Container } from "react-bootstrap";
import Header from "../header/header";
import DepartmentsProvider from "../../../shared/components.shared/components/providers/departments.provider";
import Footer from "../../../shared/components.shared/components/footer/footer";
import ThemeProvider from "../../../shared/components.shared/components/providers/theme.provider";
import { ThemeContext } from "../../../shared/components.shared/components/providers/theme.context";
import VacanciesProvider from "../../../shared/components.shared/components/providers/vacancies.provider";
import ScrollToTop from "../../../shared/components.shared/components/scroll-to-top/scroll-to-top";
import BackToTop from "../../../shared/components.shared/components/back-to-top/back-to-top";
import Homepage from "../../pages/homepage/homepage";
import Vacancies from "../../pages/vacancies/vacancies";
import Company from "../../pages/about-company/about-company";
import Reasons from "../../pages/reasons/reasons";
import News from "../../pages/news/news";
import Vacancy from "../../pages/vacancy/vacancy";
import CareerStartQa from "../../pages/career-start/career-start-qa";
import NewsProvider from "../providers/news.provider";
import NewsArticle from "../../pages/news-article/news-article";
import LibraryEn from "../../pages/library/library";
import axios from "axios";
import ErrorBoundary from "../error-boundary/error-boundary";
import NotFound from "../../../shared/components.shared/components/not-found/not-found";
import DepartmentContextWrapper from "../../DepartmentContextWrapper";
import { DEPARTMENT_CONTEXT_PAGE } from "../../types";

axios.defaults.headers["Content-Type"] = "application/json";
if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://localhost:8080";
}

const AppCz: React.FC = () => {
  return (
    <VacanciesProvider>
      <DepartmentsProvider>
        <NewsProvider>
          <ThemeProvider>
            <ThemeContext.Consumer>
              {(theme) => (
                <div className="careers-app">
                  <Header transparentMenu={theme.transparentMenu} />
                  <Container className="container-main" fluid={true}>
                    <ErrorBoundary>
                      <Switch>
                        <Route path="/" exact>
                          <Homepage
                            setMenuTransparency={theme.setMenuTransparency}
                          />
                        </Route>
                        <Route path="/company" exact>
                          <Company
                            setMenuTransparency={theme.setMenuTransparency}
                          />
                        </Route>
                        <Route path="/qa/:alias" exact>
                          <CareerStartQa
                            setMenuTransparency={theme.setMenuTransparency}
                          />
                        </Route>
                        <Route path="/about/:alias">
                          <DepartmentContextWrapper
                            page={DEPARTMENT_CONTEXT_PAGE.ABOUT}
                          />
                        </Route>
                        <Route path="/stages/:alias">
                          <DepartmentContextWrapper
                            page={DEPARTMENT_CONTEXT_PAGE.STAGES}
                          />
                        </Route>
                        <Route path="/faq/:alias">
                          <DepartmentContextWrapper
                            page={DEPARTMENT_CONTEXT_PAGE.FAQ}
                          />
                        </Route>
                        <Route path="/success/:alias" exact>
                          <DepartmentContextWrapper
                            page={DEPARTMENT_CONTEXT_PAGE.SUCCESS}
                          />
                        </Route>
                        <Route path="/news/:id" component={NewsArticle} />
                        <Route path="/news" component={News} />
                        <Route path="/vacancies" exact component={Vacancies} />
                        <Route
                          path="/vacancies/:department/:alias"
                          component={Vacancy}
                        />
                        <Route path="/gdpr" exact component={Gdpr} />
                        <Route path="/reasons" exact component={Reasons} />
                        <Route
                          path="/library"
                          exact
                          component={LibraryEn}
                        ></Route>
                        <Route path="*" component={NotFound} />
                      </Switch>
                    </ErrorBoundary>
                  </Container>
                  <Footer />
                  <ScrollToTop />
                  <BackToTop />
                </div>
              )}
            </ThemeContext.Consumer>
          </ThemeProvider>
        </NewsProvider>
      </DepartmentsProvider>
    </VacanciesProvider>
  );
};

export default AppCz;
